import {createStyles, makeStyles, Theme} from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import classnames from 'classnames';
import React, { Fragment } from 'react';
import './loader.scss';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            backgroundColor: alpha(theme.palette.common.black, 0.5),
            position: 'absolute',
            height: '100%',
            width: '100%',
            zIndex: 1,
        },
        spinner: {
            border: `${'5px ' + 'solid '}${theme.palette.secondary.dark}`,
            borderTop: `${'5px ' + 'solid '}${alpha(theme.palette.common.black, 0)}`,
        },
        spinnerWrapper: {
            zIndex: 8,
            position: 'absolute',
            top: '30%',
            // left: 0;
            right: '30%',
        },
    })
);
export default function Loader(): JSX.Element {
    const style = useStyles({});
    return (
        <Fragment>
            <div className={style.root}></div>
            <div className={style.spinnerWrapper}>
                <LoadingSpinner />
            </div>
        </Fragment>
    );
}

export function LoadingSpinner(): JSX.Element {
    const style = useStyles({});
    const loadingClass = classnames(style.spinner, 'loadingscreen_spinner');
    return <div className={loadingClass}></div>;
}
