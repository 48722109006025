import {MessageType, SystemMessage} from '../systemMessage/systemMessage';
import React, {ReactElement} from 'react';
import {useSystemvarsel} from '../../services/systemvarselService';
import {useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {useLanguage} from '../../i18n';

type SystemMessageBannerProps = {
    sidePanelIsOpen: boolean;
};

export const SystemMessageBanner = (props: SystemMessageBannerProps): ReactElement => {
    const l = useLanguage();
    const kartlagFeiler = useSelector((state: ApplicationState) => state.map.errorLoadingKartlag);
    const systemvarsel = useSystemvarsel();
    
    return (
        <>
            {systemvarsel && <SystemMessage openSidepanel={props.sidePanelIsOpen}
                                            messageType={systemvarsel.systemvarselType}
                                            message={systemvarsel.beskrivelse}
                                            title={systemvarsel.overskrift}/>}

            {kartlagFeiler && <SystemMessage openSidepanel={props.sidePanelIsOpen}
                                             messageType={MessageType.LoadingErrorMessage}
                                             message={l('systemvarsel.tileLoadError')}/>}
        </>
    );
};