import React, {ReactElement, useState} from 'react';
import {IconButton} from '../themeButtton/themeButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import './systemMessage.scss';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import classnames from 'classnames';
import {useLanguage} from '../../i18n';


export enum MessageType {
    InfoMessage = 1,
    ErrorMessage = 2,
    WarningMessage = 3,
    LoadingErrorMessage = 4,
}


type SystemMessageProps = {
    openSidepanel: boolean;
    messageType: MessageType;
    title?: string;
    message: string;
}

export function SystemMessage(props: SystemMessageProps): ReactElement {
    const l = useLanguage();
    const [showMessage, setShowMessage] = useState<boolean>(true);
    const panelOpenClass = props.openSidepanel ? 'open' : 'closed';
    const messageStylingClass = classnames('message', panelOpenClass, props.messageType && MessageType[props.messageType].toLowerCase());

    const closeMessage = (): void => {
        setShowMessage(false);
    };

    function SystemMessageIkon(): ReactElement {
        if (props.messageType === MessageType.LoadingErrorMessage || props.messageType === MessageType.ErrorMessage || props.messageType === MessageType.WarningMessage) {
            return <ReportProblemOutlinedIcon className='info-icon'/>;
        }
        return <InfoIcon className='info-icon'/>;
    }

    return (
        <>
            {showMessage && <div className={messageStylingClass}>
                <h3>{props.title}</h3>
                <div className='message-content'>
                    <SystemMessageIkon/>
                    <div className={'message-text'}>
                        {props.message}
                    </div>
                    <IconButton className='close-icon' aria-label={l('uu.close')} onClick={() => closeMessage()}>
                        <CloseIcon/>
                    </IconButton>
                </div>
            </div>
            }
        </>
    );
}

