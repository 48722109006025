import {
    sheepCompensationArea,
    sheepCompensationDamageCause,
    reindeerCompensationDamageCause,
    reindeerCompensationArea,
} from '../../constants/statistics';
import { Action } from 'redux';
import {ReindeerLocation} from "../../model/area/reindeerLocation";

export type NumberListItem = {
    value: number;
    label: string;
}

export type StringListItem = {
    value: string;
    label: string;
}

export type Listeelement =
{
    id: string;
    tekst: string;
}

export type RefundDataListdata = {
    reinbeitedistrikt: StringListItem[];
    reinbeiteområder: StringListItem[];
    sauerstatningÅr: NumberListItem[];
    reinerstatningÅr: NumberListItem[];
}


export type RefundFilter = {
    FromYear: NumberListItem;
    ToYear: NumberListItem;
    FromYearRein: NumberListItem;
    ToYearRein: NumberListItem;
    Region: string;
    County: string;
    Municipality: string;
    Reinbeiteomrade: string;
    Reinbeitedistrikt: string;
}

export type RefundSearchFilter = {
    FromYear: number;
    ToYear: number;
    Region: string;
    County: string;
    Municipality: string;
    Reinbeiteomrade: string;
    Reinbeitedistrikt: string;
}

export type SheepStatistics = {
    sheepCompensationArea: sheepCompensationArea[];
    damageCauseList: sheepCompensationDamageCause[];
}

export type ReindeerStatistics = {
    reindeerCompensationArea: reindeerCompensationArea[];
    damageCauseList: reindeerCompensationDamageCause[];
}

export type RefundDataState = {
    readonly refundFilter: RefundFilter;
    readonly sheepStatistics: SheepStatistics;
    readonly reindeerStatistics: ReindeerStatistics;
    readonly isFetching: boolean;
    readonly listData: RefundDataListdata;
}

//Actiontypes
export const SET_ISFETCHING = '@refund/SET_ISFETCHING';
export const UPDATE_LOCATION = '@refund/UPDATE_LOCATION';
export const UPDATE_LOCATIONREINDEER = '@refund/UPDATE_LOCATIONREINDEER';
export const UPDATE_FROMYEAR = '@refund/UPDATE_FROMYEAR';
export const UPDATE_TOYEAR = '@refund/UPDATE_TOYEAR';
export const UPDATE_FROMYEAR_REIN = '@refund/UPDATE_FROMYEAR_REIN';
export const UPDATE_TOYEAR_REIN = '@refund/UPDATE_TOYEAR_REIN';
export const REQUEST_REFUNDLISTDATA = '@refund/REQUEST_REFUNDLISTDATA';
export const RECEIVE_REFUNDLISTDATA = '@refund/RECEIVE_REFUNDLISTDATA';
export const RECEIVE_REFUNDSEARCHRESULTSHEEP = '@refund/RECEIVE_REFUNDSEARCHRESULTSHEEP';
export const RECEIVE_REFUNDSEARCHRESULTREINDEER = '@refund/RECEIVE_REFUNDSEARCHRESULTREINDEER';

//Actions
type RefundUpdateAction = {
    type: typeof UPDATE_LOCATION;
    location: string;
}

type RefundUpdateLocationReindeerAction = {
    type: typeof UPDATE_LOCATIONREINDEER;
    location: ReindeerLocation;
}

type RefundUpdateFromYearAction = {
    type: typeof UPDATE_FROMYEAR;
    fromYear: number;
}

type RefundUpdateToYearAction = {
    type: typeof UPDATE_TOYEAR;
    toYear: number;
}

type RequestRefundListData = {
    type: typeof REQUEST_REFUNDLISTDATA;
}

type ReceiveRefundListData = {
    type: typeof RECEIVE_REFUNDLISTDATA;
    result: RefundDataListdata;
}
type ReceiveRefundSearchResultSheep = {
    type: typeof RECEIVE_REFUNDSEARCHRESULTSHEEP;
    result: SheepStatistics;
}

type ReceiveRefundSearchResultReindeer = {
    type: typeof RECEIVE_REFUNDSEARCHRESULTREINDEER;
    result: ReindeerStatistics;
}

export type isLoadingAction = Action & {
    isFetching: boolean;
}

export type RefundFetchActionTypes =
    | RequestRefundListData
    | ReceiveRefundListData
    | ReceiveRefundSearchResultSheep
    | RefundUpdateAction
    | RefundUpdateFromYearAction
    | RefundUpdateToYearAction
    | ReceiveRefundSearchResultReindeer
    | RefundUpdateLocationReindeerAction
