import {createStyles, makeStyles, Theme} from '@material-ui/core';
import classnames from 'classnames';
import React, {ReactElement, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLanguage} from '../../i18n';
import {ApplicationState, useActionCreator} from '../../store';
import {CloseSearchresultlist} from '../../store/sidePanel/action';
import {SearchresultItem} from '../searchresultItem/searchresultItem';
import {Share} from '../share/share';
import {PrimaryButton, SecondaryButton} from '../themeButtton/themeButton';
import {getUrl} from '../tooltip/tooltip.util';
import './searchresultList.scss';
import {NavigateSearchResult} from './navigateSearchResult';
import {LoadingTitle, ResultTitle, SpecialSearchTitle} from './searchresultTitle';
import {useMobileBreakpointInnsyn} from '../../hooks/breakpoint';
import {useLoadingDots} from '../../hooks/loadingDots';

type OwnProps = {
    closeSidePanel: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            borderBottom: `1px solid ${theme.palette.secondary.dark}`,
        },
        footer: {
            borderTop: `1px solid ${theme.palette.secondary.dark}`,
        }
    })
);

export default function SearchresultList(props: OwnProps): ReactElement {
    const l = useLanguage();
    const classes = useStyles({});
    const headerClass = classnames(classes.header, 'header');
    const mobileScreen = useMobileBreakpointInnsyn();

    // Actions from store
    const closeResultList = useActionCreator(CloseSearchresultlist);
    
    // Values from store
    const stedfestings = useSelector((state: ApplicationState) => state.map.stedfesting);
    const individSearchText = useSelector((state: ApplicationState) => state.searchFilter.searchFilter.IndividualNameOrID);
    const barcode = useSelector((state: ApplicationState) => state.searchFilter.searchFilter.Barcode);
    const id = useSelector((state: ApplicationState) => state.searchFilter.searchFilter.ID);
    const isLoading = useSelector((state: ApplicationState) => state.settings.isLoading);
    const loadingDots = useLoadingDots();

    // Other values
    // OffsetStep is the number of items to be shown in the list at once.
    const offsetStep = 20;
    // Offset is the index number of the last item in the list, 
    // to keep track of which items in the stedfesting-array are currently shown 
    const [offset, setOffset] = useState<number>(offsetStep);
    const listLength = stedfestings.length;
    const isIndividSearch = individSearchText.length > 0;
    const isSpecialSearch = isIndividSearch || barcode.length > 0 || id.length > 0;
    const numberOfFirstItemShown = offset - (offsetStep - 1);
    const numberOfLastItemShown = Math.min(offset, listLength);
    
    // Url- and mail-link for sharing search result
    const currentUrl = getUrl().replace(/&/g, '%26');
    const mailLink = `mailto:?subject=Rovbase&body=${l('searchresult.mailBody')}${currentUrl}`;
    
    // Titles 
    const resultTitle = `${l('searchresultList.showResults')} ${numberOfFirstItemShown} - ${numberOfLastItemShown} (${listLength} ${l('searchresultList.hits')})`;
    const loadingTitle = `${l('searchresultList.loading')}${loadingDots}`;
    
    // Reset offset every time a new result is generated,
    // to always start showing the result list from the beginning of the list.
    useEffect(() => {
        setOffset(offsetStep);
    }, [listLength]);
    
    return (
        <div className="searchresult-panel">
            {isLoading ? (
                <LoadingTitle title={loadingTitle} />
            ) : (
                <>
                    <div className={headerClass}>
                        {isSpecialSearch ? (
                            <SpecialSearchTitle /> 
                        ) : (
                            <ResultTitle title={resultTitle} />
                        )}
                        <Share mailLink={mailLink} copyUrl={getUrl()} feedbackObject={stedfestings} />
                    </div>
                        
                    <div className="result-list">
                        {stedfestings.slice(offset - offsetStep, offset).map((stedfesting, i) => (
                            <SearchresultItem key={stedfesting.id + i} stedfesting={stedfesting} closeSidepanel={props.closeSidePanel} />
                        ))}
                    </div>
                    
                    <div className={classes.footer}>
                        <NavigateSearchResult
                            offset={offset}
                            offsetStep={offsetStep}
                            setOffset={(offset: number) => setOffset(offset)}
                            listLength={listLength}
                        />
                        <div className="footer-buttons">
                            {!isSpecialSearch && 
                            <SecondaryButton onClick={closeResultList}>
                                {l('searchresultList.showFilter')}
                            </SecondaryButton>
                            }
                            {mobileScreen && (
                                <PrimaryButton onClick={props.closeSidePanel}>
                                    {l('searchresultList.showMap')}
                                </PrimaryButton>
                            )}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}
