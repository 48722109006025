import {
    SET_LANGUAGE,
    LanguageAction,
    SET_ISLOADING,
    isLoadingAction,
    SiteAction,
    Site,
    SET_SITE,
    SET_ISINITIALIZED,
    isInitializedAction,
} from './types';
import { Language } from '../../constants/language';

export function setLanguage(language: Language): LanguageAction {
    return {
        type: SET_LANGUAGE,
        language,
    };
}
export function setIsLoading(isLoading: boolean): isLoadingAction {
    return {
        type: SET_ISLOADING,
        isLoading,
    };
}

export function setSite(site: Site): SiteAction {
    return {
        type: SET_SITE,
        site,
    };
}

export function setIsInitialized(isInitialized: boolean): isInitializedAction {
    return {
        type: SET_ISINITIALIZED,
        isInitialized,
    };
}
