import {Stedfesting} from '../../model/stedfesting/StedfestingTypes';
import React, {ReactElement} from 'react';
import {useLanguage} from '../../i18n';
import {Language} from '../../constants/language';
import {shallowEqual, useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {isNullOrEmpty, kommaseparert} from './tooltip.util';
import {IconComponent} from '../../Icons/icons';
import {formatDateStringToLocaleString} from '../../helpers';
import './tooltip.scss';
type TooltipInfoGridProps = {
    stedfesting: Stedfesting
}

export function TooltipHeaderInfo(props: TooltipInfoGridProps): ReactElement {
    const {funnsted, kommune, dato} = props.stedfesting;
    const l = useLanguage();
    const language: Language = useSelector((state: ApplicationState) => state.settings.language, shallowEqual);
    const sted = isNullOrEmpty(funnsted) ? kommune : kommaseparert([kommune, funnsted]);
    return (
        <span className="tooltip-header-info">
            <IconComponent stedfesting={props.stedfesting} className="tooltip-icon"/>
            <span className="info-grid">
                <span className="field-label">{l('filterbar.date')}</span>
                <span>{formatDateStringToLocaleString(dato, language)}</span>
                <span className="field-label">{l('filterbar.place')}</span>
                <span>{sted}</span>
            </span>
        </span>
    );
}
