import pick from 'ramda/es/pick';
import { AreaFilter } from '../../services/areaService';
import keys from 'ramda/es/keys';
import { LocationType } from '../../constants/locationType';
import {Area} from "../../model/area/area";

export function createLocationString(areas: Area[], defaultString: string): string {
    let places = '';
    if (areas === null) {
        places = defaultString;
    } else if (areas.length > 0) {
        areas.map(o => {
            if (places === '') {
                places = o.label;
            } else {
                places = places.concat(`, ${o.label}`);
            }
        });
    }
    return places;
}

export const containsAll = (mainArray: number[], subArray: number[]) => subArray.every(i => mainArray.includes(i));

export const searchFilterToAreaSearchFilter = pick(['Country', 'County', 'Municipality', 'Region']);

export const locationIDsNotInAreaObjects = (filter: AreaFilter, areas: Area[]): boolean => {
    return keys(filter).some(
        type =>
            !filter[type].every(value =>
                areas
                    .filter(area => area.locationType === LocationType[type])
                    .some(area => area.value === value)
            )
    );
};
