import { createStyles, makeStyles, Theme } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import LinkIcon from '@material-ui/icons/Link';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../i18n';
import './share.scss';

type OwnProps = {
    copyUrl: string;
    mailLink: string;
    feedbackObject: any;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        shareLinkWrapper: {
            color: theme.palette.primary.main,
            '&:focus': {
                outline: `1px solid ${theme.palette.primary.light}`,
            },
        },
        mailIcon: {
            fill: theme.palette.primary.main,
            height: '24px',
        },
        icon: {
            fill: theme.palette.primary.main,
        },
        feedbackMessage: {
            backgroundColor: theme.palette.secondary.dark,
        },
    })
);

const copyToClipboard = (url: string): void => {
    const el = document.createElement('textarea'); // Create a <textarea> element
    el.value = url; // The string to copy
    el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
    el.style.position = 'absolute';
    el.style.left = '-9999px'; // Move outside the screen to make it invisible
    document.body.appendChild(el); // Append the <textarea> element to the HTML document
    const selected =
        document.getSelection().rangeCount > 0 // Check if there is any content selected previously
            ? document.getSelection().getRangeAt(0) // Store selection if found
            : false; // Mark as false to know no selection existed before
    el.select(); // Select the <textarea> content
    document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
    document.body.removeChild(el); // Remove the <textarea> element
    if (selected) {
        // If a selection existed before copying
        document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
        document.getSelection().addRange(selected); // Restore the original selection
    }
};

export function Share(props: OwnProps): JSX.Element {
    const classes = useStyles({});
    const l = useLanguage();
    const [feedback, setFeedback] = useState(false);
    const feedbackMessage = classnames(classes.feedbackMessage, 'feedback-message');
    const copyLinkWrapper = classnames(classes.shareLinkWrapper, 'copy-link-wrapper');
    const mailLinkWrapper = classnames(classes.shareLinkWrapper, 'mail-link-wrapper');

    const copy = (url: string): void => {
        setFeedback(true);
        copyToClipboard(url);
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setFeedback(false);
        }, 4000);
        return () => clearTimeout(timer);
    }, [feedback === true]);

    useEffect(() => {
        setFeedback(false);
    }, [props.feedbackObject]);

    return (
        <div className="share">
            <div className="share-title">{l('shareSite.share')}</div>
            <div className="share-buttons-container">
                <button className={copyLinkWrapper} onClick={() => copy(props.copyUrl)}>
                    <LinkIcon className={classes.icon} aria-hidden={true} />
                    {l('tooltip.copyLink')}
                </button>
                <a href={props.mailLink} className={mailLinkWrapper}>
                    <MailOutlineIcon className={classes.mailIcon} aria-hidden={true} />
                    {l('tooltip.mailLink')}
                </a>
            </div>
            {feedback && (
                <div className={feedbackMessage}>
                    <CheckIcon className={classes.icon} aria-hidden={true} />
                    <span>{l('shareSite.feedback')}</span>
                </div>
            )}
        </div>
    );
}
