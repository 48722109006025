import {IndividualData} from "../../components/searchresultList/searchresultTitle";

// export type SidePanelFilter = {
//     // carnivore: number[];
// };

export type MenuState = {
    open: boolean;
};

export type SidePanelFilterState = {
    // sidePanelState: Readonly<SidePanelFilter>;
    individ: IndividualData;
    menuState: MenuState;
};

export const UPDATE = '@sidePanel/UPDATE';
export const OPEN = '@sidePanel/OPEN';
export const CLOSE = '@sidePanel/CLOSE';
export const SET_INDIVID = '@sidePanel/SET_INDIVID';
export const REMOVE_INDIVID = '@sidePanel/SET_INDIVID';
