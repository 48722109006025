import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import React, {ChangeEvent, ReactElement} from 'react';
import './toggleSwitch.scss';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        input: {
            '& + .switch-label::before': {
                backgroundColor: theme.palette.grey[200],
            },
            '& + .switch-label::after': {
                backgroundColor: theme.palette.grey[600],
                boxShadow: '0 0 0 2px white',
            },
            '&:checked + .switch-label::before': {
                backgroundColor: theme.palette.secondary.dark,
            },
            '&:checked + .switch-label::after': {
                backgroundColor: theme.palette.primary.main,
            }
        },
        toggleSwitch: {
            '&:focus-within .switch-label::before': {
                outline: `2px solid ${theme.palette.background.paper}`,
                transition: '0s'
            }
        },
        
    })
);

type ToggleSwitchProps = {
    id: string;
    label: string;
    checked: boolean;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

export function ToggleSwitch(props: ToggleSwitchProps): ReactElement {
    const classes = useStyles({});
    const toggleSwitchClasses = classnames('toggle-switch', classes.toggleSwitch);

    return (
        <div className={toggleSwitchClasses}>
            <input
                id={props.id}
                className={classes.input}
                type="checkbox"
                role="switch"
                aria-checked={props.checked}
                onChange={e => props.onChange(e)}
                checked={props.checked}
            />
            <label className="switch-label" htmlFor={props.id} >
                {props.label}
            </label>
        </div>
    );
    
    
}
