import React, {Fragment, ReactElement} from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {AboutRovbaseData} from "./about";

type AboutENProps = {
    aboutRovbaseData: AboutRovbaseData
}

export function AboutContentEN(props: AboutENProps): ReactElement {
    return (
        <>
            <header>
                <h2>About Rovbase</h2>
                <p className="about-ingress">
                    Rovbase is a management tool to accumulate and verify information on the distribution and occurrence of 
                    carnivores, the depredation on livestock of carnivores, and the compensation for depredation of 
                    carnivores on livestock.
                </p>
            </header>
            <p>
                The database consists of both a production database (login required) and a web-site for public access to data. 
                The production database may contain information not displayed in the public web-site. 
                The difference is caused by the need to limit access to sensitive information, determined e.g. by cases still 
                undergoing verification, police investigation or where disclosure may compromise sensitive information on 
                persons involved.
            </p>
            <h3>Content in Rovbase</h3>
            <div className="about-statistics">
                <span>As of today's date the database contains the following data:</span>
                <ul className="statistics-list">
                    {props.aboutRovbaseData && (
                        <Fragment>
                            <li>
                                <NavigateNextIcon />
                                <span>{props.aboutRovbaseData.carnivoreObserved.toLocaleString()}</span>
                                <span>Carnivore observations</span>
                            </li>
                            <li>
                                <NavigateNextIcon />
                                <span>{props.aboutRovbaseData.carnivoreDamageSheep.toLocaleString()}</span>
                                <span>Carnivore damages on sheep</span>
                            </li>
                            <li>
                                <NavigateNextIcon />
                                <span>{props.aboutRovbaseData.carnivoreDamageReindeer.toLocaleString()}</span>
                                <span>Carnivore damages on semi-domesticated reindeer</span>
                            </li>
                            <li>
                                <NavigateNextIcon />
                                <span>{props.aboutRovbaseData.carnivoreDead.toLocaleString()}</span>
                                <span>Dead carnivores</span>
                            </li>
                            <li>
                                <NavigateNextIcon />
                                <span>{props.aboutRovbaseData.dnaSamples.toLocaleString()}</span>
                                <span>DNA samples</span>
                            </li>
                        </Fragment>
                    )}
                </ul>
            </div>
        </>
    )
}
