import React, {useEffect, useState} from 'react';
import {AreaSearchTypes} from '../../constants/areasearchTypes';
import {RefundAnimal} from '../../constants/refundAnimal';
import {useLanguage} from '../../i18n';
import {useActionCreator} from '../../store';
import {updateLocation, updateLocationReindeer} from '../../store/refund/action';
import AreaSearchableSelect from '../searchableSelect/areaSearchableSelect';
import YearSelector from '../yearSelector/yearSelector';
import './compensationSelector.scss';
import {Area} from '../../model/area/area';
import {ReindeerLocation} from '../../model/area/reindeerLocation';

type OwnProps = {
    animal: RefundAnimal;
    location: Area[] | ReindeerLocation[];
    locationPlaceholder: string;
    areaSearchType: AreaSearchTypes;
    onLocationChange: (location: Area | ReindeerLocation | {}) => void;
};

export default function CompensationSelector(props: OwnProps): JSX.Element {
    const l = useLanguage();
    const updateSheepLocation = useActionCreator(updateLocation);
    const updateReindeerLocation = useActionCreator(updateLocationReindeer);
    const searchLocation = props.location;
    const [locationValue, setLocationValue] = useState<Area[] | ReindeerLocation[]>(searchLocation);
    const season =
        props.animal === RefundAnimal.Sheep
            ? l('compensation.sheepSeason').toUpperCase()
            : l('compensation.reindeerSeason').toUpperCase();
    
    useEffect(() => {
        setLocationValue(searchLocation);
    }, [props.animal]);

    const onSelectedLocationChange = (selectedOption: any, action: any): void => {
        switch (action.action) {
            case 'select-option':
                if (props.animal === RefundAnimal.Sheep) {
                    updateSheepLocation(selectedOption);
                    props.onLocationChange([selectedOption]);
                }
                else {
                    updateReindeerLocation(selectedOption);
                    props.onLocationChange([selectedOption]);
                }
                setLocationValue([selectedOption]);
                break;
            case 'remove-value':
                setLocationValue([]);
                break;
            case 'clear':
                setLocationValue([]);
                break;
            }
    };

    return (
        <div className="compensation-selector-content">
            <div className="location-search" id="locationSearchField">
                <label id="locationSearchLabel">{props.locationPlaceholder}</label>
                <AreaSearchableSelect
                    aria-labelledby="locationSearchLabel"
                    areas={locationValue}
                    onChange={onSelectedLocationChange}
                    placeholder={props.locationPlaceholder}
                    noOptionsMessage={l('searchableSelect.noOptionsMessage')}
                    filterMenu={false}
                    isMultiSelect={false}
                    type={props.animal === RefundAnimal.Sheep ? AreaSearchTypes.norway : AreaSearchTypes.reindeer}
                />
            </div>
            <div>
                <div>{season}</div>
                <YearSelector animal={props.animal}/>
            </div>
            <p className="compensation-help-autosearch">{l('compensation.messageAutosearch')}</p>
        </div>
    );
}
