import { ReportKeys } from '../constants/reportKeys';
import {models} from 'powerbi-client';
import {useMobileBreakpointLandscapeInnsyn, useMobileBreakpointPortraitInnsyn} from "../hooks/breakpoint";

export type ReportsResponse = {
    embedToken: EmbedToken,
    reports: EmbedReport[]
}

type EmbedReport = {
    reportId: string;
    reportName: string;
    embedUrl: string;
}

type EmbedToken = {
    token: string;
    tokenId: string;
    tokenExpiry: string;
}

export async function getReportId(reportKey:string) : Promise<string | undefined> {
    const req = createRequest();
    const response = await fetch(`/api/report/id/${reportKey}`, req);
    if (response.ok) {
        return await response.json() as string;
    }
}

let cachedReportStatus : Record<ReportKeys, boolean> | undefined = undefined;

export async function checkReportStatus(reportKey:ReportKeys) : Promise<boolean | undefined> {
    if(cachedReportStatus !== undefined){
        return Promise.resolve(cachedReportStatus[reportKey]);
    }
    
    const req = createRequest();
    const response = await fetch('/api/report/status/', req);
    if (response.ok) {
        cachedReportStatus = await response.json() as typeof cachedReportStatus;
        return cachedReportStatus[reportKey];
    }
}

export async function getReports() : Promise<ReportsResponse | undefined> {
    const req = createRequest();
    const response = await fetch('/api/reports', req);
    if (response.ok) {
        return await response.json() as ReportsResponse;
    }
}

function createRequest(): object {
    const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
    });
    return {
        method: 'GET',
        headers: headers
    };
}

export function useLayout() : models.LayoutType {
    const mobilePortrait = useMobileBreakpointPortraitInnsyn();
    const mobileLandscape = useMobileBreakpointLandscapeInnsyn();
    
    if(mobilePortrait || mobileLandscape){
        // vi benytter ikke eget landscape layout for mobil
        return models.LayoutType.MobilePortrait;
    } else {
        return models.LayoutType.Master;
    }
}
