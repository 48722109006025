import any from 'ramda/es/any';
import values from 'ramda/es/values';
import all from 'ramda/es/all';
export enum LayerType {
    WMS,
    WMTS,
    VECTOR,
}

export enum AccessMethod {
    NONE,
    BAAT,
    MAPP,
}

export enum LayerGroup {
    BG = 'BG',
}

export type Layer = {
    id: string;
    urls: string[];
    type: LayerType;
    access: AccessMethod;
    options?: any;
    params?: any;
    group?: LayerGroup;
    tileUrl?: string;
};

export type LayerCollection = { [id: string]: Layer };

export function isLayer(obj: Layer | LayerCollection): obj is Layer {
    return typeof obj.id === 'string' && Array.isArray(obj.urls) && LayerType[obj.type as LayerType] !== undefined;
}

export function isLayerGroup(obj: Layer | LayerCollection): obj is LayerCollection {
    return isLayer(values(obj)[0]);
}

export function isVisible(layer: Layer | LayerCollection): boolean {
    if (isLayer(layer)) {
        return layer.options.visible;
    } else if (isLayerGroup(layer)) {
        return all(layer => layer.options.visible, values(layer));
    }
}
