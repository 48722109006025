import * as React from 'react';
import { Provider, useSelector } from 'react-redux';
import { Store } from 'redux';
import { About } from '../../pages/about/about';
import { Home } from '../../pages/home';
import { Accessibility } from '../../pages/accessibility/accessibility';
import { BrowserRouter as Router, Route, withRouter, Switch } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../styles/materialTheme';
import themeSvensk from '../../styles/materialThemeSvensk';
import './root.scss';
import { Menu } from '../menu/menu';
import Loader from '../loader/loader';
import { ApplicationState } from '../../store';
import { Site } from '../../store/settings/types';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { Contact } from '../../pages/contact';
import { Redirect } from 'react-router';
import { ErrorBoundary } from '../errorBoundary/errorBoundary';
import { reactPlugin, appInsight} from '../../applicationInsights';
import {RefundSheep} from '../../pages/refundSheep';
import {RefundReindeer} from '../../pages/refundReindeer';
import {useEffect} from 'react';
import {ReportDodeRovdyr} from '../../pages/report/reportdoderovdyr';
import {ReportRovviltskade} from '../../pages/report/reportrovviltskade';
export const history = createBrowserHistory();

type RootProps = {
    store: Store;
};

function Root(): JSX.Element {
    const site = useSelector((state: ApplicationState) => state.settings.site);
    const themeCurrent = site === Site.SE ? themeSvensk : theme;
    const colorTheme = site === Site.SE ? 'nv' : 'md';
    document.documentElement.lang = useSelector((state: ApplicationState) => state.settings.language);

    useEffect(() => {
        document.documentElement.setAttribute(
            'data-color-theme',
            colorTheme
        );
    }, [colorTheme]);

    
    return (
        <Router>
            <ThemeProvider theme={themeCurrent}>
                <Menu />
                <Switch>
                    <Route path={'/'} exact={true} component={withRouter(Home)} />
                    <Route path={'/test'} exact={true} component={withRouter(Loader)} />
                    <Route path={'/filter'} component={withRouter(Home)} />
                    <Route path={'/individ'} component={withRouter(Home)} />
                    <Route path={'/search'} component={withRouter(Home)} />
                    <Route path={'/rapport/doderovdyr'} component={withRouter(ReportDodeRovdyr)} />
                    <Route path={'/rapport/rovviltskade'} component={withRouter(ReportRovviltskade)} />
                    <Route path={'/erstatning/sau'} component={withRouter(RefundSheep)} />
                    <Route path={'/erstatning/rein'} component={withRouter(RefundReindeer)} />
                    <Route path={'/om'} component={withRouter(About)} />
                    <Route path={'/kontakt'} component={withRouter(Contact)} />
                    <Route path={'/tilgjengelighet'} component={withRouter(Accessibility)} />
                    <Redirect from={'/*'} to="/" />
                </Switch>
            </ThemeProvider>
        </Router>
    );
}

function _Root({ store }: RootProps): JSX.Element {
    return (
        <Provider store={store}>
            <ErrorBoundary>
                <Root />
            </ErrorBoundary>
        </Provider>
    );
}

const rootExport = appInsight ? withAITracking(reactPlugin, _Root, null, 'contentWrapper') : _Root;

// @ts-ignore
export default rootExport;

