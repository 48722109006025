import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import React, {HTMLAttributes, PropsWithChildren, ReactChild, ReactElement, useEffect, useState} from "react";
import './expansionField.scss';
import { last, head } from 'ramda';

type ExpansionFieldProps = {
    children: [ReactElement, ReactChild];
    expanded?: boolean;
    onChangeExpanded?: () => void;
};

export function ExpansionField({
                                   children,
                                   expanded,
                                   onChangeExpanded,
                               }: ExpansionFieldProps & HTMLAttributes<HTMLDivElement>): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);
    const [overflow, setOverflow] = useState<boolean>(false);
    const expansionPanelOpen = open ? 'panel-open' : 'panel-closed';
    
    useEffect(() => {
        setOpen(expanded);
    }, [expanded !== undefined && expanded !== open]);

    useEffect(() => {
        if (open) {
            setTimeout(function() {
                setOverflow(true);
            }, 300);
        }
        setOverflow(false);
    }, [open]);

    return (
        <div className="expansion-field">
            <div className={overflow ? `${expansionPanelOpen} overflow` : expansionPanelOpen}>
                <div className="main-content">{children[1]}</div>
            </div>
            {React.cloneElement(children[0], {
                clickHandler: () => {
                    setOpen(!open);
                    onChangeExpanded && onChangeExpanded();
                },
                open: open,
            })}
        </div>
    );
}

type ExpansionFieldTitleProps = {
    label: [string] | [string, string];
    clickHandler?: () => void;
    open?: boolean;
};

export const ExpansionFieldTitle = (
    props: ExpansionFieldTitleProps & HTMLAttributes<HTMLButtonElement>
): JSX.Element => {
    const handler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        event.preventDefault();
        props.clickHandler();
    };

    return (
        <button className="expansion-field-title" onClick={handler} aria-expanded={props.open}>
            <span className={props.open ? 'panel-icon expanded' : 'panel-icon'}>
                {props.open ? <RemoveIcon /> : <AddIcon fontSize="large" />}
            </span>
            <span>{props.open ? last(props.label) : head(props.label)}</span>
        </button>
    );
};

export const ExpansionFieldContent = (props: PropsWithChildren<{}>): JSX.Element => {
    return <>{props.children}</>;
};