import React from 'react';
import {useSelector} from 'react-redux';
import {useLanguage} from '../../i18n';
import {ApplicationState, useActionCreator} from '../../store';
import {DeSelectAllAndSearch, SelectAllAndSearch,} from '../../store/searchfilter/action';
import {CheckboxWrapper} from '../checkbox/checkbox';
import Searchbar from '../searchbar/searchbar';
import './searchfilter.scss';
import {CarnivoreFilter} from '../carnivoreFilter/carnivoreFilter';
import {allCarnivoreArray} from '../../constants/carnivore';
import DateFilter from '../dateFilter/dateFilter';
import {RegistrationsFilter} from '../registrationsFilter/registrationsFilter';
import {GeographyFilter} from '../geographyFilter/geographyFilter';
import {EvaluationFilter} from '../evaluationFilter/evaluationFilter';
import {containsAll} from './searchfilter.utils';
import {useMobileBreakpointInnsyn} from '../../hooks/breakpoint';
import {SkipFilters} from '../themeButtton/themeButton';
import {useLoadingDots} from '../../hooks/loadingDots';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        helpMessage: {
            backgroundColor: theme.palette.secondary.main
        }
    })
);

export function Searchfilter(): JSX.Element {
    const l = useLanguage();
    const classes = useStyles({});
    const resultCount: number = useSelector((state: ApplicationState) => state.map.stedfesting.length);
    const carnivoreSelected =
        useSelector((state: ApplicationState) => state.searchFilter.searchFilter.Carnivore).length > 0;
    const mobileScreen = useMobileBreakpointInnsyn();
    const searchFilter = useSelector((state: ApplicationState) => state.searchFilter.searchFilter);
    const isLoading = useSelector((state: ApplicationState) => state.settings.isLoading);
    const deselectAll = useActionCreator(DeSelectAllAndSearch);
    const selectAll = useActionCreator(SelectAllAndSearch);
    const loadingDots = useLoadingDots();
    const helpMessageClass = classnames(classes.helpMessage, 'help-message-autosearch');

    const getSearchfilterHeader = (): string => {
        if (isLoading) {
            return `${l('searchresultList.loading')}${loadingDots}`;
        }
        return carnivoreSelected
            ? `${resultCount} ${l('searchresultList.hits')}`
            : l('searchfilter.selectCarnivore');
    };

    function ToggleAllMainCarnivore(): void {
        if (containsAll(searchFilter.Carnivore, allCarnivoreArray)) {
            // alle er valgt
            deselectAll('Carnivore');
        } else {
            selectAll('Carnivore', allCarnivoreArray);
        }
    }

    return (
        <main>
            <div className="searchfilter">
                <h2 className="title">{getSearchfilterHeader()}</h2>
                <p className={helpMessageClass}>{l('searchfilter.messageAutosearch')}</p>
                {mobileScreen && (
                    <div className="checkbox-toggle-all-mobile">
                        <CheckboxWrapper
                            label={l('filterbar.showAll')}
                            selected={containsAll(searchFilter.Carnivore, allCarnivoreArray)}
                            onChange={() => ToggleAllMainCarnivore()}
                        />
                    </div>
                )}

                <CarnivoreFilter/>
                {mobileScreen && (
                    <div className="searchbar-mobile">
                        <Searchbar/>
                    </div>
                )}

                {/* The filter panel appears when at least one carnivore is selected */}
                {carnivoreSelected && (
                    <>
                        <SkipFilters/>
                        <DateFilter/>
                        <RegistrationsFilter/>
                        <GeographyFilter/>
                        <EvaluationFilter/>
                    </>
                )}
            </div>
        </main>
    );
}

