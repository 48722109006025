import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core/';
import React from 'react';
import { useSelector } from 'react-redux';
import { DamageCause } from '../../constants/damageCause';
import { useLanguage } from '../../i18n';
import { ApplicationState } from '../../store';
import {DamageCausePieChart} from '../damageCauseTable/damageCauseTable';
import '../damageCauseTable/damageCauseTable.scss';
import { getStringFromEnum } from '../tooltip/tooltip.util';
import DamageCauseTableReindeerSmall from './damageCauseTableReindeerSmall';
import {useTabletBreakpointInnsyn} from '../../hooks/breakpoint';

export default function DamageCauseTableReindeer(): JSX.Element {
    const damageCauseData = useSelector((state: ApplicationState) => state.refund.reindeerStatistics.damageCauseList);
    const pieChartData = damageCauseData.filter(row => row.artID !== 'Totalt');
    const l = useLanguage();
    const mediumScreen = useTabletBreakpointInnsyn();

    return (
        <div className="damage-cause">
            <h3>{l('refund.compensationCarnivoreTitle')}</h3>
            <div className="damage-cause-table-and-chart">
                {mediumScreen ? (
                    <DamageCauseTableReindeerSmall apiData={damageCauseData} />
                ) : (
                    <Table size="small" className="damage-cause-table">
                        <TableHead>
                            <TableRow>
                                <TableCell>{l('refund.species')}</TableCell>
                                <TableCell>{l('refund.calf')}</TableCell>
                                <TableCell>{l('refund.bull')}</TableCell>
                                <TableCell>{l('refund.doe')}</TableCell>
                                <TableCell>{l('refund.driving')}</TableCell>
                                <TableCell>{l('refund.compensatedTotal')}</TableCell>
                                <TableCell>{l('refund.compensatedPercent')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {damageCauseData.map(row => (
                                <TableRow key={row.artID} hover={true}>
                                    <TableCell scope="row">
                                        {row.artID === 'Totalt'
                                            ? 'Totalt'
                                            : l(`carnivore.${getStringFromEnum(DamageCause, row.artID)}`)}
                                    </TableCell>
                                    <TableCell>{row.compensatedCalf.toLocaleString()}</TableCell>
                                    <TableCell>{row.compensatedBull.toLocaleString()}</TableCell>
                                    <TableCell>{row.compensatedDoe.toLocaleString()}</TableCell>
                                    <TableCell>{row.compensatedDriving.toLocaleString()}</TableCell>
                                    <TableCell>{row.compensatedTotal.toLocaleString()}</TableCell>
                                    <TableCell>{row.percent.toFixed(1)} %</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                <div className="damage-cause-pie-chart">
                    <DamageCausePieChart data={pieChartData} />
                </div>
            </div>
        </div>
    );
}
