import React from 'react';
import { useSelector } from 'react-redux';
import { RefundAnimal } from '../../constants/refundAnimal';
import { ApplicationState } from '../../store';
import './statisticalInfoYears.scss';
import {
    CompensationAmountChart,
    ReindeerCompensationChart,
    SheepCompensationChart,
} from './statisticalInfoYears.util';
import { useLanguage } from '../../i18n';

type InfoProps = {
    fromYear: string;
    toYear: string;
    animal: RefundAnimal;
};

export default function StatisticalInfoYears(props: InfoProps): JSX.Element {
    const l = useLanguage();
    const sheepData = useSelector((state: ApplicationState) =>
        state.refund.sheepStatistics.sheepCompensationArea.filter(
            obj => obj.area === 'Totalt' && obj.year !== 'ALLE' && obj.year !== ''
        )
    );
    const reindeerData = useSelector((state: ApplicationState) =>
        state.refund.reindeerStatistics.reindeerCompensationArea.filter(
            obj => obj.area === 'Totalt' && obj.year !== 'ALLE' && obj.year !== ''
        )
    );
    const apiData = props.animal === RefundAnimal.Sheep ? sheepData : reindeerData;
    const windowWidth = window.innerWidth;
    const boxHeight = windowWidth > 600 ? 400 : 400;
    const boxWidth = '100%';
    const refundHeader =
        props.animal === RefundAnimal.Sheep
            ? `${l('refund.sheepCompensation')} ${props.fromYear} - ${props.toYear}`
            : `${l('refund.reindeerCompensation')} ${props.fromYear} - ${props.toYear}`;
    const compensationHeader = `${l('refund.compensationAmount')} ${props.fromYear} - ${props.toYear}`;

    return (
        <div className="yearsInfoWrapper">
            <div className="first-area-chart">
                <div className="header">{refundHeader}</div>
                {props.animal === RefundAnimal.Sheep ? (
                    <SheepCompensationChart width={boxWidth} height={boxHeight} apiData={sheepData} />
                ) : (
                    <ReindeerCompensationChart width={boxWidth} height={boxHeight} apiData={reindeerData} />
                )}
            </div>
            <div className="second-area-chart">
                <CompensationAmountChart
                    apiData={apiData}
                    width={boxWidth}
                    height={boxHeight}
                    header={compensationHeader}
                    animal={props.animal}
                />
            </div>
        </div>
    );
}
