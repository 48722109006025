import {isEmpty} from 'ramda';
import * as React from 'react';
import {useSelector} from 'react-redux';
import CompensationSelector from '../components/compensationSelector/compensationSelector';
import DamageCauseTableReindeer from '../components/damageCauseTableReindeer/damageCauseTableReindeer';
import {LoadingSpinner} from '../components/loader/loader';
import StatisticalInfoBox from '../components/statisticalInfoBox/statisticalInfoBox';
import StatisticalInfoYears from '../components/statisticalInfoYears/statisticalInfoYears';
import StatisticalTableReindeer from '../components/statisticalTableReindeer/statisticalTableReindeer';
import {createLabel} from '../components/yearpicker/yearperiodpicker';
import {RefundAnimal} from '../constants/refundAnimal';
import {ReindeerLocationType} from '../constants/reindeerLocationType';
import {useLanguage} from '../i18n';
import {ApplicationState, useActionCreator} from '../store';
import {fetchRefundData, fetchSearchResultReindeer} from '../store/refund/action';
import {AreaSearchTypes} from '../constants/areasearchTypes';
import StaticPageFrame from '../components/staticPageFrame/staticPageFrame';
import {ErrorBoundary} from '../components/errorBoundary/errorBoundary';
import {Suspense} from 'react';
import {ReindeerLocation} from '../model/area/reindeerLocation';
import {HoppTilHovedinnhold} from "../components/themeButtton/themeButton";
import {NumberListItem} from "../store/refund/types";

export function toYearReindeer(year: number): NumberListItem {
    return { value: year, label: `${year.toString()}/${(year + 1).toString()}` };
}

export function RefundReindeer(): JSX.Element {
    const animal = RefundAnimal.Reindeer;
    const getListData = useActionCreator(fetchRefundData);
    const getDataReindeer = useActionCreator(fetchSearchResultReindeer);
    const isFetching = useSelector((state: ApplicationState) => state.refund.isFetching);
    const filter = useSelector((state: ApplicationState) => state.refund.refundFilter);
    const l = useLanguage();
    const [location, setLocation] = React.useState<ReindeerLocation[]>([
        { value: '1', label: l('refund.reindeerAllAreas'), locationType: ReindeerLocationType.Country },
    ]);
    const header = createHeader();
    const fromYear = filter.FromYear && filter.FromYearRein;
    const toYear = filter.ToYear && filter.ToYearRein;
    const locationPlaceholder = l('refund.reindeerAreaDistrict');
    const areaSearchType: AreaSearchTypes = AreaSearchTypes.reindeer;
    const multipleYears = fromYear && fromYear.value !== toYear.value;
    
    React.useEffect(() => {
        getListData();
    }, []);
  
    React.useEffect(() => {
        getDataReindeer();
    }, [filter, animal]);

    function createHeader(): string {
        let year = createLabel(filter.FromYearRein, filter.ToYearRein);
        let locationString = isEmpty(location) || location[0].label === l('country.norway')
                ? l('refund.reindeerAllAreas')
                : location[0].label;
        return `${locationString} ${year}`;
    }
    
    return (
        <StaticPageFrame>
            <HoppTilHovedinnhold mainContentId={"locationSearchField"} />
            <h2>
                {l('compensation.header')}
                {l('refund.reindeer').toLowerCase()}
            </h2>
            
            <Suspense fallback={<div>Loading...</div>}>
                <CompensationSelector
                    animal={animal}
                    location={location}
                    onLocationChange={setLocation}
                    locationPlaceholder={locationPlaceholder}
                    areaSearchType={areaSearchType}
                />
            </Suspense>

            {/* Grafer */}
            {isFetching ? (
                <div className="compensation-loader">
                    <LoadingSpinner />
                </div>
            ) : (
                <ErrorBoundary>
                    <StatisticalInfoBox animal={animal} header={header} period={isEmpty(fromYear) && isEmpty(toYear)} />
                    {multipleYears && (
                        <StatisticalInfoYears fromYear={fromYear.label} toYear={toYear.label} animal={animal} />
                    )}
                    <StatisticalTableReindeer />
                    <DamageCauseTableReindeer />
                </ErrorBoundary>
            )}
        </StaticPageFrame>
    );
}
