import React, { useEffect, useState } from 'react';
import { Reportpage } from '../../components/reportpage/reportpage';
import { useLanguage } from '../../i18n';
import {useRovviltskadeRapport} from '../../hooks/featureToggles';
export function ReportRovviltskade(): JSX.Element {
    const l = useLanguage();
    const showReportRovviltskade = useRovviltskadeRapport();

    if (!showReportRovviltskade) {
        return null;
    }

    return (
        <Reportpage reportKey={'rovviltskade'} reportName={l('menu.reportRovviltskade')} reportDescription={l('rovviltskade.report.description')} />
    );
}