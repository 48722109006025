import {MenuItem, Select, Theme} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/styles';
import React from 'react';
import {useSelector} from 'react-redux';
import {useLanguage} from '../../i18n';
import {ApplicationState, useActionCreator} from '../../store';
import {setLanguage} from '../../store/settings/actions';
import {isLang, Language} from '../../constants/language';
import './languageSelector.scss';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        select: {
            color: theme.palette.primary.main,
        },
    })
);

export function LanguageSelector(): JSX.Element {
    const l = useLanguage();
    const classes = useStyles({});
    const language = useSelector((state: ApplicationState) => state.settings.language);
    const updateLanguage = useActionCreator(setLanguage);
    const changeHandler = (event: React.ChangeEvent<HTMLSelectElement>): void => {
        const id = event.target.value;
        if (isLang(id)) updateLanguage(id);
    };

    return (
        <div className="language-selector">
            <label htmlFor={'languageSelector'}>
                    {l('languageSelect.language')}
            </label>
                <Select className={classes.select} value={language} id={'languageSelector'} onChange={changeHandler}>
                    <MenuItem value={Language.NO}>Norsk</MenuItem>
                    <MenuItem value={Language.SV}>Svenska</MenuItem>
                    <MenuItem value={Language.EN}>English</MenuItem>
                </Select>
        </div>
    );
}
