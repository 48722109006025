import React, {ReactElement} from 'react';
import {ExternalLink} from '../../components/externalLInk/externalLink';
import {useLanguage} from '../../i18n';
import {useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {Language} from '../../constants/language';

const norskURL = 'https://uustatus.no/nb/erklaringer/publisert/4dda6a97-983d-4bf5-95b0-61b8178a168b';


export function AccesibilityContentNO(): ReactElement {
    const l = useLanguage();
    const language = useSelector((state: ApplicationState) => state.settings.language);
    return (
            <div className="accessibility-content">
                <header>
                    <h2>{l('accessibility.contentNOTitle')}</h2>
                </header>
                {language === Language.SV && <p>Läs på <ExternalLink href={norskURL}>uustatus.no</ExternalLink> (endast på norska) om hur Rovbase.no uppfyller den norska lagen om tillgänglighet till digital offentlig service.</p>}
                {language === Language.NO && <p>Les på <ExternalLink href={norskURL}>uustatus.no</ExternalLink> hvordan rovbase.no oppfyller de norske kravene til universell utforming i IKT-løsninger.</p>}
                {language === Language.EN && <p>On <ExternalLink href={norskURL}>uustatus.no</ExternalLink> (in norwegian only), you can read about how rovbase.no complies with the Norwegian law on web accessibility.</p>}
            </div>
    );
}