type ContactInfo = {
    visitAdress: string[];
    postAdress: string;
    mail: string;
    phone: string;
};

export const mdirInfo: ContactInfo = {
    visitAdress: ['Brattørkaia 15, 7010 Trondheim'],
    postAdress: 'Postboks 5672 Torgarden, 7485 Trondheim',
    mail: 'post@miljodir.no',
    phone: '+47 73 58 05 00',
};

export const naturvardsInfo: ContactInfo = {
    visitAdress: ['Virkesvägen 2, 120 30 Stockholm', 'Forskarens väg 5, 831 40 Östersund'],
    postAdress: 'Naturvårdsverket, 106 48 Stockholm',
    mail: 'kundtjanst@naturvardsverket.se',
    phone: '+46 010-698 10 00',
};

export const stripPhoneNumber = (phone: string): string => {
    return phone.replace(/[\s-]/g, '');
};
