import { Action } from 'redux';
import { Stedfesting } from '../../model/stedfesting/StedfestingTypes';
import { CLEAR_TOOLTIP, SetTooltipAction, SET_TOOLTIP } from './types';

export function setTooltip(stedfesting: Stedfesting): SetTooltipAction {
    return {
        type: SET_TOOLTIP,
        stedfesting
    };
}

export function ClearTooltip(): Action {
    return {
        type: CLEAR_TOOLTIP
    };
}
