let aborts: AbortController[] = [];

const abortAll = (): void => {
    aborts.forEach(a => a.abort());
    aborts = [];
};

export function postSearch(search: object): Promise<Response> {
    abortAll();
    const abortController = new AbortController();
    aborts.push(abortController);
    const { signal } = abortController;
    return fetch('api/Feature', { ...search, signal });
}
