export enum CarnivoreDamage {
    Sheep = 1,
    Reindeer = 2,
    Dog = 3,
    Goat = 4,
    Cattle = 5,
}

export const defaultCarnivoreDamageFilter = [CarnivoreDamage.Sheep, CarnivoreDamage.Reindeer, CarnivoreDamage.Dog, 
                                             CarnivoreDamage.Goat, CarnivoreDamage.Cattle]           ;
