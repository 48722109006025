import { Action } from 'redux';
import { Language } from '../../constants/language';

export type SettingsState = {
    language: Language;
    isLoading: boolean;
    site: Site;
    isInitialized: boolean;
};

export type LanguageAction = Action & {
    language: Language;
};

export type SiteAction = Action & {
    site: Site;
};

export enum Site {
    NO,
    SE,
}

export type isLoadingAction = Action & {
    isLoading: boolean;
};

export type isInitializedAction = Action & {
    isInitialized: boolean;
};

export const SET_LANGUAGE = '@settings/SET_LANGUAGE';
export const SET_ISLOADING = '@settings/SET_ISLOADING';
export const SET_HAS_SEARCHED = '@settings/SET_HAS_SEARCHED';
export const SET_SITE = '@settings/SET_SITE';
export const SET_ISINITIALIZED = '@settings/SET_ISINITIALIZED';
