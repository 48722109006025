export enum Observations {
    DeadCarnivores = 1,
    VisualObservation = 2,
    Footprint = 3,
    Faeces = 4,
    TerritorialMarking = 5,
    Reproduction = 6,
    TreeMarkings = 7,
    HairOrFeather = 8,
    Bed = 9,
    Digging = 10,
    AnalysedDna = 11,
    UnanalysedDna = 12,
    UnspecifiedType = 13,
}


export const defaultObservationsFilter = [Observations.DeadCarnivores, Observations.VisualObservation, Observations.Footprint, Observations.UnanalysedDna, Observations.AnalysedDna];
export const dnaObservations = [Observations.AnalysedDna, Observations.UnanalysedDna];
