import {Theme} from '@material-ui/core';
import {createStyles, makeStyles} from '@material-ui/styles';
import classnames from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';
import {Language} from '../../constants/language';
import {IconComponent} from '../../Icons/icons';
import {clusterSource, mapDispatch} from '../../map/map';
import {zoomToFeature, zoomToFeatureMobile} from '../../map/mapActions';
import {getColorFromFeature} from '../../map/styles';
import {toFeature} from '../../model/stedfesting/Stedfesting';
import {Stedfesting} from '../../model/stedfesting/StedfestingTypes';
import {ApplicationState, useActionCreator} from '../../store';
import {hoverStedfesting, selectStedfesting} from '../../store/map/actions';
import {setTooltip} from '../../store/tooltip/actions';
import {stedfestingTitle} from '../tooltip/tooltip.util';
import {formatDateStringToLocaleString} from '../../helpers';
import './searchresultItem.scss';
import {useMobileBreakpointInnsyn} from '../../hooks/breakpoint';

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        searchItem: {
            borderColor: theme.palette.secondary.dark,
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
            '&:focus': {
                backgroundColor: theme.palette.secondary.dark,
                outline: 'none',
            },
        },
    })
);

type ListProps = {
    stedfesting: Stedfesting;
    closeSidepanel: () => void;
};

export function getColor(stedfesting: Stedfesting): string {
    return getColorFromFeature(toFeature(stedfesting));
}

const funnsted = (stedfesting: Stedfesting): string =>
    stedfesting.funnsted.length > 0 ? ` - ${stedfesting.funnsted}` : '';

function searchresultItemTitle(language: Language, stedfesting: Stedfesting): string {
    let title = stedfestingTitle(language, stedfesting);
    if (title.length > 0) {
        title = title.concat(funnsted(stedfesting));
    }
    return title;
}

export function SearchresultItem(props: ListProps): JSX.Element {
    const lang = useSelector((state: ApplicationState) => state.settings.language);
    const setTooltipStedfesting = useActionCreator(setTooltip);
    const setSelectedStedfesting = useActionCreator(selectStedfesting);
    const setHoverStedfesting = useActionCreator(hoverStedfesting);

    const classes = useStyle({});
    const resultItemClass = classnames(classes.searchItem, 'result-button-item');
    const mobileScreen = useMobileBreakpointInnsyn();

    function clickOnItem(stedfesting: Stedfesting): void {
        mobileScreen && props.closeSidepanel();
        mobileScreen ? mapDispatch(zoomToFeatureMobile(stedfesting.id)) : mapDispatch(zoomToFeature(stedfesting.id));
        setSelectedStedfesting([stedfesting]);
        setTooltipStedfesting(stedfesting);
    }

    function pointerEnterHandle(): void {
        setHoverStedfesting(props.stedfesting);
        clusterSource.changed();
    }

    function pointerLeaveHandle(): void {
        setHoverStedfesting(null);
        clusterSource.changed();
    }

    return (
        <button
            className={resultItemClass}
            onClick={() => clickOnItem(props.stedfesting)}
            onPointerEnter={pointerEnterHandle}
            onPointerLeave={pointerLeaveHandle}
        >
            <IconComponent stedfesting={props.stedfesting} className={'searchresult-icon'} />
            <div className="result-text-wrapper">
                <div className="datatype-description">{searchresultItemTitle(lang, props.stedfesting)}</div>
                <div className="search-item-date">{formatDateStringToLocaleString(props.stedfesting.dato, lang)}</div>
                <div>{props.stedfesting.kommune}</div>
            </div>
        </button>
    );
}