import { Reducer } from 'redux';
import { LocationType } from '../../constants/locationType';
import {head} from 'ramda';
import {
    sheepCompensationArea,
    sheepCompensationDamageCause,
    reindeerCompensationArea,
    reindeerCompensationDamageCause,
} from '../../constants/statistics';
import {
    RECEIVE_REFUNDLISTDATA,
    RefundDataState,
    RefundFilter,
    REQUEST_REFUNDLISTDATA,
    SET_ISFETCHING,
    UPDATE_LOCATION,
    RECEIVE_REFUNDSEARCHRESULTSHEEP,
    UPDATE_FROMYEAR,
    UPDATE_TOYEAR,
    RECEIVE_REFUNDSEARCHRESULTREINDEER,
    UPDATE_LOCATIONREINDEER, Listeelement, UPDATE_FROMYEAR_REIN, UPDATE_TOYEAR_REIN,
} from './types';
import { ReindeerLocationType } from '../../constants/reindeerLocationType';

export const initialRefundFilter: RefundFilter = {
    ToYear: undefined,
    FromYear: undefined,
    ToYearRein: undefined,
    FromYearRein: undefined,
    Region: '',
    County: '',
    Municipality: '',
    Reinbeiteomrade: '',
    Reinbeitedistrikt: '',
};

const initState: RefundDataState = {
    refundFilter: initialRefundFilter,
    sheepStatistics: {
        sheepCompensationArea: [],
        damageCauseList: [],
    },
    reindeerStatistics: {
        reindeerCompensationArea: [],
        damageCauseList: [],
    },
    isFetching: true,
    listData: {
        reinbeitedistrikt: [],
        reinbeiteområder: [],
        reinerstatningÅr: [],
        sauerstatningÅr: [],
    },
};

const reducer: Reducer<RefundDataState> = (state = initState, action): RefundDataState => {
    switch (action.type) {
        case UPDATE_LOCATION:
            if (action.location.locationType === LocationType.Country) {
                return {
                    ...state,
                    refundFilter: {
                        ...state.refundFilter,
                        County: '',
                        Region: '',
                        Municipality: '',
                    },
                };
            }
            return {
                ...state,
                refundFilter: {
                    ...state.refundFilter,
                    County: '',
                    Region: '',
                    Municipality: '',
                    [LocationType[action.location.locationType]]: action.location.value,
                },
            };
        case UPDATE_LOCATIONREINDEER: {
            if (action.location.locationType === ReindeerLocationType.Country) {
                return {
                    ...state,
                    refundFilter: {
                        ...state.refundFilter,
                        Reinbeiteomrade: '',
                        Reinbeitedistrikt: '',
                    },
                };
            }
            return {
                ...state,
                refundFilter: {
                    ...state.refundFilter,
                    Reinbeiteomrade: '',
                    Reinbeitedistrikt: '',
                    [ReindeerLocationType[action.location.locationType]]: action.location.value,
                },
            };
        }
        case UPDATE_FROMYEAR: {
            return {
                ...state,
                refundFilter: {
                    ...state.refundFilter,
                    FromYear: action.fromYear,
                },
            };
        }
        case UPDATE_TOYEAR: {
            return {
                ...state,
                refundFilter: {
                    ...state.refundFilter,
                    ToYear: action.toYear,
                },
            };
        }
        case UPDATE_FROMYEAR_REIN: {
            return {
                ...state,
                refundFilter: {
                    ...state.refundFilter,
                    FromYearRein: action.fromYearRein,
                },
            };
        }
        case UPDATE_TOYEAR_REIN: {
            return {
                ...state,
                refundFilter: {
                    ...state.refundFilter,
                    ToYearRein: action.toYearRein,
                },
            };
        }
        case REQUEST_REFUNDLISTDATA:
            return {
                ...state,
                isFetching: true,
            };
        case SET_ISFETCHING: {
            return {
                ...state,
                isFetching: action.isLoading,
            };
        }
        case RECEIVE_REFUNDLISTDATA:
            const sauÅr: Listeelement[] = action.result.sauerstatningÅr;
            const reinÅr: Listeelement[] = action.result.reinerstatningÅr;
            const newestYearRein: Listeelement = head(reinÅr);
            const newestYearSau: Listeelement = head(sauÅr);
            return {
                ...state,
                refundFilter: {
                    ...state.refundFilter,
                    FromYear: {
                        value: Number(newestYearSau.id),
                        label: newestYearSau.tekst
                    },
                    ToYear: {
                        value: Number(newestYearSau.id),
                        label: newestYearSau.tekst
                    },
                    FromYearRein: {
                        value: Number(newestYearRein.id),
                        label: newestYearRein.tekst
                    },
                    ToYearRein: {
                        value: Number(newestYearRein.id),
                        label: newestYearRein.tekst
                    },
                },
                //isFetching: false,
                listData: {
                    reinerstatningÅr: reinÅr.map((obj: Listeelement) => ({
                        value: Number(obj.id),
                        label: obj.tekst,
                    })),
                    reinbeitedistrikt: action.result.reinbeitedistrikt.map(obj => ({
                        value: obj.id,
                        label: obj.tekst,
                    })),
                    reinbeiteområder: action.result.reinbeiteområder.map(obj => ({ value: obj.id, label: obj.tekst })),
                    sauerstatningÅr: sauÅr.map(obj => ({
                        value: Number(obj.id),
                        label: obj.tekst,
                    })),
                },
            };
        case RECEIVE_REFUNDSEARCHRESULTSHEEP:
            return {
                ...state,
                isFetching: false,
                sheepStatistics: {
                    sheepCompensationArea: action.result.erstatningSauOmradeListe.map(obj =>
                        mapToSheepCompensationList(obj)
                    ),
                    damageCauseList: action.result.erstatningSauSkadevolderListe.map(obj =>
                        mapToDamageCauseListSheep(obj)
                    ),
                },
            };

        case RECEIVE_REFUNDSEARCHRESULTREINDEER:
            return {
                ...state,
                isFetching: false,
                reindeerStatistics: {
                    reindeerCompensationArea: action.result.erstatningReinOmrådeListe.map(obj =>
                        mapToReindeerCompensationList(obj)
                    ),
                    damageCauseList: action.result.erstatningReinSkadevolderListe.map(obj =>
                        mapToDamageCauseListReindeer(obj)
                    ),
                },
            };
        default:
            return state;
    }
};

function mapToDamageCauseListSheep(obj: any): sheepCompensationDamageCause {
    const list: sheepCompensationDamageCause = {
        artID: obj.artID,
        compensatedSheep: obj.antallErstattetSau,
        compensatedLamb: obj.antallErstattetLam,
        compensatedTotal: obj.antallErstattetTotalt,
        percent: obj.prosent,
    };
    return list;
}

function mapToSheepCompensationList(obj: any): sheepCompensationArea {
    const list: sheepCompensationArea = {
        area: (obj.område as string).split(' (N)')[0],
        year: obj.beitesesong,
        applications: obj.antallSøknaderTotalt,
        normalLossSheep: obj.beregnetNormaltapSau,
        normalLossLamb: obj.beregnetNormaltapLam,
        normalLossTotal: obj.beregnetNormaltapTotalt,
        compensatedSheep: obj.erstattetSau,
        compensatedLamb: obj.erstattetLam,
        compensatedTotal: obj.erstattetTotalt,
        totalLossSheep: obj.totaltTapSau,
        totalLossLamb: obj.totaltTapLam,
        totalLossTotal: obj.totaltTapTotalt,
        compensationAmount: obj.erstatningsbelopTotalt,
    };
    return list;
}

function mapToDamageCauseListReindeer(obj: any): reindeerCompensationDamageCause {
    const list: reindeerCompensationDamageCause = {
        artID: obj.artID,
        compensatedCalf: obj.antallErstattetKalv,
        compensatedBull: obj.antallErstattetOkserein,
        compensatedDoe: obj.antallErstattetSimlerein,
        compensatedDriving: obj.antallErstattetKjørerein,
        compensatedTotal: obj.antallErstattetTotalt,
        percent: obj.prosent,
    };
    return list;
}

function mapToReindeerCompensationList(obj: any): reindeerCompensationArea {
    const list: reindeerCompensationArea = {
        area: (obj.område as string).split(' (N)')[0],
        year: obj.beitesesong,
        applications: obj.antallSøknader,
        compensatedCalf: obj.erstattetKalv,
        compensatedBull: obj.erstattetOkserein,
        compensatedDoe: obj.erstattetSimlerein,
        compensatedDriving: obj.erstattetKjørerein,
        compensatedTotal: obj.erstattetTotalt,
        appliedLossCalf: obj.omsøktTapKalv,
        appliedLossBull: obj.omsøktTapOkserein,
        appliedLossDoe: obj.omsøktTapSimlerein,
        appliedLossTotal: obj.omsøktTapTotalt,
        totalLossCalf: obj.totaltTapKalv,
        totalLossBull: obj.totaltTapOkserein,
        totalLossDoe: obj.totaltTapSimlerein,
        totalLossTotal: obj.totaltTapTotalt,
        compensationAmount: obj.erstatningsbeløp,
    };
    return list;
}

export { reducer as RefundReducer };
