import {isSameDay, subDays, subYears} from 'date-fns';
import {DatePreset} from './dateFilter.types';
import {dateToISOString} from '../../helpers';

const isLast7days = (today: string, fromDate: string, toDate: string): boolean =>
    isSameDay(fromDate, subDays(today, 7)) && isSameDay(toDate, today);

const isLastMonth = (today: string, fromDate: string, toDate: string): boolean =>
    isSameDay(fromDate, subDays(today, 30)) && isSameDay(toDate, today);

const isThisYear = (today: string, fromDate: string, toDate: string): boolean =>
    isSameDay(fromDate, subYears(today, 1)) && isSameDay(toDate, today);

const isBeforeOrSameDate = (date: string, dateToCompare: string): boolean =>
    date <= dateToCompare;

const isAfterOrSameDate = (date: string, dateToCompare: string): boolean =>
    date >= dateToCompare;

export function subtractDays(date: string, amount: number): string {
    return dateToISOString(subDays(date, amount));
}

export function subtractYears(date: string, amount: number): string {
    return dateToISOString(subYears(date, amount));
}

export function currentDatePreset(fromDate: string, toDate: string, today: string): DatePreset {
    if (isLast7days(today, fromDate, toDate)) {
        return DatePreset.LastSevenDays;
    } else if (isLastMonth(today, fromDate, toDate)) {
        return DatePreset.LastMonth;
    } else if (isThisYear(today, fromDate, toDate)) {
        return DatePreset.ThisYear;
    }
    return null;
}

export function isValidDate(dateString: string): boolean {
    return !isNaN(Date.parse(dateString));
}

export function dateIsBetween(checkDate: string, minDate: string, maxDate: string): boolean {
    return isAfterOrSameDate(checkDate, minDate) && isBeforeOrSameDate(checkDate, maxDate);
}