import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import React from 'react';
import { MapToolIcon } from '../../Icons/icons';
import { olMap } from '../../map/map';
import { Control } from '../../map/types';
import './toolbar.scss';

type Props = {
    children: JSX.Element[];
};

const useStyle = makeStyles((theme: Theme) =>
    createStyles({
        tool: {
            backgroundColor: theme.palette.common.white,
            fill: theme.palette.primary.dark,

            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
                fill: theme.palette.common.white,
            },
        },
    })
);

export function MapToolbar(props: Props): JSX.Element {
    return <div className="toolbar">{props.children}</div>;
}

type MapToolProps = {
    label: string;
    control: Control;
    src: string;
    onActivate?: () => void;
    ariaLabel?: string;
};

export function MapTool(props: MapToolProps): JSX.Element {
    const classes = useStyle({});
    const toolClass = classnames(classes.tool, 'tool');
    const clickHandler = (e): void => {
        e.preventDefault();
        props.control.activate(olMap);

        if (props.onActivate) {
            props.onActivate();
        }
    };

    return (
        <button className={toolClass} onClick={clickHandler} aria-label={props.ariaLabel}>
            <span className="maptool-text">{props.label}</span>
            <span className="maptool-icon-wrapper">
                <MapToolIcon type={props.src} />
            </span>
        </button>
    );
}
