 import { Control } from '../types';
import { store } from '../../store';
import { setRightPanelContent } from '../../store/rightpanel/action';
import { RightPanelContent } from '../../constants/rightpanelContent';

const openPanel = (): void => {
    store.dispatch(setRightPanelContent(RightPanelContent.LayerSelector));
};

const closePanel = (): void => {
    store.dispatch(setRightPanelContent(RightPanelContent.None));
};

export const Layers: Control = {
    id: 'layers',
    activate: openPanel,
    deactivate: closePanel,
};
