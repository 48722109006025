import React, {Fragment, ReactElement} from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import {AboutRovbaseData} from "./about";
import {NavLink} from 'react-router-dom';
import {ExternalLink} from "../../components/externalLInk/externalLink";

type AboutNOProps = {
    aboutRovbaseData: AboutRovbaseData
}

export function AboutContentNO(props: AboutNOProps): ReactElement {
    return (
        <>
            <header>
                <h2>Om Rovbase</h2>
                <p className="about-ingress">
                    Her viser vi geografiske registreringer av observasjoner og skader etter rovdyr i 
                    Norge og Sverige. Det er i hovedsak registreringer av de fire store rovdyrene ulv, bjørn, gaupe og jerv. 
                    Rovbase inneholder også registreringer av skader påført av kongeørn og ukjent fredet rovvilt.
                </p>
            </header>
            <p>
                Rovbase er et viktig verktøy for forvaltning av de store rovdyrene. Mer om forvaltningen og hvem som gjør hva 
                kan du lese på <ExternalLink href={"https://www.miljodirektoratet.no"}>Miljødirektoratets</ExternalLink> og <ExternalLink href={"http://www.naturvardsverket.se/"}>Naturvårdsverkets</ExternalLink> nettsteder.
            </p>
            <p>
                En stor del av informasjonen i Rovbase er basert på opplysninger og tips fra lokale kilder og enkeltpersoner, 
                og er et viktig bidrag til kunnskapen om utbredelsen av rovdyrene.
                Rovbase inneholder også informasjon om organiserte registreringer gjennomført av feltpersonell og 
                laboratorieanalyser av prøvefunn. Dette omfatter blant annet:
            </p>
            <ul className="info-list">
                <li>Undersøkelser av husdyr og tamrein, skadet eller drept av rovdyr.</li>
                <li>Spor og sportegn fra rovdyr som er undersøkt og kvalitetssikret</li>
                <li>Ekskrement og hårprøver fra rovdyr, samlet inn av organisert feltpersonell eller forskningsprosjekt</li>
            </ul>
            
            <p>
                I Rovbase er det også tilgjengelig en oversikt over erstatningstall for tapt sau, lam og rein i Norge. Innholdet 
                i Rovbase oppdateres av Statsforvalteren i respektive fylker, Statens naturoppsyn (SNO), og Norsk institutt for 
                naturforskning (NINA), i henhold til Norges nasjonale overvåkingsprogram for rovvilt. Detaljer om det nasjonale 
                overvåkningsprogrammet og rapporter fra overvåkningen finnes på <ExternalLink href={"https://rovdata.no/"}>Rovdata</ExternalLink> sitt nettsted.
            </p>
            <p>
                Naturvårdsverket i Sverige samler inn data om rovdyrene med samme metodikk, via respektive länsstyrelser i Sverige.
                I Sverige er bare skader på rein tilgjengelig. Skader på rein rapporteres og undersøkes i liten utstrekning i Sverige. Det skyldes at erstatning for rovdyrskader på rein baseres på hvor mange rovdyr som er i respektive samebys areal, og ikke på dokumenterte kadaver.
            </p>
            <p>
                Personopplysninger og sensitive opplysninger om artene vises ikke.
            </p>
            
            <h3>Innhold i Rovbase</h3>
            <div className="about-statistics">
                <span>Rovbase kartløsning</span>
                <ul className="statistics-list">
                    {props.aboutRovbaseData && (
                        <Fragment>
                            <li>
                                <NavigateNextIcon />
                                <span>{props.aboutRovbaseData.carnivoreObserved.toLocaleString()}</span>
                                <span>Observasjoner av rovdyr</span>
                            </li>
                            <li>
                                <NavigateNextIcon />
                                <span>{props.aboutRovbaseData.carnivoreDamageSheep.toLocaleString()}</span>
                                <span>Sau skadet av rovdyr</span>
                            </li>
                            <li>
                                <NavigateNextIcon />
                                <span>{props.aboutRovbaseData.carnivoreDamageReindeer.toLocaleString()}</span>
                                <span>Rein skadet av rovdyr</span>
                            </li>
                            <li>
                                <NavigateNextIcon />
                                <span>{props.aboutRovbaseData.carnivoreDead.toLocaleString()}</span>
                                <span>Døde rovdyr</span>
                            </li>
                            <li>
                                <NavigateNextIcon />
                                <span>{props.aboutRovbaseData.dnaSamples.toLocaleString()}</span>
                                <span>DNA-prøver</span>
                            </li>
                        </Fragment>
                    )}
                </ul>
            </div>
            <p>
                <span>Har du opplysninger å bidra med, ta gjerne </span>
                <NavLink to="/kontakt" activeClassName="selected" accessKey="7">
                    kontakt med oss
                </NavLink>
            </p>
        </>
    )
}
