import {ReactPlugin} from '@microsoft/applicationinsights-react-js';
import {ApplicationInsights} from '@microsoft/applicationinsights-web';
import {history} from './components/root/root';

let reactPlugins;
let appInsights;

// @ts-ignore
if (window.confAiEnabled && window.confAiEnabled()) {
    reactPlugins = new ReactPlugin();
    appInsights = new ApplicationInsights({
        config: {
            // @ts-ignore
            connectionString: window.confAiEnabled && window.confAiEnabled() ? window.confAiConnectionString : '',
            loggingLevelConsole: 2,
            extensions: [reactPlugins],
            extensionConfig: {
                [reactPlugins.identifier]: { history },
            },
        },
    });
    appInsights.loadAppInsights();
}

export const reactPlugin = reactPlugins;
export const appInsight = appInsights && appInsights.appInsights;
