import React, {useMemo, useState} from 'react';
import SantaHat from './santahat2.svg';
import './julehilsen.scss';

export function Julehilsen(): JSX.Element {
    const [snowfall, setSnowfall] = useState<boolean>(false);
    const showJulehilsen = useMemo(() => new Date < new Date(2023, 0, 2), []);
    const onJuleklikk = (): void => {
        setSnowfall(!snowfall);
    };
    return (
        showJulehilsen && <>
            <button className="julehilsen" onClick={onJuleklikk} type="button" tabIndex={2}
            >
                <SantaHat/>
                God Jul
            </button>
            {snowfall &&
                <div id="divSnowflakes" className="snowflakes" aria-hidden="true">
                    <div className="snowflake">{'\u2744'}</div>
                    <div className="snowflake">{'\u2744'}</div>
                    <div className="snowflake">{'\u2744'}</div>
                    <div className="snowflake">{'\u2744'}</div>
                    <div className="snowflake">{'\u2744'}</div>
                    <div className="snowflake">{'\u2744'}</div>
                    <div className="snowflake">{'\u2744'}</div>
                    <div className="snowflake">{'\u2744'}</div>
                    <div className="snowflake">{'\u2744'}</div>
                    <div className="snowflake">{'\u2744'}</div>
                    <div className="snowflake">{'\u2744'}</div>
                    <div className="snowflake">{'\u2744'}</div>
                </div>
            }
        </>
    );
}