import {
    CLEAR_LOCATIONLISTELEMENTS,
    ClearLocationAction,
    DESELECT_ALL_IN_LIST,
    Filter,
    REMOVE_FILTERLISTELEMENT,
    SELECT_ALL_IN_LIST,
    SET_SEARCHINPUT,
    SetSearchinputAction,
    ToggleAllInListAction,
    ToggleLocationAction,
    ToggleRovdyrAction,
    UPDATE,
    UPDATE_BOTH_DATES,
    UPDATE_FILTERELEMENT,
    UPDATE_PARTIAL,
    UpdateAction,
    UpdateBothDatesAction,
    UpdateFilterElementAction,
    UpdatePartialAction,
} from './types';
import {ThunkAction, ThunkDispatch} from 'redux-thunk';
import {ApplicationState} from '..';
import {Action, Dispatch} from 'redux';
import {isStedfesting} from '../../model/stedfesting/Stedfesting';
import {Stedfesting} from '../../model/stedfesting/StedfestingTypes';
import {selectStedfesting, setStedfesting} from '../map/actions';
import {setIsLoading} from '../settings/actions';
import queryString from 'query-string';
import {LocationType} from '../../constants/locationType';
import {history} from '../../components/root/root';
import {postSearch} from '../../services/featureService';
import {mapDispatch} from '../../map/map';
import {zoomToFeatures, zoomToFeaturesMobile} from '../../map/mapActions';
import {setTooltip} from '../tooltip/actions';
import {dateToStringForUrl} from '../../helpers';
import {Observations} from '../../constants/observations';
import {isEmpty} from 'ramda';
import {OpenSearchresultlist} from '../sidePanel/action';
import {appInsight} from '../../applicationInsights';
import {Language} from '../../constants/language';

//hjelpefunksjoner
const isInArray = (stedfestings: Stedfesting[], selected: Stedfesting): boolean => {
    return stedfestings.some(a => {
        if (a.id === selected.id) {
            return true;
        } else return false;
    });
};

export function UpdateSearchFilter(searchFilter: Filter): UpdateAction {
    return {
        type: UPDATE,
        searchFilter,
    };
}

export function PartialUpdateSearchFilter(searchFilterPartial: Partial<Filter>): UpdatePartialAction {
    return {
        type: UPDATE_PARTIAL,
        searchFilterPartial,
    };
}

// Carnivore, CarnivoreDamage, Evaluation, Observation
export function ToggleFilterListElement(type: string, filterArrayName: string, id: number): ToggleRovdyrAction {
    return {
        type: type,
        filterArrayName,
        id,
    };
}

// Country, Region, County, Municipality
export function ToggleLocationListElement(actionType: string, filterArrayName: string, stringId: string): ToggleLocationAction {
    return {
        type: actionType,
        filterArrayName,
        stringId,
    };
}

export function UpdateFilterElement(fieldName: keyof Filter, payload: any): UpdateFilterElementAction {
    return {
        type: UPDATE_FILTERELEMENT,
        fieldName,
        payload,
    };
}

export function UpdateBothDates(fromDate: string, toDate: string): UpdateBothDatesAction {
    return {
        type: UPDATE_BOTH_DATES,
        fromDate,
        toDate,
    };
}

export function ClearLocationListelements(): ClearLocationAction {
    return {
        type: CLEAR_LOCATIONLISTELEMENTS,
    };
}

export function SelectAllInList(arrayName: string, array: number[]): ToggleAllInListAction {
    return {
        type: SELECT_ALL_IN_LIST,
        arrayName: arrayName,
        array: array,
        
    };
}

export function DeselectAllInList(arrayname: string): ToggleAllInListAction {
    return {
        type: DESELECT_ALL_IN_LIST,
        arrayName: arrayname,
    };
}

export function SetSearchinput(searchinput: string): SetSearchinputAction {
    return {
        type: SET_SEARCHINPUT,
        searchinput: searchinput,
    };
}

export function createSearch(filter: Filter, languageCode: string): object {
    const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
    });
    const body = {
        LanguageCode: languageCode,
        SearchFilter: filter,
    };
    return {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(body),
    };
}
export const getIndividualSearchUrl = (searchText: string[]): string => {
    return queryString.stringify({ T: searchText }, {arrayFormat: 'comma'});
};

//https://www.npmjs.com/package/query-string
export function UpdateUrl(searchFilter: Filter): void {
    if (searchFilter.IndividualNameOrID.length > 0) {
        history.push({
            pathname: '/individ',
            search: queryString.stringify({ IndividualNameOrID: searchFilter.IndividualNameOrID }),
        });
    } else if (searchFilter.Barcode.length > 0) {
        history.push({
                pathname: '/search',
                search: getIndividualSearchUrl(searchFilter.Barcode.map(str => str.trim())), 
        });
        
    } else if (searchFilter.ID.length > 0) {
            history.push({
                    pathname: '/search',
                    search: getIndividualSearchUrl(searchFilter.ID.map(str => str.trim())),
            });
            
    } else {
        const filterToUrl = {
            Carnivore: searchFilter.Carnivore.length > 0 ? searchFilter.Carnivore : '',
            CarnivoreDamage: searchFilter.CarnivoreDamage.length > 0 ? searchFilter.CarnivoreDamage : '',
            Evaluation: searchFilter.Evaluation.length > 0 ? searchFilter.Evaluation : '',
            ToDate: searchFilter.ToDate,
            FromDate: searchFilter.FromDate,
            Observation: searchFilter.Observation.length > 0 ? searchFilter.Observation : '',
            Offspring: searchFilter.Offspring,
            Country: searchFilter.Country,
            County: searchFilter.County,
            Region: searchFilter.Region,
            Municipality: searchFilter.Municipality,
        };

        history.push({
            pathname: '/filter',
            search: queryString.stringify(filterToUrl, { arrayFormat: 'comma' }),
        });
    }
}

export function searchAction(): ThunkAction<void, ApplicationState, null, Action<string>> {
    return async function(dispatch: Dispatch, getState): Promise<void> {
        const { searchFilter } = getState().searchFilter;
        const { language } = getState().settings;

        UpdateUrl(searchFilter);

        await loadFeaturesFromSearchfilter(dispatch, searchFilter, language);
    };
}

export async function loadFeaturesFromSearchfilter(dispatch: Dispatch, searchFilter: Filter, language: Language): Promise<void> {
    const search = createSearch(searchFilter, language);
    dispatch(setIsLoading(true));
    try {
        const response = await postSearch(search);
        if (response.ok) {
            const result = await response.json();
            const stedfestinger: Stedfesting[] = result.filter(isStedfesting);
            const sortedStedfestinger = stedfestinger.sort((a, b) => a.dato.localeCompare(b.dato)).reverse();
            dispatch(setStedfesting(sortedStedfestinger));
            const windowWidth = window.innerWidth;

            if (windowWidth > 600) {
                mapDispatch(zoomToFeatures());
            } else {
                mapDispatch(zoomToFeaturesMobile());
            }

            if (!isEmpty(searchFilter.ID) || !isEmpty(searchFilter.Barcode) || !isEmpty(searchFilter.IndividualNameOrID)) {
                dispatch(OpenSearchresultlist());

                // Logging til application insights
                appInsight?.trackEvent({name: 'SEARCH_IND_ID_BC', properties: {individual: searchFilter.IndividualNameOrID, id: searchFilter.ID, barcode: searchFilter.Barcode, hits: stedfestinger.length}});
            }

            if (sortedStedfestinger.length === 1) {
                dispatch(selectStedfesting(sortedStedfestinger));
                dispatch(setTooltip(sortedStedfestinger[0]));
            } else {
                dispatch(selectStedfesting([]));
                dispatch(setTooltip(null));
            }
        }
        dispatch(setIsLoading(false));
    } catch (error) {
        if (error.name !== 'AbortError') {
            // eslint-disable-next-line no-console
            console.error(error);
        } else {
            // eslint-disable-next-line no-console
            console.debug('Abort previous search');
        }
    }
}

export function UpdateOffspringElementAndSearch(
    payload: any
): ThunkAction<void, ApplicationState, void, Action<string>> {
    return async function(dispatch: ThunkDispatch<any, any, any>): Promise<any> {
        dispatch(UpdateFilterElement('Offspring', payload));
        dispatch(ToggleFilterListElement(REMOVE_FILTERLISTELEMENT, 'Observation', Observations.AnalysedDna));
        dispatch(ToggleFilterListElement(REMOVE_FILTERLISTELEMENT, 'Observation', Observations.UnanalysedDna));
        dispatch(searchAction());
    };
}


export function UpdateSearchFilterAndSearch(
    searchFilter: Partial<Filter>
): ThunkAction<void, ApplicationState, void, Action<string>> {
    return async function(dispatch: ThunkDispatch<any, any, any>): Promise<any> {
        dispatch(PartialUpdateSearchFilter(searchFilter));
        dispatch(searchAction());
    };
}

export function ToggleFilterListElementOgSok(
    type: string,
    filterArrayName: string,
    id: number
): ThunkAction<void, ApplicationState, null, Action<string>> {
    return async function(dispatch: ThunkDispatch<{}, {}, any>): Promise<any> {
        dispatch(ToggleFilterListElement(type, filterArrayName, id));
        dispatch(searchAction());
    };
}

export function ToggleLocationListElementAndSearch(
    actionType: string,
    locationType: number,
    idString: string
): ThunkAction<void, ApplicationState, null, Action<string>> {
    return async function(dispatch: ThunkDispatch<{}, {}, any>): Promise<any> {
        if (actionType === CLEAR_LOCATIONLISTELEMENTS) {
            dispatch(ClearLocationListelements());
        } else {
            const filterName = LocationType[locationType];
            dispatch(ToggleLocationListElement(actionType, filterName, idString));
        }
        dispatch(searchAction());
    };
}

export function UpdateDateFilter(
    field: keyof Filter,
    date: string
): ThunkAction<void, ApplicationState, null, Action<string>> {
    return async function(dispatch: ThunkDispatch<{}, {}, any>): Promise<any> {
        dispatch(UpdateFilterElement(field, date));
        dispatch(searchAction());
    };
}

export function UpdateBothDatesAndSearch(
    fromDate: string,
    toDate: string,
): ThunkAction<void, ApplicationState, null, Action<string>> {
    return async function(dispatch: ThunkDispatch<{}, {}, any>): Promise<any> {
        dispatch(UpdateBothDates(fromDate, toDate));
        dispatch(searchAction());
    };
}

export function SelectAllAndSearch(
    arrayName: string,
    array: number[]
): ThunkAction<void, ApplicationState, null, Action<string>> {
    return async function(dispatch: ThunkDispatch<{}, {}, any>): Promise<any> {
        dispatch(SelectAllInList(arrayName, array));
        dispatch(searchAction());
    };
}

export function DeSelectAllAndSearch(arrayName: string): ThunkAction<void, ApplicationState, null, Action<string>> {
    return async function(dispatch: ThunkDispatch<{}, {}, any>): Promise<any> {
        dispatch(DeselectAllInList(arrayName));
        dispatch(searchAction());
    };
}
