import React, {ChangeEvent} from 'react';
import {useSelector} from 'react-redux';
import {useLanguage, useLanguageTemplate} from '../../i18n';
import {ApplicationState, useActionCreator} from '../../store';
import './dateFilter.scss';
import './mdInputComponent.scss';
import {DatePreset} from './dateFilter.types';
import {currentDatePreset, dateIsBetween, isValidDate, subtractDays, subtractYears} from './dateFilter.util';
import {dateToISOString, formatDateStringToLocaleString} from '../../helpers';
import {ExpansionField, ExpansionFieldContent, ExpansionFieldTitle} from '../expansionField/expansionField';
import {UpdateBothDatesAndSearch, UpdateDateFilter, UpdateFilterElement} from '../../store/searchfilter/action';
import {PresetButton} from '../themeButtton/themeButton';
import {isAfter, isBefore} from "date-fns";
import {MdInput} from '@miljodirektoratet/md-react';

export default function DateFilter(): JSX.Element {
    const l = useLanguage();
    const lt = useLanguageTemplate();
    const language = useSelector((state: ApplicationState) => state.settings.language);
    const fromDate = useSelector((state: ApplicationState) => state.searchFilter.searchFilter.FromDate);
    const toDate = useSelector((state: ApplicationState) => state.searchFilter.searchFilter.ToDate);
    const minDate = "1980-01-01";
    const maxDate = "2099-12-31";
    const updateBothDates = useActionCreator(UpdateBothDatesAndSearch);
    const updateDate = useActionCreator(UpdateDateFilter);
    const updateFilter = useActionCreator(UpdateFilterElement);
    const ButtonList = [
        { id: DatePreset.LastSevenDays, label: l('dateLabel.lastWeek')},
        { id: DatePreset.LastMonth, label: l('dateLabel.lastMonth')},
        { id: DatePreset.ThisYear, label: l('dateLabel.lastYear')},
    ];
    const today = dateToISOString(new Date());
    
    function setDatePreset(id: DatePreset): void {
        if (id === currentDatePreset(fromDate, toDate, today)) {
            return;
        }
        switch (id) {
            case DatePreset.LastSevenDays:
                updateBothDates(subtractDays(today, 7), today);
                break;
            case DatePreset.LastMonth:
                updateBothDates(subtractDays(today, 30), today);
                break;
            case DatePreset.ThisYear:
                updateBothDates(subtractYears(today, 1), today);
                break;
        }
    }

    function handleToDateChange(event: ChangeEvent<HTMLInputElement>): void {
        const newToDate = event.target.value;
        
        if (!isValidDate(newToDate)) return;

        if (dateIsBetween(newToDate, minDate, maxDate)) {
            updateDate('ToDate', newToDate);
        } else {
            updateFilter('ToDate', newToDate);
        }

    }

    function handleFromDateChange(event: ChangeEvent<HTMLInputElement>): void {
        const newFromDate = event.target.value;
        
        if (!isValidDate(newFromDate)) return;
        
        if (dateIsBetween(newFromDate, minDate, toDate)) {
            updateDate('FromDate', newFromDate);
        } else {
            updateFilter('FromDate', newFromDate);
        }

    }

    const isFromDateInvalid = !isValidDate(fromDate) || (isValidDate(toDate) && !dateIsBetween(fromDate, minDate, toDate));
    const isToDateInvalid = !isValidDate(toDate) || (isValidDate(fromDate) && !dateIsBetween(toDate, fromDate, maxDate));

    function errorTextToDate(): string {
        if (!isValidDate(toDate)){
            return l('dateLabel.invalidDate');
        }
        else if (isBefore(toDate, fromDate)) {
            return l('dateLabel.invalidToDate');
        }
        else if (isAfter(toDate, maxDate)) {
            return lt('dateLabel.dateMustBeBefore', 
            {date: formatDateStringToLocaleString(maxDate, language)});
        }

        // Unknown error
        return l('error.oida');
    }

    function errorTextFromDate(): string {
        if (!isValidDate(fromDate)){
            return l('dateLabel.invalidDate');
        }
        else if (isBefore(fromDate, minDate)) {
            return lt('dateLabel.dateMustBeAfter', 
                {date: formatDateStringToLocaleString(minDate, language)});
        }
        else if (isAfter(fromDate, toDate)) {
           return l('dateLabel.invalidFromDate');
        }
         
        // Unknown error
        return l('error.oida');
    }

    return (
        <div className="date-filter">
            <h3>{l('datePicker.time')}</h3>
            <div className="presets-button-group">
                {ButtonList.map((btn, i) => (
                    <PresetButton
                        key={btn.id}
                        selected={currentDatePreset(fromDate, toDate, today) === btn.id}
                        onClick={() => setDatePreset(btn.id)}
                    >
                        <span>{btn.label}</span>
                    </PresetButton>
                ))}
            </div>

            <ExpansionField>
                <ExpansionFieldTitle label={[l('dateLabel.chooseDate')]} />
                <ExpansionFieldContent>
                    <MdInput
                        value={fromDate}
                        label={l('dateLabel.from')}
                        size="normal"
                        type="date"
                        max={toDate}
                        min={minDate}
                        errorText={errorTextFromDate()}
                        error={isFromDateInvalid}
                        outerWrapperClass={'date-picker-wrapper'}
                        onChange={handleFromDateChange}
                        id="fromDate"
                    />
                    <MdInput
                        value={toDate}
                        label={l('dateLabel.to')}
                        size="normal"
                        type="date"
                        max={maxDate}
                        min={fromDate}
                        errorText={errorTextToDate()}
                        error={isToDateInvalid}
                        outerWrapperClass={'date-picker-wrapper'}
                        onChange={handleToDateChange}
                        id="toDate"
                    />
                </ExpansionFieldContent>
            </ExpansionField>
        </div>
    );
}
