import Feature from 'ol/Feature';
import WKT from 'ol/format/WKT';
import { Datatype, DNA, Rovviltobservasjon, Rovviltskade, Stedfesting, DodeRovdyr } from './StedfestingTypes';
import { curry } from 'ramda';

export function toFeature(stedfesting: Stedfesting): Feature {
    const wkt = new WKT();
    const feature = new Feature({
        geometry: wkt.readGeometry(stedfesting.wkt)
    });

    feature.set('stedfesting', stedfesting);
    return feature;
}

export function fromFeature(feature: Feature): Stedfesting {
    return feature.get('stedfesting');
}

export const toLookupVal = (s: Stedfesting): string => `${s.id}-${s.datatype}`;

export const isSame = curry((s1: Stedfesting, s2: Stedfesting) => s1.id === s2.id && s1.datatype === s2.datatype);

export function isStedfesting(obj: any): obj is Stedfesting {
    return obj && obj.id && validDatatype(obj.datatype || '');
}

export function isDNA(stedfesting: any): stedfesting is DNA {
    return isStedfesting(stedfesting) && stedfesting.datatype === Datatype.DNA;
}

export function isRovviltskade(stedfesting: any): stedfesting is Rovviltskade {
    return isStedfesting(stedfesting) && stedfesting.datatype === Datatype.Rovviltskade;
}

export function isRovviltobservasjon(stedfesting: any): stedfesting is Rovviltobservasjon {
    return isStedfesting(stedfesting) && stedfesting.datatype === Datatype.Rovviltobservasjon;
}

export function isDodeRovdyr(stedfesting: any): stedfesting is DodeRovdyr {
    return isStedfesting(stedfesting) && stedfesting.datatype === Datatype.DodeRovdyr;
}

export function validDatatype(datatype: string): datatype is Datatype {
    return Object.values(Datatype).includes(datatype as Datatype);
}
