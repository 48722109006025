import { Action } from 'redux';
import { RightPanelContent } from '../../constants/rightpanelContent';

export type RightPanelContentAction = Action & { component: RightPanelContent };

export type RightPanelState = {
    component: RightPanelContent;
};

export const SET_RIGHTPANEL_CONTENT = '@rightpanel/SET_RIGHTPANEL_CONTENT';
