import { LocationType } from '../constants/locationType';
import {Country} from '../constants/country';
import {Area} from '../model/area/area';
import {ReindeerLocationType} from '../constants/reindeerLocationType';
import {ReindeerLocation} from '../model/area/reindeerLocation';

export type AreaFilter = {
    Country: string[];
    Region: string[];
    County: string[];
    Municipality: string[];
};

export type AreaSearchResult = {
    label: string,
    options: any[]
}

export default {
    getAllAreas(input: string) {
        const headers = new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
        });
        
        const req: RequestInit = {
            method: 'GET',
            headers: headers,
        };
        
        return fetch(`/api/areaSearch?searchString=${input}`, req)
            .then(response => response.json())
            .then((res: Area[]) => {
                return [
                    { label: 'Land', options: res.filter(area => area.locationType === LocationType.Country) },
                    
                    { label: 'Regioner i Norge', options: res.filter(area => area.locationType === LocationType.Region && area.countryID === Country.Norway) },
                    { label: 'Fylker i Norge', options: res.filter(area => area.locationType === LocationType.County && area.countryID === Country.Norway) },
                    { label: 'Kommuner i Norge', options: res.filter(area => area.locationType === LocationType.Municipality && area.countryID === Country.Norway) },
                    
                    { label: 'Regioner i Sverige', options: res.filter(area => area.locationType === LocationType.Region && area.countryID === Country.Sweden)},
                    { label: 'Län i Sverige', options: res.filter(area => area.locationType === LocationType.County && area.countryID === Country.Sweden) },
                    { label: 'Kommuner i Sverige', options: res.filter(area => area.locationType === LocationType.Municipality && area.countryID === Country.Sweden) },

                    { label: 'Maakunnat Suomessa', options: res.filter(area => area.locationType === LocationType.County && area.countryID === Country.Finland) },
                    { label: 'Kunnat Suomessa', options: res.filter(area => area.locationType === LocationType.Municipality && area.countryID === Country.Finland) },
                ];
            });
    },

    getNorwayAreas(input: string) {
        const headers = new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
        });

        const req: RequestInit = {
            method: 'GET',
            headers: headers,
        };
        return fetch(`/api/areaSearch?searchString=${input}`, req)
            .then(response => response.json())
            .then((res: Area[]) => {
                // filtrer bort det som ikke har countryID Norge
                const norwegianAreas = res.filter(area => area.countryID === Country.Norway);
                return [
                    { label: 'Land', options: norwegianAreas.filter(area => area.locationType === LocationType.Country) },
                    { label: 'Region', options: norwegianAreas.filter(area => area.locationType === LocationType.Region) },
                    { label: 'Fylke', options: norwegianAreas.filter(area => area.locationType === LocationType.County) },
                    { label: 'Kommune', options: norwegianAreas.filter(area => area.locationType === LocationType.Municipality) },
                ];
            });
    },
    getReindeerAreas(input: string) {
        const headers = new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
        });

        const req: RequestInit = {
            method: 'GET',
            headers: headers,
        };
        return fetch(`/api/reindeerAreaSearch?searchString=${input}`, req)
            .then(response => response.json())
            .then((res: ReindeerLocation[]) => {
                return [
                    { label: 'Land', options: res.filter(area => area.locationType === ReindeerLocationType.Country) },
                    { label: 'Reinbeiteomrade', options: res.filter(area => area.locationType === ReindeerLocationType.Reinbeiteomrade),},
                    { label: 'Reinbeitedistrikt', options: res.filter(area => area.locationType === ReindeerLocationType.Reinbeitedistrikt),
                    },
                ];
            });
    },

    getAreasFromID(areafilter: AreaFilter, languageCode: string): Promise<Area[]> {
        const headers = new Headers({
            Accept: 'application/json',
            'Content-Type': 'application/json',
        });

        const body = {
            LanguageCode: languageCode,
            AreaFilter: areafilter,
        };

        const areaSearch = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body),
        };

        return fetch('api/areatextsearch', { ...areaSearch }).then(response => response.json());
    },
};
