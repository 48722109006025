import { AnyAction } from 'redux';
import {OPEN, CLOSE, SET_INDIVID, REMOVE_INDIVID} from './types';
import {IndividualData} from "../../components/searchresultList/searchresultTitle";

export function OpenSearchresultlist(): AnyAction {
    return {
        type: OPEN,
    };
}

export function CloseSearchresultlist(): AnyAction {
    return {
        type: CLOSE,
    };
}


export function SetIndivid(individ: IndividualData): AnyAction {
    return {
        value: individ,
        type: SET_INDIVID
    }
}


export function RemoveIndivid(): AnyAction {
    return {
        type: REMOVE_INDIVID
    }
}
