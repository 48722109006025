import {makeStyles, Theme} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {createStyles} from '@material-ui/styles';
import classnames from 'classnames';
import {equals} from 'ramda';
import findIndex from 'ramda/es/findIndex';
import React, {ReactElement, useRef, Fragment} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {Language} from '../../constants/language';
import {useLanguage, useLanguageTemplate} from '../../i18n';
import {ApplicationState, useActionCreator} from '../../store';
import {selectStedfesting} from '../../store/map/actions';
import {setTooltip} from '../../store/tooltip/actions';
import {Share} from '../share/share';
import {IconButton, InlineLinkButton, PrimaryButton, SecondaryButton} from '../themeButtton/themeButton';
import './tooltip.scss';
import {
    formatIndividString,
    getStedfestingUrl, getStringFromEnum, notNullOrEmpty,
    stedfestingTitle,
} from './tooltip.util';
import {ErrorBoundary} from '../errorBoundary/errorBoundary';
import {SetSearchinput, UpdateSearchFilterAndSearch} from '../../store/searchfilter/action';
import {Individ} from '../../model/stedfesting/StedfestingTypes';
import {Carnivore} from '../../constants/carnivore';
import {TooltipDescription} from './tooltipDescription';
import {StedfestingInfo} from './stedfestingInfo';
import {TooltipHeaderInfo} from './tooltipHeaderInfo';
import {clusterSource} from '../../map/map';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tooltip: {
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.grey[300]}`,
        },
        titleBanner: {
            backgroundColor: theme.palette.secondary.main,
        },
        borderTop: {
            borderTop: `1px solid ${theme.palette.grey[200]}`,
        }
    })
);

function TooltipTitle(): ReactElement {
    const stedfesting = useSelector((state: ApplicationState) => state.tooltip.stedfesting);
    const language: Language = useSelector((state: ApplicationState) => state.settings.language, shallowEqual);
    const selectedStedfestings = useSelector((state: ApplicationState) => state.map.selectedStedfesting);
    const individSearchText = useSelector((state: ApplicationState) => state.searchFilter.searchFilter.IndividualNameOrID);
    const setTooltipStedfesting = useActionCreator(setTooltip);
    const setSelectedStedfestings = useActionCreator(selectStedfesting);
    const individ = useSelector((state: ApplicationState) => state.sidePanelFilter.individ);
    const clusterSize = selectedStedfestings.length;
    const index = findIndex(equals(stedfesting))(selectedStedfestings);
    const l = useLanguage();
    const classes = useStyles({});
    const titleClass = classnames(classes.titleBanner, 'title-banner');
    const individSearch = individSearchText.length > 0;
    const cluster = clusterSize > 1;
    const clusterOrIndividual = cluster || individSearch;
    const theCarnivoreString = getStringFromEnum(Carnivore, individ?.artsID);
    
    const individualCarnivoreType = individ ?
        `${l(`theCarnivore.${theCarnivoreString}`)}`
        : l('tooltip.individual').toLowerCase();
    const individTitleText = individ && `${individualCarnivoreType} ${individ.individID} ${individ.individnavn}`;
    
    const handleCloseTooltip = () => {
        setTooltipStedfesting(null);
        setSelectedStedfestings([]);
        clusterSource.changed();
    };

    return (
        <div className={titleClass}>
            <div className={'titles'}>
            {cluster && <div className="title cluster">{`${index + 1} ${l('tooltip.of')} ${clusterSize}`}</div>}
            {individSearch && <div className="title individ">{individTitleText}</div>}
            {!clusterOrIndividual && <div className="title">{stedfestingTitle(language, stedfesting)}</div>}
            </div>
            <IconButton
                className={'close-button'}
                onClick={handleCloseTooltip}
                aria-label={l('uu.close')}
            >
                <CloseIcon />
            </IconButton>
        </div>
    );
}

type BarcodeLinkProps = {
    barcode: string;
    text: string;
}

export function BarcodeLink(props: BarcodeLinkProps): ReactElement {
    const setSearchParameter = useActionCreator(UpdateSearchFilterAndSearch);
    const setSearchinput = useActionCreator(SetSearchinput);

    const handleBarcodeSearch = (barcode: string): void => {
        setSearchParameter({
            IndividualNameOrID: '',
            ID: [],
            Barcode: [barcode],
        });
        setSearchinput(barcode);
    };

    return (
        <div className="barcode-link">
            {props.text}
            <InlineLinkButton onClick={() => handleBarcodeSearch(props.barcode)}>
                {props.barcode}
            </InlineLinkButton>
        </div>
    );
}

type IndividLinkProps = {
    individ: Individ[],
    text: string;
    postText?: string
}

export function IndividLink(props: IndividLinkProps): ReactElement {
    const setSearchParameter = useActionCreator(UpdateSearchFilterAndSearch);
    const setSearchinput = useActionCreator(SetSearchinput);

    const handleIndividSearch = (individ: Individ): void => {
        setSearchParameter({
            IndividualNameOrID: individ.individID,
            ID: [],
            Barcode: [],
        });
        setSearchinput(`${individ.individID} ${individ.individnavn}`);
    };
    
    return (
        <>
            {props.individ.length > 0 && (
                <div className="individ-link">
                    {props.text}
                    {props.individ.map((individ, i) => (
                        <Fragment key={individ.individID}>
                            {i > 0 && <span>{', '}</span>}
                            <InlineLinkButton onClick={() => handleIndividSearch(individ)}>
                                {individ.individnavn.length > 0
                                    ? formatIndividString(individ)
                                    : individ.individID}
                            </InlineLinkButton>
                        </Fragment>
                    ))}
                    {props.postText}
                </div>
            )}
        </>
    );
}


function TooltipHeader(): ReactElement {
    const stedfesting = useSelector((state: ApplicationState) => state.tooltip.stedfesting);
    const language: Language = useSelector((state: ApplicationState) => state.settings.language, shallowEqual);
    const isIndividSearch = notNullOrEmpty(useSelector((state: ApplicationState) => state.searchFilter.searchFilter.IndividualNameOrID));
    const isCluster = useSelector((state: ApplicationState) => state.map.selectedStedfesting).length > 1;
    const clusterOrIndividual = isCluster || isIndividSearch;
    return (
        <>
            {clusterOrIndividual && (
                <div className="extra-title">{stedfestingTitle(language, stedfesting)}</div>
            )}
            <TooltipHeaderInfo stedfesting={stedfesting}/>
        </>
    );
}

function NavigateTooltip(): ReactElement {
    const setTooltipStedfesting = useActionCreator(setTooltip);
    const selectedStedfestings = useSelector((state: ApplicationState) => state.map.selectedStedfesting);
    const stedfesting = useSelector((state: ApplicationState) => state.tooltip.stedfesting);
    const l = useLanguage();

    const index = findIndex(equals(stedfesting))(selectedStedfestings);
    const clusterSize = selectedStedfestings.length;

    function handlePrevious(): void {
        setTooltipStedfesting(selectedStedfestings[index - 1]);
    }

    function handleNext(): void {
        setTooltipStedfesting(selectedStedfestings[index + 1]);
    }

    return (
        <div className="navigate-tooltip">
            <SecondaryButton onClick={handlePrevious} disabled={index < 1}>
                {l('tooltip.previousPost')}
            </SecondaryButton>
            <div className="counter">{`${index + 1} ${l('tooltip.of')} ${clusterSize}`}</div>
            <PrimaryButton onClick={handleNext} disabled={index + 1 >= clusterSize}>
                {l('tooltip.nextPost')}
            </PrimaryButton>
        </div>
    );

}

export function Tooltip(): JSX.Element {
    const tooltip = useRef();
    const stedfesting = useSelector((state: ApplicationState) => state.tooltip.stedfesting);
    const language: Language = useSelector((state: ApplicationState) => state.settings.language, shallowEqual);
    const clusterSize = useSelector((state: ApplicationState) => state.map.selectedStedfesting).length;
    const lt = useLanguageTemplate();
    const classes = useStyles({});
    const tooltipClass = classnames(classes.tooltip, 'tooltip');
    const cluster = clusterSize > 1;

    const mailSubject = stedfestingTitle(language, stedfesting);
    const mailLink = `mailto:?subject=Rovbase: ${mailSubject}&body=${lt('tooltip.mailBody', {
        title: mailSubject,
    })} ${getStedfestingUrl(stedfesting)}`;

    return (
        <div className={tooltipClass} ref={tooltip}>
            <ErrorBoundary>
                <TooltipTitle />
                <TooltipHeader />
                <div className={classes.borderTop}>
                    <TooltipDescription/>
                    <StedfestingInfo />
                    <Share
                        copyUrl={getStedfestingUrl(stedfesting)}
                        mailLink={mailLink}
                        feedbackObject={stedfesting}
                    />
                    {cluster && <NavigateTooltip />}
                </div>
            </ErrorBoundary>
        </div>
    );
}
