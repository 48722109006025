import {last, path} from 'ramda';
import { useDispatch } from 'react-redux';
import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import {composeWithDevTools} from '@redux-devtools/extension';
import thunk from 'redux-thunk';
import { Observable } from 'rxjs';
import { map, pairwise, startWith } from 'rxjs/operators';
import { mapReducer } from './map/reducer';
import { initCookieStore } from './map/sideEffects';
import { MapState } from './map/types';
import { menuReducer } from './menu/reducer';
import { MenuState } from './menu/types';
import { RefundReducer } from './refund/reducer';
import { RefundDataState } from './refund/types';
import { RightPanelReducer } from './rightpanel/reducer';
import { RightPanelState } from './rightpanel/types';
import { searchFilterReducer } from './searchfilter/reducer';
import { SearchFilterState } from './searchfilter/types';
import { settingsReducer } from './settings/reducer';
import { SettingsState } from './settings/types';
import { sidePanelReducer } from './sidePanel/reducer';
import { SidePanelFilterState } from './sidePanel/types';
import { tooltipReducer } from './tooltip/reducer';
import { TooltipState } from './tooltip/types';

export type ApplicationState = {
    searchFilter: SearchFilterState;
    settings: SettingsState;
    map: MapState;
    rightPanel: RightPanelState;
    sidePanelFilter: SidePanelFilterState;
    menu: MenuState;
    tooltip: TooltipState;
    refund: RefundDataState;
};

const reducers = combineReducers<ApplicationState>({
    searchFilter: searchFilterReducer,
    settings: settingsReducer,
    map: mapReducer,
    rightPanel: RightPanelReducer,
    sidePanelFilter: sidePanelReducer,
    menu: menuReducer,
    tooltip: tooltipReducer,
    refund: RefundReducer,
});

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk)));
initCookieStore();

export function useActionCreator<T>(action: T): T {
    const dispatch = useDispatch();
    // @ts-ignore
    return (...args) => dispatch(action(...args));
}

//https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
export function observe(store: Store): Observable<ApplicationState> {
    return new Observable(observer => {
        observer.next(store.getState());
        return store.subscribe(() => observer.next(store.getState()));
    });
}

export function observeProp<T>(store: Store, propPath: string[]): Observable<T> {
    return observe(store).pipe(
        startWith(null as ApplicationState),
        map(path<T>(propPath)),
        pairwise(),
        map(last)
    ) as Observable<T>;
}

