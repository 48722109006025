import WKT from 'ol/format/WKT';
import Point from 'ol/geom/Point';
import {get as getProjection, transform} from 'ol/proj';
import {MouseEvent} from 'react';
import {Carnivore} from '../../constants/carnivore';
import {CarnivoreDamage} from '../../constants/carnivoreDamage';
import {CarnivoreObserved} from '../../constants/carnivoreObserved';
import {Evaluation} from '../../constants/evaluation';
import {Language} from '../../constants/language';
import {Observations} from '../../constants/observations';
import {SampleStatus} from '../../constants/sampleStatus';
import L from '../../i18n';
import {
    Datatype,
    DNA,
    DodeRovdyr,
    Individ,
    Rovviltobservasjon,
    Rovviltskade,
    Stedfesting,
} from '../../model/stedfesting/StedfestingTypes';
import {getIndividualSearchUrl} from '../../store/searchfilter/action';
import {Site} from '../../store/settings/types';
import './tooltip.scss';
import curry from 'ramda/es/curry';
import {join} from 'ramda';
import {MapProjection} from '../../constants/mapProjection';

export const formatIndividString = (individ: Individ): string => {
    return `${individ.individID} ${individ.individnavn}`;
};


export const getCoordinates = (wktString: string, site: Site): number[] => {
    const wkt = new WKT();
    const geom: Point = wkt.readGeometry(wktString) as Point;
    const coordinates = geom.getCoordinates();

    if (site === Site.SE) {
        return transform(coordinates, getProjection(MapProjection.EPSG_32633), getProjection(MapProjection.EPSG_3006));
    }
    return coordinates;
};

export const getStedfestingUrl = (stedfesting: Stedfesting): string => {
    const { origin } = location;
    const search = getIndividualSearchUrl([stedfesting.id]);
    return `${origin}/search?${search}`;
};

export const getUrl = (): string => {
    const { href } = location;
    return href;
};

export const copyStedfestingUrlToClipboard = curry(
    (stedfesting: Stedfesting, e: MouseEvent<HTMLButtonElement>): void => {
        e.preventDefault();
        navigator.clipboard.writeText(getStedfestingUrl(stedfesting));
    }
);

export const copyUrlToClipboard = (e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    navigator.clipboard.writeText(getUrl());
};

export const getCoordinateType = (site: Site): string => {
    return site === Site.SE ? 'SWEREF99' : 'UTM33';
};

export function camelCase(str: string): string {
    return str === undefined
        ? ''
        : str
              .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
                  return index === 0 ? word.toLowerCase() : word.toUpperCase();
              })
              .replace(/\s+/g, '');
}

export function getStringFromEnum(enumType: any, id: string): string {
    return camelCase(enumType[parseInt(id)]);
}

export function getStringFromEnumNumber(enumType: any, id: number): string {
    return camelCase(enumType[id]);
}

export const isNullOrEmpty = (str) => !str || str === '';
export const notNullOrEmpty = (str) => !isNullOrEmpty(str);

export const kommaseparert = join(", ");

function rovviltskadeHeader(language: Language, stedfesting: Rovviltskade): string {
    const l = L(language);
    const damageType = l(`carnivoreDamage.${getStringFromEnum(CarnivoreDamage, stedfesting.skadetypeID)}`);
    const perpetrator = l(`carnivore.${getStringFromEnum(Carnivore, stedfesting.skadeårsakID)}`).toLowerCase();
    const descriptionText = l('searchresultItem.descriptionText').toLowerCase();
    if (language === Language.SV) {
        return `Skada på ${damageType.toLowerCase()} orsakad av ${perpetrator}`;
    }
    return `${damageType} ${descriptionText} ${perpetrator} `;
}
function dnaHeader(language: Language, stedfesting: DNA): string {
    const l = L(language);
    if (parseInt(stedfesting.prøvestatusID) === SampleStatus.Functional) {
        const art = l(
            `carnivoreObserved.${getStringFromEnum(CarnivoreObserved, stedfesting.artsIDAnalyse)}`
        ).toLowerCase();
        return `DNA, ${art} `;
    } else {
        return 'DNA ';
    }
}

function rovviltobservasjonHeader(language: Language, stedfesting: Rovviltobservasjon): string {
    const l = L(language);
    if (parseInt(stedfesting.vurderingID) === Evaluation.ErroneousReport) {
        return `${l('evaluation.label.erroneousReport')} ${l(
            `carnivore.${getStringFromEnum(Carnivore, stedfesting.artsID)}`
        ).toLowerCase()} `;
    }
    const observation =
        stedfesting.observasjoner.length === 1
            ? getStringFromEnumNumber(Observations, stedfesting.observasjoner[0])
            : 'observation';
    const perpetrator = getStringFromEnum(Carnivore, stedfesting.artsID);
    return `${l(`observations.${observation}`)}, ${l(`carnivore.${perpetrator}`).toLowerCase()}`;
}

function dodeRovdyrHeader(language: Language, stedfesting: DodeRovdyr): string {
    const l = L(language);
    const perpetrator = getStringFromEnum(Carnivore, stedfesting.artsID);
    const intetkjonnPhrase = stedfesting.artsID === Carnivore.Lynx.toString() ? l('deadCarnivore.dead.intetkjonn') : l('deadCarnivore.dead')
    return `${intetkjonnPhrase} ${l(`carnivore.${perpetrator}`).toLowerCase()}`;
}

export function stedfestingTitle(language: Language, stedfesting: Stedfesting): string {
    switch (stedfesting.datatype) {
        case Datatype.DNA: {
            return dnaHeader(language, stedfesting as DNA);
        }
        case Datatype.DodeRovdyr: {
            return dodeRovdyrHeader(language, stedfesting as DodeRovdyr);
        }
        case Datatype.Rovviltobservasjon: {
            return rovviltobservasjonHeader(language, stedfesting as Rovviltobservasjon);
        }
        case Datatype.Rovviltskade: {
            return rovviltskadeHeader(language, stedfesting as Rovviltskade);
        }
    }
    return '';
}

