import React, {useState} from 'react';
import {useSelector} from "react-redux";
import {AboutContentSE} from "./about-se";
import {AboutContentNO} from "./about-no";
import {AboutContentEN} from "./about-en";
import {Language} from '../../constants/language';
import {HoppTilHovedinnhold} from '../../components/themeButtton/themeButton';
import {ErrorBoundary} from '../../components/errorBoundary/errorBoundary';
import StaticPageFrame from '../../components/staticPageFrame/staticPageFrame';
import {ApplicationState} from '../../store';
import './about.scss';

export type AboutRovbaseData = {
    carnivoreDamageSheep: number;
    carnivoreDamageReindeer: number;
    carnivoreObserved: number;
    carnivoreDead: number;
    dnaSamples: number;
    compensationApplicationsSheep: number | '';
    compensationApplicationsReindeer: number;
};

function createRequest(): object {
    let headers = new Headers({
        Accept: 'application/json',
    });
    return {
        method: 'POST',
        headers: headers,
    };
}

export function About(): JSX.Element {
    const [aboutRovbaseData, setAboutRovbaseData] = useState<AboutRovbaseData>();
    const lang = useSelector((state:ApplicationState) => state.settings.language);
    React.useEffect(() => {
        fetch('/api/aboutRovbase', createRequest())
            .then(response => response.json())
            .then(res => setAboutRovbaseData(res.omRovbaseData));
    }, []);
    return (
        <ErrorBoundary>
            <StaticPageFrame>
                <HoppTilHovedinnhold mainContentId={"mainContent"} />
                <div className="about" id={"mainContent"}>
                    {lang === Language.SV && <AboutContentSE/>}
                    {lang === Language.NO && <AboutContentNO aboutRovbaseData={aboutRovbaseData} />}
                    {lang === Language.EN && <AboutContentEN aboutRovbaseData={aboutRovbaseData} />}
                </div>
            </StaticPageFrame>
        </ErrorBoundary>
    );
}
