import { Layer } from '../model/layer/layer';
import { Stedfesting } from '../model/stedfesting/StedfestingTypes';
import { MapAction } from './types';

export enum MapActionType {
    ADD_FEATURES = 'ADD_FEATURES',
    CLEAR_FEATURES = 'CLEAR_FEATURES',
    ADD_LAYERS = 'ADD_LAYERS',
    TOGGLE_LAYER = 'TOGGLE_LAYER',
    ZOOM_TO_FEATURE = 'ZOOM_TO_FEATURE',
    ZOOM_TO_FEATURE_MOBILE = 'ZOOM_TO_FEATURE_MOBILE',
    ZOOM_TO_FEATURES = 'ZOOM_TO_FEATURES',
    ZOOM_TO_FEATURES_MOBILE = 'ZOOM_TO_FEATURES_MOBILE',
}

export function toggleMapLayers(layer: string[]): MapAction<string[]> {
    return {
        type: MapActionType.TOGGLE_LAYER,
        value: layer,
    };
}

export function addLayersToMap(layers: Layer[]): MapAction<Layer[]> {
    return {
        type: MapActionType.ADD_LAYERS,
        value: layers,
    };
}

export function addFeatures(features: Stedfesting[]): MapAction<Stedfesting[]> {
    return {
        type: MapActionType.ADD_FEATURES,
        value: features,
    };
}

export function clearFeatures(): MapAction<void> {
    return {
        type: MapActionType.CLEAR_FEATURES,
        value: undefined,
    };
}

export function zoomToFeature(id: string): MapAction<string> {
    return {
        type: MapActionType.ZOOM_TO_FEATURE,
        value: id,
    };
}

export function zoomToFeatureMobile(id: string): MapAction<string> {
    return {
        type: MapActionType.ZOOM_TO_FEATURE_MOBILE,
        value: id,
    };
}

export function zoomToFeatures(): MapAction<void> {
    return {
        type: MapActionType.ZOOM_TO_FEATURES,
        value: undefined,
    };
}

export function zoomToFeaturesMobile(): MapAction<void> {
    return {
        type: MapActionType.ZOOM_TO_FEATURES_MOBILE,
        value: undefined,
    };
}
