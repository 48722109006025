import React, {ReactElement} from 'react';
import {ExternalLink} from '../../components/externalLInk/externalLink';
import {useLanguage} from '../../i18n';
import {useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {Language} from '../../constants/language';

const svenskURL = 'https://www.naturvardsverket.se/om-oss/om-webbplatsen/tillganglighet/tillganglighet-for-rovbase.se/';

export function AccesibilityContentSE(): ReactElement {
    const l = useLanguage();
    const language = useSelector((state: ApplicationState) => state.settings.language);
    return (
        <div className="accessibility-content">
            <header>
                <h2>{l('accessibility.contentSETitle')}</h2>
            </header>
            {language === Language.SV && <p>Läs på <ExternalLink href={svenskURL}>Naturvårdsverkets hemsida</ExternalLink> om hur Rovbase.se uppfyller den svenska lagen om tillgänglighet till digital offentlig service.</p>}
            {language === Language.NO && <p>Les på <ExternalLink href={svenskURL}>Naturvårdsverkets hjemmeside</ExternalLink> (kun på svensk) hvordan rovbase.se oppfyller de svenske kravene til universell utforming i IKT-løsninger.</p>}
            {language === Language.EN && <p>On <ExternalLink href={svenskURL}>The Swedish Environmental Protection Agency&apos;s webpage</ExternalLink> (in swedish only), you can read about how rovbase.se complies with the Swedish law on web accessibility.</p>}
        </div>
    );
}