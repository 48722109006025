import Map from 'ol/Map';
import { Control } from '../types';

function zoomIn (map: Map): void {
    const zoom = map.getView().getZoom();
    map.getView().setZoom(zoom + 1);
}

function zoomOut (map: Map): void {
    const zoom = map.getView().getZoom();
    map.getView().setZoom(zoom - 1);
}

export const ZoomIn: Control = {
    id: 'zoomin',
    activate: zoomIn
};

export const ZoomOut: Control = {
    id: 'zoomout',
    activate: zoomOut
};