import {alpha} from '@material-ui/core/styles';
import Feature from 'ol/Feature';
import Circle from 'ol/style/Circle';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import {defaultTo, either, isEmpty, isNil, pipe} from 'ramda';
import 'whatwg-fetch';
import {Carnivore} from '../constants/carnivore';
import {CarnivoreDamage} from '../constants/carnivoreDamage';
import {Observations} from '../constants/observations';
import {
    fromFeature,
    isDNA,
    isDodeRovdyr,
    isRovviltobservasjon,
    isRovviltskade,
    isStedfesting,
    toLookupVal,
} from '../model/stedfesting/Stedfesting';
import {Rovviltobservasjon, Rovviltskade} from '../model/stedfesting/StedfestingTypes';
import {store} from '../store';
import {Site} from '../store/settings/types';
import theme from '../styles/materialTheme.js';
import themeSE from '../styles/materialThemeSvensk.js';
import {Evaluation} from '../constants/evaluation';
import {DamageCause} from '../constants/damageCause';
import {CarnivoreObserved} from '../constants/carnivoreObserved';

// Root til svg-mappen
const ICON_DIR_ROOT = '/assets/svg';

const getSvgStyleUrl = createSvgDataUrlLoader();

// Standard-stil hvis stil er null
export const fallbackStyle = new Style({
    image: new Circle({
        fill: new Fill({
            color: '#000',
        }),
        radius: 12,
    }),
    text: new Text({
        text: '?',
        fill: new Fill({
            color: '#FFF',
        }),
    }),
});

// Hjelpefunksjoner
const isInvalid = (str: string): boolean => either(isNil, isEmpty)(str);
const isCluster = (feature: Feature): boolean => feature && feature.get('features');

const clusterText = (clustersize: number): Text => {
    return new Text({
        text: `${clustersize}`,
        scale: clustersize >= 50 ? 1.7 : 1.5,
        fill: new Fill({
            color: '#FFF',
        }),
        offsetX: 0,
        offsetY: 1,
    });
};

/**
 * Henter filsti for rovviltskade-ikon
 * @param stedfesting
 */
export const getIconPathRovviltskade = (stedfesting: Rovviltskade): string | null => {
    const damageType = CarnivoreDamage[stedfesting.skadetypeID];
    if (isInvalid(damageType)) {
        return null;
    }
    const dir = 'rovviltskade';
    return `${ICON_DIR_ROOT}/${dir}/${damageType}.svg`;
};

/**
 * Henter filsti for dna-ikon
 */
export const getIconPathDNA = (): string => {
    const dir = 'dna';
    return `${ICON_DIR_ROOT}/${dir}/dna.svg`;
};

/**
 * Henter filsti for uanalysert dna-ikon
 */
export const getUnanalysedIconPathDNA = (): string | null => {
    const dir = 'dna';
    return `${ICON_DIR_ROOT}/${dir}/unanalysedDNA.svg`;
};

/**
 * Henter filsti for rovviltobservasjon-ikon
 *
 * @param perpetratorTypeId
 * @param observations
 */
export const getIconPathRovviltobservasjon = (stedfesting: Rovviltobservasjon): string | null => {
    const observations = stedfesting.observasjoner;
    const dir = 'observasjonstyper';
    if (parseInt(stedfesting.vurderingID) === Evaluation.ErroneousReport) {
        return `${ICON_DIR_ROOT}/${dir}/ErroneousReport.svg`;
    }
    if (observations.length > 1 || observations.length === 0) {
        const observationType = 'Observation';
        return `${ICON_DIR_ROOT}/${dir}/${observationType}.svg`;
    }
    const observationType = Observations[observations[0]];
    return `${ICON_DIR_ROOT}/${dir}/${observationType}.svg`;
};

/**
 * Henter filsti for DødeRovdyr-ikon
 *
 * @param perpetratorTypeId
 * @param observations
 */
export const getIconPathDodeRovdyr = (): string | null => {
    const dir = 'deadcarnivores';
    return `${ICON_DIR_ROOT}/${dir}/dod.svg`;
};

/**
 * Oppretter stil for feature
 * @param feature
 *
 */
function getIconStyle(feature: Feature, selected: boolean): Style[] {
    const iconPath = getIconPath(feature);
    const selectedIconStroke =
        store.getState().settings.site === Site.SE ? themeSE.palette.primary.light : theme.palette.primary.light;
    const strokeSize = 10;
    
    if (selected) {
        return [
            new Style({
                image: new Icon({
                    src: getSvgStyleUrl(iconPath, feature, false),
                    scale: 0.6,
                }),
                zIndex: 10,
            }),
            new Style({
                image: new Circle({
                    stroke: new Stroke({
                        color: selectedIconStroke,
                        width: strokeSize,
                    }),
                    radius: 16,
                }),
                zIndex: 1,
            }),
        ];
    }
    return [
        new Style({
            image: new Icon({
                src: getSvgStyleUrl(iconPath, feature, false),
                scale: 0.6,
            }),
        }),
    ];
}

export function getIconPath(feature: Feature): string {
    const stedfesting = fromFeature(feature);
    if (isRovviltobservasjon(stedfesting)) {
        return getIconPathRovviltobservasjon(stedfesting);
    }
    if (isRovviltskade(stedfesting)) {
        return getIconPathRovviltskade(stedfesting);
    }
    if (isDNA(stedfesting)) {
        if (stedfesting.prøvestatusID.length > 0) {
            return getIconPathDNA();
        }
        return getUnanalysedIconPathDNA();
    }
    if (isDodeRovdyr(stedfesting)) {
        return getIconPathDodeRovdyr();
    }
    return null;
}

/**
 * Oppretter stil for en stedfesting
 * @param stedfesting
 * @public
 */
export function getStyle(feature: Feature): Style[] {
    const defaultIfNil = defaultTo(fallbackStyle);
    const selected = store.getState().map.selectedStedfesting;
    const hovered = store.getState().map.hoveredStedfesting;
    const array = hovered != null ? [...selected, hovered] : selected;
    const marked = new Map(array.map(s => [toLookupVal(s), s]));

    // Cluster
    if (isCluster(feature)) {
        const cFeatures = feature.get('features').map(pipe(fromFeature, toLookupVal));
        if (cFeatures.length > 1) {
            if (cFeatures.some(v => marked.has(v))) {
                return getSelectedClusterStyle(feature);
            }
            return getClusterStyle(feature);
        }
    }

    // Stedfesting
    let style;
    if (feature.get('features') !== undefined) {
        const [f] = feature.get('features');
        const stedfesting = fromFeature(f);

        if (isStedfesting(stedfesting)) {
            style = marked.has(toLookupVal(stedfesting)) ? getSelectedStyle(feature) : getIconStyle(f, false);
        }
    }

    return defaultIfNil(style);
}

/**
 * Oppretter stil for en markert stedfesting
 * @param stedfesting
 * @public
 */
export function getSelectedStyle(feature: Feature): Style {
    const defaultIfNil = defaultTo(fallbackStyle);
    let style;

    // Cluster
    if (isCluster(feature) && feature.get('features').length > 1) {
        style = getClusterStyle(feature);
        style.getImage().setRadius(style.getImage().getRadius() + 4);
        style
            .getImage()
            .getFill()
            .setColor([255, 99, 1, 1]);
        return style;
    }

    // Stedfesting
    if (feature.get('features') !== undefined) {
        const [f] = feature.get('features');
        const stedfesting = fromFeature(f);

        if (isStedfesting(stedfesting)) {
            style = getIconStyle(f, true);
        }
    }
    return defaultIfNil(style);
}

export function getSelectedClusterStyle(feature: Feature): Style[] {
    const color =
        store.getState().settings.site === Site.SE ? themeSE.palette.primary.dark : theme.palette.primary.dark;
    //const defaultIfNil = defaultTo(fallbackStyle);
    const clusterSize = feature.getProperties().features.length;
    const radius = Math.min(12 + clusterSize * 0.1, 20);
    const selectedClusterStroke = alpha(theme.palette.stroke.selectedCluster, 0.8);
    const strokeWidth = 10;
    return [
        new Style({
            image: new Circle({
                fill: new Fill({
                    color: color,
                }),
                radius: radius,
            }),
            text: clusterText(clusterSize),
            zIndex: 10,
        }),
        new Style({
            image: new Circle({
                stroke: new Stroke({
                    color: selectedClusterStroke,
                    width: strokeWidth,
                }),
                radius: radius,
            }),
        }),
    ];
}

/**
 * @param feature
 * @public
 */
export function getClusterStyle(feature: Feature): Style[] {
    const color =
        store.getState().settings.site === Site.SE ? themeSE.palette.primary.dark : theme.palette.primary.dark;
    const stroke =
        store.getState().settings.site === Site.SE
            ? alpha(themeSE.palette.primary.dark, 0.3)
            : alpha(theme.palette.primary.dark, 0.3);
    const clusterSize = (feature.get('features') || []).length;
    const radius = Math.min(12 + clusterSize * 0.1, 20);
    const strokeSize = 10;
    return [
        new Style({
            image: new Circle({
                fill: new Fill({
                    color: color,
                }),

                radius: radius,
            }),
            zIndex: 10,
            text: clusterText(clusterSize),
        }),
        new Style({
            image: new Circle({
                stroke: new Stroke({
                    color: stroke,
                    width: strokeSize,
                }),
                radius: radius,
            }),
            zIndex: 1,
        }),
    ];
}

function toSvg(str: string): Element {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'image/svg+xml');
    return doc.children[0];
}

export function getColorFromFeature(feature: Feature): string {
    const stedfesting = fromFeature(feature);

    let id;
    if (isRovviltobservasjon(stedfesting) || isDodeRovdyr(stedfesting)) {
        id = stedfesting.artsID;
    }
    if (isRovviltskade(stedfesting)) {
        id = stedfesting.skadeårsakID;
    }
    if (isDNA(stedfesting)) {
        if (stedfesting.prøvestatusID.length > 0) {
            id = stedfesting.artsIDAnalyse;
            return getColorFromCarnivoreObservedId(id);
        } else {
            return theme.palette.dna.main;
        }
    }
    return getColorFromCarnivoreId(id);
}

export function getColorFromCarnivoreId(id: string): string {
    switch (parseInt(id)) {
        case Carnivore.Lynx:
            return theme.palette.lynx.main;
        case Carnivore.Wolverine:
            return theme.palette.wolverine.main;
        case Carnivore.Bear:
            return theme.palette.bear.main;
        case Carnivore.Wolf:
            return theme.palette.wolf.main;
        case Carnivore.Eagle:
            return theme.palette.goldenEagle.main;
        case Carnivore.UnknownProtected:
            return theme.palette.unknownProtected.main;
        default:
            return '#FFFFFF';
    }
}

export function getColorFromCarnivoreObservedId(id: string): string {
    switch (parseInt(id)) {
        case CarnivoreObserved.Lynx:
            return theme.palette.lynx.main;
        case CarnivoreObserved.Wolverine:
            return theme.palette.wolverine.main;
        case CarnivoreObserved.Bear:
            return theme.palette.bear.main;
        case CarnivoreObserved.Wolf:
            return theme.palette.wolf.main;
        case CarnivoreObserved.Eagle:
            return theme.palette.goldenEagle.main;
        case CarnivoreObserved.Unknown:
            return theme.palette.unknownProtected.main;
        default:
            return theme.palette.grey[700];
    }
}

export function getColorFromDamageCauseId(id: string): string {
    switch (parseInt(id)) {
        case DamageCause.Lynx:
            return theme.palette.lynx.main;
        case DamageCause.Wolverine:
            return theme.palette.wolverine.main;
        case DamageCause.Bear:
            return theme.palette.bear.main;
        case DamageCause.Wolf:
            return theme.palette.wolf.main;
        case DamageCause.Eagle:
            return theme.palette.goldenEagle.main;
        case DamageCause.UnknownProtected:
            return theme.palette.unknownProtected.main;
        default:
            return theme.palette.grey[700];
    }
}

const setColor = (color: string) => (svg: Element) => {
    svg.setAttribute('style', `fill: ${color};`);
    return svg;
};

const setInvertedColor = (color: string) => (svg: Element) => {
    if (color === '#ffffff') {
        svg.setAttribute('style', 'fill: #353535;');
    } else {
        svg.setAttribute('style', 'fill: #FFFFFF;');
    }
    const svgPaths = Array.from(svg.getElementsByClassName('st0'));
    svgPaths.map(element => element.setAttribute('style', `fill: ${color};`));
    return svg;
};

const createUrl = (svg: Element): string => {
    const str = new XMLSerializer().serializeToString(svg);
    return `data:image/svg+xml;base64,${btoa(str)}`;
};

// eslint-disable-next-line no-unused-vars
const svgLoadedHandler = (color: string, selected: boolean): ((str: string) => string) =>
    selected ? pipe(toSvg, setInvertedColor(color), createUrl) : pipe(toSvg, setColor(color), createUrl);

type CachedSvgUrl = string | Promise<string>;
// eslint-disable-next-line no-unused-vars
export function createSvgDataUrlLoader(): (baseUrl: string, feature: Feature, selected: boolean) => string {
    // Cache som holder på alle lastede, eller lastende ikoner
    const svgCache: Record<string, CachedSvgUrl> = {};

    // TypeGuards
    const isLoaded = (key: string): boolean => typeof svgCache[key] === 'string';
    const isLoading = (key: string): boolean => svgCache[key] instanceof Promise;

    // Funksjon som tar i mot en url og en feature
    // laster ikon for feature ved behov
    return (baseUrl, feature, selected): string => {
        // Cache-nøkkel er url + farge
        // Nettleser vil ta seg av caching av base-SVG, så derfor
        // cacher vi hver variant i svgCache
        const color = getColorFromFeature(feature);
        const key = baseUrl + color + selected;
        // Hvis ikon er etterspurt tidligere
        if (svgCache[key]) {
            if (isLoaded(key)) {
                // Ikon er ferdig lastet
                return svgCache[key] as string;
            } else if (isLoading(key)) {
                // Ikon er etterspurt, men ikke lastet ferdig
                const promise = svgCache[key] as Promise<string>;
                promise.then(() => feature.changed()); // oppdater feature når ikon er klart
                return baseUrl; // vis ikon uten riktig farge intill videre
            }
        } else {
            // Ikon er ikke etterspurt
            const promise = fetch(baseUrl) // Start lasting av ikon
                .then(res => res.text())
                .then(svgLoadedHandler(color, selected));

            svgCache[key] = promise; // legg promise i cache for å signalisere at det lastes

            promise.then(url => {
                // Når lastet, legg ikon i cache og tegn ikon på nytt
                svgCache[key] = url;
                feature.changed();
            });
        }
        return baseUrl;
    };
}
