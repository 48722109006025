import { makeStyles, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';
import classnames from 'classnames';
import React, {ButtonHTMLAttributes, ReactElement} from 'react';
import { useSelector } from 'react-redux';
import {ApplicationState, store} from '../../store';
import { Site } from '../../store/settings/types';
import {useLanguage} from '../../i18n';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        primaryButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            minHeight: '40px',
            border: 'none',
            padding: '8px 16px',
            '&:hover': {
                backgroundColor: theme.palette.primary.dark,
            },
            '&:focus': {
                outline: `2px solid ${theme.palette.primary.main}`,
                outlineOffset: '2px',
            },
            '&:disabled': {
                visibility: 'hidden',
            },
        },
        secondaryButton: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            minHeight: '40px',
            border: `2px solid ${store.getState().settings.site === Site.SE ? theme.palette.primary.main : theme.palette.primary.light}`,
            padding: '8px 16px',
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
            },
            '&:focus': {
                color: theme.palette.common.white,
                border: `2px solid ${theme.palette.primary.main}`,
                outline: `2px solid ${theme.palette.primary.main}`,
                outlineOffset: '2px',
                backgroundColor: theme.palette.primary.main,
            },
            '&:disabled': {
                visibility: 'hidden',
            },
        },
        linkButton: {
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            backgroundColor: 'unset',
            border: '0',
            borderRadius: '5px',
            padding: '12px 3px',
            cursor: 'pointer',
            '&:hover': {
                // backgroundColor: theme.palette.secondary.main,
                textDecoration: 'none',
            },
            '&:focus': {
                outline: `1px solid ${theme.palette.primary.light}`,
                // outlineOffset: '2px',
            },
            '&:disabled': {
                color: theme.palette.grey[500],
                textDecoration: 'none',
                backgroundColor: 'unset',
                cursor: 'unset',
            },
        },
        inlineLinkButton: {
            textDecoration: 'underline',
            color: theme.palette.primary.main,
            backgroundColor: 'unset',
            border: '0',
            padding: '0',
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'none',
            },
            '&:focus': {
                outline: `1px solid ${theme.palette.primary.light}`,
                outlineOffset: '2px',
            },
        },
        iconFocus: {
            border: '0',
            backgroundColor: 'unset',
            borderRadius: '50%',
            padding: '10px',
            height: '44px',
            width: '44px',
            cursor: 'pointer',
            color: theme.palette.primary.dark,
            '&:hover': {
                backgroundColor: theme.palette.secondary.dark,
            },
            '&:focus': {
                backgroundColor: theme.palette.action.active,
                outline: 'none',
            },
        },
        skipContent: {
            position: 'absolute',
            left: '-2000px',
            top: '0',
            margin: '12px',
            backgroundColor: theme.palette.secondary.light,
            fontSize: '20px',
            padding: '16px',
            color: theme.palette.primary.dark,
            '&:focus': {
                left: '0px',
                zIndex: 100,
                outline: `2px solid ${theme.palette.primary.dark}`,
                animation: '0.5s',
                // outlineOffset: '3px',
            },
        },
        dateButton: {
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.secondary.dark}`,
            backgroundColor: theme.palette.background.paper,
            borderRadius: 0,
            fontSize: '16px',
            minHeight: '50px',
            padding: '8px',
            fontFamily: 'inherit',

            '& span': {
                margin: '0 4px',
            },
            '&:hover': {
                backgroundColor: theme.palette.secondary.main,
            },
            '&:focus': {
                outline: 'none',
                border: `1px solid ${theme.palette.action.active}`,
                backgroundColor: theme.palette.action.active,
            },
        },
        dateButtonSelected: {
            border: `1px solid ${theme.palette.secondary.dark}`,
            backgroundColor: theme.palette.secondary.dark,
            color: theme.palette.text.primary,
            fontWeight: 'bold',
        },
    })
);

export function PrimaryButton(props: ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
    const classes = useStyles({});
    const buttonClass = classnames(classes.primaryButton, props.className);
    return (
        <button {...props} className={buttonClass}>
            {props.children}
        </button>
    );
}

export function SecondaryButton(props: ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
    const classes = useStyles({});
    const buttonClass = classnames(classes.secondaryButton, props.className);
    return (
        <button {...props} className={buttonClass}>
            {props.children}
        </button>
    );
}

export function InlineLinkButton(props: ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
    const classes = useStyles({});
    const linkButtonClass = classnames(classes.inlineLinkButton, props.className);

    return (
        <button {...props} className={linkButtonClass}>
            {props.children}
        </button>
    );
}

export function LinkButton(props: ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
    const classes = useStyles({});
    const linkButtonClass = classnames(props.className, classes.linkButton, );

    return (
        <button {...props} className={linkButtonClass}>
            {props.children}
        </button>
    );
}

export function IconButton(props: ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
    const classes = useStyles({});
    const iconButtonClass = classnames(classes.iconFocus, props.className);

    return (
        <button {...props} className={iconButtonClass}>
            {props.children}
        </button>
    );
}

export function HoppTilHovedinnhold({mainContentId}: {mainContentId: string}): JSX.Element {
    const classes = useStyles({});
    const l = useLanguage();
    return (
        <a href={`#${mainContentId}`} className={classes.skipContent} tabIndex={1} >
            {l('accessibility.skipToContent')}
        </a>
    );      
}

export function SkipFilters(): JSX.Element {
    const classes = useStyles({});
    const l = useLanguage();
    return (
        <a href={'#sidepanel_btngroup'} className={classes.skipContent}>
            {l('accessibility.skipFilter')}
        </a>
    );
}

type PresetButtonProps = {
    selected: boolean;
    onClick: () => void;
}

export function PresetButton(props: PresetButtonProps & ButtonHTMLAttributes<HTMLButtonElement>): ReactElement {
    const classes = useStyles({});
    const dateButtonClass = classnames(classes.dateButton, props.className, 'date-preset-button');
    const dateButtonSelectedClass = classnames(classes.dateButton, classes.dateButtonSelected, props.className, 'date-preset-button');
    const presetButtonClass = props.selected ? dateButtonSelectedClass : dateButtonClass;
    return (
        <button {...props}
            className={presetButtonClass}
            aria-pressed={props.selected}
        >
            {props.children}
        </button>
    );
}
