import {Feature, Map, View} from 'ol';
import Select, {SelectEvent} from 'ol/interaction/Select';
import VectorLayer from 'ol/layer/Vector';
import Overlay from 'ol/Overlay';
import {addProjection, get as getProjection} from 'ol/proj';
import {register} from 'ol/proj/proj4';
import Cluster from 'ol/source/Cluster';
import VectorSource from 'ol/source/Vector';
import proj4 from 'proj4';
import {MutableRefObject, useEffect, useRef} from 'react';
import {Observable, OperatorFunction, Subject, Subscription} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {fromOnable} from '../helpers';
import {isStedfesting} from '../model/stedfesting/Stedfesting';
import {Stedfesting} from '../model/stedfesting/StedfestingTypes';
import {store, useActionCreator} from '../store';
import {requestGKToken, selectStedfesting} from '../store/map/actions';
import {setTooltip} from '../store/tooltip/actions';
import {initMapListeners} from './mapActionHandler';
import {MapActionType} from './mapActions';
import {defs} from './proj4-defs';
import {getStyle} from './styles';
import {MapAction} from './types';
import {defaults as InteractionDefaults} from 'ol/interaction';
import {MapProjection} from '../constants/mapProjection';

const port$ = new Subject<MapAction<any>>();

const debug = <T>(debug: boolean): OperatorFunction<MapAction<T>, MapAction<T>> =>
    map(value => {
        if (debug) {
            // eslint-disable-next-line no-console
            console.log('DEBUG', value);
        }
        return value;
    });

export function onMapAction<T>(filterType: MapActionType): Observable<T> {
    return port$.pipe(
        filter((value: MapAction<T>): boolean => value.type === filterType),
        debug(false),
        map((val): T => val.value)
    );
}

export function mapDispatch<T>(action: MapAction<T>): void {
    return port$.next(action);
}

export const pointSource = new VectorSource();

export const clusterSource = new Cluster({
    distance: 60,
    source: pointSource,
});

const pointLayer = new VectorLayer({
    source: clusterSource,
    zIndex: 20,
    style: getStyle,
});
pointLayer.set('layerName', 'stedfesting');

export const select = new Select({
    layers: [pointLayer],
    hitTolerance: 10,
});

//Setup
proj4.defs(defs);
register(proj4);
const utm33 = getProjection(MapProjection.EPSG_32633);
const extent = [-2500000, 3500000, 3045984, 9045984];
utm33.setExtent(extent);
addProjection(utm33);

const view = new View({
    projection: utm33,
    center: [270728, 7041841],
    zoom: 4,
    maxZoom: 12,
    minZoom: 2,
    // Begrenser extent for å ikke kunne se kanten på kartene
    extent: [-1090000, 5600000, 2150000, 8130000] // [minx, miny, maxx, maxy]
});

const interactions = InteractionDefaults({
    altShiftDragRotate: false,
    pinchRotate: false,
});

export const olMap = new Map({
    target: '#map',
    controls: [],
    interactions: interactions,
    layers: [
        // new TileLayer({
        //     source: new OSM()
        // }),
        pointLayer,
    ],
    view: view,
});

export function useMap(map: Map): MutableRefObject<any> {
    const mapRef = useRef();
    useSelect(olMap);
    useClusterSize();

    useEffect(() => {
        const sub$: Subscription[] = initMapListeners();
        
        // Setter gkt (gatekeeper-token) på kartlag-urlene som bruker token 
        store.dispatch(requestGKToken());
        
        return function cleanUp() {
            sub$.forEach(sub => sub.unsubscribe());
        };
    }, []);

    useEffect((): void => {
        if (mapRef.current) {
            map.setTarget(mapRef.current);
        }
    });

    useEffect(() => {
        // map.updateSize();
    });

    return mapRef;
}

export const useSelect = (map: Map): [Stedfesting, (nyStedfesting: Stedfesting, nextNum: number) => void, number] => {
    const setSelectedStedfestings = useActionCreator(selectStedfesting);
    const setTooltipStedfesting = useActionCreator(setTooltip);

    useEffect(() => {
        const select$ = fromOnable(select, 'select').subscribe(() => clusterSource.changed());

        const selectHandler = (event): void => {
            const unclustered = getUnclusteredFeatures(event.selected);
            setSelectedStedfestings(unclustered.map(i => i.getProperties().stedfesting));
            select.getFeatures().clear();
            select.getFeatures().push(new Feature());

            if ((unclustered || []).length) {
                const { stedfesting } = unclustered[0].getProperties();
                if (isStedfesting(stedfesting)) {
                    setTooltipStedfesting(stedfesting);
                }
            } else {
                setTooltipStedfesting(null);
            }
        };
        select.on('select', selectHandler);
        return () => {
            select.un('select', selectHandler);
            select$.unsubscribe();
        };
    }, [map]);
    return;
};

olMap.addInteraction(select);

export const useClusterSize = (): void => {
    useEffect(() => {
        const zoomHandler = (): void => {
            const zoom = olMap.getView().getZoom();
            const distance = (pointLayer.getSource() as Cluster).getDistance();
            if (zoom >= 10 && distance !== 30) {
                (pointLayer.getSource() as Cluster).setDistance(30);
            } else if (zoom < 10 && distance !== 60) {
                (pointLayer.getSource() as Cluster).setDistance(60);
            }
        };
        view.on('change:resolution', zoomHandler);
        return () => view.un('change:resolution', zoomHandler);
    });
};

/**
 * Tar inn en array med features hvor noen av featurene kan være cluster-features, og returnerer en liste med rene uclustrede features
 * @param {Array<modules:ol/Feature>} features
 * @returns {Array<modules:ol/Feature>} features
 */
export function getUnclusteredFeatures(features: Feature[]): Feature[] {
    const selectedFeatures: Feature[] = [];

    features.forEach(feature => {
        if (feature.get('features'))
            // Cluster
            feature.get('features').forEach((clusteredFeature: Feature) => selectedFeatures.push(clusteredFeature));
        else selectedFeatures.push(feature); // Ikke Cluster
    });

    return selectedFeatures;
}

export function setOverlay(ref, coordinate): void {
    const popup = new Overlay({
        element: ref,
        stopEvent: false,
    });

    popup.setPosition(coordinate);
    olMap.addOverlay(popup);
}

/* eslint-disable no-undef */
//@ts-ignore
window.olMap = olMap;
