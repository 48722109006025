import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/root/root';
import { store } from './store';
import {UpdateSearchFilter, SetSearchinput, loadFeaturesFromSearchfilter} from './store/searchfilter/action';
import { setIsInitialized } from './store/settings/actions';
import { Filter } from './store/searchfilter/types';
import { initialSearchFilters } from './store/searchfilter/reducer';
import { defaultTo} from 'ramda';
import { suggestionSearch } from './components/searchbar/suggestionService';

function StringArrayToNumberArray(array: string): number[] {
    if (array.length > 0) {
        return array.split(',').map(Number);
    }
    return [];
}

const stringToBoolean = (value: string): boolean => {
    return value === 'true';
};

const { language } = store.getState().settings;
const searchParams = new URLSearchParams(window.location.search);
let searchfilter: Filter = initialSearchFilters;
if (window.location.pathname === '/filter') {
    searchfilter = {
        Carnivore:
            searchParams.get('Carnivore') !== null
                ? StringArrayToNumberArray(searchParams.get('Carnivore'))
                : initialSearchFilters.Carnivore,
        CarnivoreDamage:
            searchParams.get('CarnivoreDamage') !== null
                ? StringArrayToNumberArray(searchParams.get('CarnivoreDamage'))
                : initialSearchFilters.CarnivoreDamage,
        Evaluation:
            searchParams.get('Evaluation') !== null
                ? StringArrayToNumberArray(searchParams.get('Evaluation'))
                : initialSearchFilters.Evaluation,
        Observation:
            searchParams.get('Observation') !== null
                ? StringArrayToNumberArray(searchParams.get('Observation'))
                : initialSearchFilters.Observation,
        Offspring:
            searchParams.get('Offspring') !== null
                ? stringToBoolean(searchParams.get('Offspring'))
                : initialSearchFilters.Offspring,
        FromDate:
            searchParams.get('FromDate') !== null
                ? decodeURIComponent(searchParams.get('FromDate'))
                : initialSearchFilters.FromDate,
        ToDate:
            searchParams.get('ToDate') !== null
                ? decodeURIComponent(searchParams.get('ToDate'))
                : initialSearchFilters.ToDate,
        Country: defaultTo(initialSearchFilters.Country, searchParams.get('Country')?.split(',')),
        Region: defaultTo(initialSearchFilters.Region, searchParams.get('Region')?.split(',')),
        County: defaultTo(initialSearchFilters.County, searchParams.get('County')?.split(',')),
        Municipality: defaultTo(initialSearchFilters.Municipality, searchParams.get('Municipality')?.split(',')),
        IndividualNameOrID: defaultTo(initialSearchFilters.IndividualNameOrID)(searchParams.get('IndividualNameOrID')),
        Barcode: defaultTo(initialSearchFilters.Barcode)(searchParams.get('Barcode')?.split(',')),
        Rovdjursforum:
            searchParams.get('Rovdjursforum') !== null
                ? Boolean(searchParams.get('Rovdjursforum'))
                : initialSearchFilters.Rovdjursforum,
        ID: defaultTo(initialSearchFilters.ID)(searchParams.get('ID')?.split(',')),
    };
    store.dispatch(UpdateSearchFilter(searchfilter));
}
if (window.location.pathname === '/individ') {
    searchfilter = {
        ...searchfilter,
        IndividualNameOrID: defaultTo(initialSearchFilters.IndividualNameOrID)(searchParams.get('IndividualNameOrID')),
    };
    suggestionSearch(searchfilter.IndividualNameOrID)
        .then(res => {
            if (res.length === 1) {
                store.dispatch(SetSearchinput(`${res[0].id} ${res[0].tekst} `));
            } else {
                store.dispatch(SetSearchinput(searchfilter.IndividualNameOrID));
            }
        });
    store.dispatch(UpdateSearchFilter(searchfilter));
}

if (window.location.pathname === '/search') {
    searchfilter = {
        ...searchfilter,
        Barcode: defaultTo(initialSearchFilters.Barcode)(searchParams.get('T')?.split(',')),
        ID: defaultTo(initialSearchFilters.ID)(searchParams.get('T')?.split(',')),
    };
    store.dispatch(UpdateSearchFilter(searchfilter));
    store.dispatch(SetSearchinput(searchfilter.Barcode.join(',')));
}

// Initielt søk her for å slippe dobbel initiel rendring
export async function InitSearch(): Promise<void> {
    await loadFeaturesFromSearchfilter(store.dispatch, searchfilter, language)
        .then(() => store.dispatch(setIsInitialized(true)));
}

/* eslint-disable no-undef */
ReactDOM.render(
    <Root store={store} />,
    document.getElementById('rovbaseApp')
);
