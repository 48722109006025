export enum ControlType {
    FieldVerificationCarnivoreContact = 1,
    FieldVerificationSNO = 2,
    Photograph = 3,
    NotVerified = 4,
    Interview = 5,
    Laboratory = 6,
    FieldVerificationLST = 7,
    FieldVerificationSamiVillage = 8,
    GPS = 9,
}
