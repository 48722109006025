import { curry, pipe } from 'ramda';
import Onable from 'ol/Observable';
import { fromEventPattern, Observable } from 'rxjs';
import { format } from 'date-fns';
import { Language } from './constants/language';
import {isValidDate} from './components/dateFilter/dateFilter.util';
import { formatISO } from 'date-fns';

type BEvent = {
    preventDefault: Effect;
};

type Effect = (args: any[]) => any;

export function btnHandler(fn: Effect): (...args: any) => (e: BEvent) => any {
    return (...args: any) => {
        return (e: BEvent) => {
            // @ts-ignore
            e.preventDefault();
            // @ts-ignore
            fn(...args);
        };
    };
}

export function promisify<T>(v: T): Promise<T> {
    return new Promise(res => {
        res(v);
    });
}

/**
 * @description Tar inn en effekt (a: T) => any, og returnerer en funksjon (a: T) => T, som utfører effekten
 * resultatet fra effekten blir ignorert
 */
export const transparentEffect = curry(<T>(fn: (a: T) => any, a: T) => {
    fn(a);
    return a;
});

export const fromOnable = <T extends Onable, TRes>(obj: T, type: Parameters<T['on']>[0][0]): Observable<TRes> => fromEventPattern(
    handler => obj.on(type, handler),
    handler => obj.un(type, handler)
);

export const dateToISOString = (date: Date) => formatISO(date, { representation: 'date' });
export const dateToStringForUrl = pipe(dateToISOString, encodeURIComponent);
export const dateFormatString = (language: Language): string => {
    if (language === Language.SV) {
        return 'yyyy.MM.dd';
    }
    if (language === Language.EN) {
        return 'MM.dd.yyyy';
    }
    return 'dd.MM.yyyy';
};

export function formatDateStringToLocaleString(dateString: string, lang: Language): string {
    if (!isValidDate(dateString)) return '';
    
    return format(dateString, dateFormatString(lang));
}