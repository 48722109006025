import {useLanguage, useLanguageTemplate} from "../../i18n";
import {SampleType} from "../../constants/sampleType";
import {Carnivore} from "../../constants/carnivore";
import {SampleStatus} from "../../constants/sampleStatus";
import {CarnivoreObserved} from "../../constants/carnivoreObserved";
import React from "react";
import {getStringFromEnum, isNullOrEmpty} from "./tooltip.util";
import {DNA} from "../../model/stedfesting/StedfestingTypes";
import {BarcodeLink, IndividLink} from "./tooltip";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import './tooltip.scss';

function useIntro(stedfesting: DNA): string {
    const lt = useLanguageTemplate();
    const l = useLanguage();
    
    const observationType = l(`tooltip.sampleType.${getStringFromEnum(SampleType, stedfesting.prøvetypeID)}`).toLowerCase();
    const art = l(`carnivore.${getStringFromEnum(Carnivore, stedfesting.artsIDPrøve)}`).toLowerCase();
    
    return lt('dnaInfo.submitted', {observationType: observationType, art: art});
}

function useAnalysed(stedfesting: DNA): string {
    const lt = useLanguageTemplate();
    const l = useLanguage();
    const provestatusID = parseInt(stedfesting?.prøvestatusID);

    switch (provestatusID) {
        case SampleStatus.Functional:
            const analysedArt =
                stedfesting.artsIDAnalyse.length > 0 &&
                l(`carnivoreObserved.${getStringFromEnum(CarnivoreObserved, stedfesting.artsIDAnalyse)}`).toLowerCase();

            return lt('dnaInfo.analysed', {
                art: analysedArt,
            });
        case SampleStatus.NotFunctional:
            return l('tooltip.dnaAnalysed.nonFunctioning');
            
        default:
            return l('dnaInfo.unanalysed');
    }

}

export function DnaTooltipDescription(): JSX.Element {
    const stedfesting = useSelector((state: ApplicationState) => state.tooltip.stedfesting) as DNA;
    const l = useLanguage();
    const analysed = useAnalysed(stedfesting);
    const intro = useIntro(stedfesting);
    
    return (
        <div className="tooltip-description">
            <div>{intro} {analysed}</div> 
            {stedfesting.individ && <IndividLink individ={[stedfesting.individ]} text={l('dnaInfo.identity')} />}
            <BarcodeLink barcode={stedfesting.strekkode} text={l('dnaInfo.barcode')} />
        </div>
    );
}
