import React, {ReactElement, useEffect} from "react";
import {ExpansionPanel, ExpansionPanelContent, ExpansionPanelTitle} from "../expansionPanel/expansionPanel";
import AreaSearchableSelect from "../searchableSelect/areaSearchableSelect";
import {AreaSearchTypes} from "../../constants/areasearchTypes";
import {useLanguage} from "../../i18n";
import {
    ADD_LOCATIONLISTELEMENT,
    CLEAR_LOCATIONLISTELEMENTS,
    REMOVE_LOCATIONLISTELEMENT
} from "../../store/searchfilter/types";
import {ApplicationState, useActionCreator} from "../../store";
import {ToggleLocationListElementAndSearch} from "../../store/searchfilter/action";
import {
    createLocationString,
    locationIDsNotInAreaObjects,
    searchFilterToAreaSearchFilter
} from "../searchfilter/searchfilter.utils";
import areaService from "../../services/areaService";
import {useSelector} from "react-redux";
import {Area} from "../../model/area/area";
import '../searchfilter/searchfilter.scss';

export function GeographyFilter(): ReactElement {
    const l = useLanguage();
    const locationToggle = useActionCreator(ToggleLocationListElementAndSearch);
    const searchFilter = useSelector((state: ApplicationState) => state.searchFilter.searchFilter);
    const language = useSelector((state: ApplicationState) => state.settings.language);
    const [areas, setAreas] = React.useState<Area[]>([]);
    const [locationText, setLocationText] = React.useState<string>(l('searchfilter.allAreas'));

    const createLocationTitle = (areas: Area[]): string =>
        createLocationString(areas, l('searchfilter.allAreas'));
    
    const onLocationChange = (selectedOption: Area[], action: any): void => {
        switch (action.action) {
            case 'select-option':
                setAreas(areas.concat(action.option));
                locationToggle(ADD_LOCATIONLISTELEMENT, action.option.locationType, action.option.value);
                setLocationText(createLocationTitle(selectedOption));
                break;
            case 'remove-value':
                setAreas(areas.filter(area => area.value !== action.removedValue.value));
                locationToggle(
                    REMOVE_LOCATIONLISTELEMENT,
                    action.removedValue.locationType,
                    action.removedValue.value
                );
                setLocationText(createLocationTitle(selectedOption));
                break;

            case 'clear':
                setAreas([]);
                locationToggle(CLEAR_LOCATIONLISTELEMENTS, null, null);
                setLocationText(l('searchfilter.allAreas'));
                break;
        }
    };

    useEffect(() => {
        const filter = searchFilterToAreaSearchFilter(searchFilter);
        if (locationIDsNotInAreaObjects(filter, areas)) {
            areaService
                .getAreasFromID(filter, language)
                .then((res) => {
                    if (res.length > 0) {
                        setAreas(res);
                        setLocationText(createLocationTitle(res));
                    }
                })
                // eslint-disable-next-line no-console
                .catch(error => console.error(error));
        }
    }, []);
    
    return (
        <div id="geography-filter">
            <h3>{l('searchfilter.geography')}</h3>
            <ExpansionPanel className="searchfilter-expansion-panel">
                <ExpansionPanelTitle>{locationText}</ExpansionPanelTitle>
                <ExpansionPanelContent>
                    <div>
                        <AreaSearchableSelect
                            areas={areas}
                            onChange={onLocationChange}
                            placeholder={l('searchableSelect.locationInputPlaceholder')}
                            noOptionsMessage={l('searchableSelect.noOptionsMessage')}
                            filterMenu={true}
                            isMultiSelect={true}
                            type={AreaSearchTypes.all}
                        />
                    </div>
                </ExpansionPanelContent>
            </ExpansionPanel>
        </div>
    )
}
