import { Button, Hidden, Theme } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { createStyles, makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { btnHandler } from '../../helpers';
import { useLanguage } from '../../i18n';
import { toggleMenu } from '../../store/menu/action';
import './menubutton.scss';

type DispatchProps = {
    toggleMenu: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuButton: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            '&:focus': {
                outline: `2px solid ${theme.palette.primary.main}`,
                outlineOffset: '2px',
            },
        },
    })
);
type Props = DispatchProps;

function _MenuButton(props: Props): JSX.Element {
    const classes = useStyles({});
    const menuButtonMobile = classnames(classes.menuButton, 'menu-button--mobile');
    const menuButton = classnames(classes.menuButton, 'menu-button');
    const l = useLanguage();

    return (
        <Fragment>
            <Hidden smUp>
                <button
                    className={menuButtonMobile}
                    onClick={btnHandler(props.toggleMenu)()}
                    aria-label={l('menu')}
                    tabIndex={3}
                >
                    <MenuIcon fontSize="large" aria-hidden={true}/>
                </button>
            </Hidden>
            <Hidden xsDown>
                <div className="menu-button-wrapper">
                    <nav> 
                        <button
                            className={menuButton}
                            onClick={btnHandler(props.toggleMenu)()}
                            accessKey="3"
                            aria-haspopup={true}
                        >
                            {l('menu')}
                            <MenuIcon className="menu-icon" aria-hidden={true} />
                        </button>
                    </nav>
                </div>
            </Hidden>
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        toggleMenu: () => dispatch(toggleMenu()), //toggleMenu()
    };
};

export const MenuButton = connect(null, mapDispatchToProps)(_MenuButton);
