import { Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@material-ui/core/';
import React from 'react';
import { useSelector } from 'react-redux';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { DamageCause } from '../../constants/damageCause';
import { useLanguage } from '../../i18n';
import { getColorFromDamageCauseId } from '../../map/styles';
import { ApplicationState } from '../../store';
import { CustomizedLegendBarChart } from '../statisticalInfoBox/statisticalInfoBox';
import { getStringFromEnum } from '../tooltip/tooltip.util';
import './damageCauseTable.scss';
import DamageCauseTableSmall from './damageCauseTableSmall';
import {reindeerCompensationDamageCause, sheepCompensationDamageCause} from '../../constants/statistics';
import {useMobileBreakpointInnsyn} from '../../hooks/breakpoint';
import { Payload } from 'recharts/types/component/DefaultLegendContent';

type PieProps = {
    data: (sheepCompensationDamageCause | reindeerCompensationDamageCause)[];
};

function customPieLabel(props: any): JSX.Element {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, percent } = props;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + 1.6 * radius * Math.cos(-midAngle * RADIAN); //
    const y = cy + 1.6 * radius * Math.sin(-midAngle * RADIAN); //
    return (
        <text x={x} y={y} fill="#333" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {percent < 0.5 ? null : `${percent.toFixed(1)}%`}
        </text>
    );
}

function getLegendPayLoad(id: string, value: string): Payload {
    const load: Payload = {
        value: value,
        type: 'circle',
        id: id,
        color: getColorFromDamageCauseId(id),
    };
    return load;
}

export function DamageCausePieChart(props: PieProps): JSX.Element {
    const l = useLanguage();
    const mobileScreen = useMobileBreakpointInnsyn();
    const boxWidth = mobileScreen ? '100%' : 400;
    const outerRadius = mobileScreen ? 90 : 100;
    const innerRadius = mobileScreen ? 50 : 60;
    const notEmptyData = props.data?.reduce((sum: number, row) => sum + (row?.compensatedTotal ?? 0), 0) !== 0;
    return (
        <>
        {notEmptyData && 
        <ResponsiveContainer width={boxWidth} height={400}>
            <PieChart aria-hidden={true} margin={{ bottom: 20 }}>
                <Pie
                    data={props.data}
                    dataKey="percent"
                    outerRadius={outerRadius}
                    innerRadius={innerRadius}
                    labelLine={false}
                    isAnimationActive={false}
                    label={customPieLabel}
                    aria-hidden={true}
                >
                    {props.data.map((row, key) => (
                        <Cell key={key} fill={getColorFromDamageCauseId(row.artID)} aria-hidden={true} />
                    ))}
                </Pie>
                {/* <Tooltip payload={pieChartData.map(row => getTooltipPayLoad(row.artID, row.percent))} active={true} separator="% " /> */}
                <Legend
                    verticalAlign="bottom"
                    payload={props.data.map(row =>
                        getLegendPayLoad(row.artID, l(`carnivore.${getStringFromEnum(DamageCause, row.artID)}`))
                    )}
                    content={<CustomizedLegendBarChart />}
                />
            </PieChart>
        </ResponsiveContainer>
}
</>
    );
}

export default function DamageCauseTableSheep(): JSX.Element {
    const damageCauseData = useSelector((state: ApplicationState) => state.refund.sheepStatistics.damageCauseList);
    const pieChartData = damageCauseData.filter(row => row.artID !== 'Totalt');
    const l = useLanguage();
    const mobileScreen = useMobileBreakpointInnsyn();

    return (
        <div className="damage-cause">
            <h3>{l('refund.compensationCarnivoreTitle')}</h3>
            <div className="damage-cause-table-and-chart">
                {mobileScreen ? (
                    <DamageCauseTableSmall apiData={damageCauseData} />
                ) : (
                    <Table size="small" className="damage-cause-table">
                        <TableHead>
                            <TableRow>
                                <TableCell >{l('refund.species')}</TableCell>
                                <TableCell >{l('refund.sheep')}</TableCell>
                                <TableCell >{l('refund.lamb')}</TableCell>
                                <TableCell >{l('refund.compensatedTotal')}</TableCell>
                                <TableCell >{l('refund.compensatedPercent')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {damageCauseData.map(row => (
                                <TableRow key={row.artID} hover={true}>
                                    <TableCell scope="row">
                                        {row.artID === 'Totalt'
                                            ? 'Totalt'
                                            : l(`carnivore.${getStringFromEnum(DamageCause, row.artID)}`)}
                                    </TableCell>
                                    <TableCell>{row.compensatedSheep.toLocaleString()}</TableCell>
                                    <TableCell>{row.compensatedLamb.toLocaleString()}</TableCell>
                                    <TableCell>{row.compensatedTotal.toLocaleString()}</TableCell>
                                    <TableCell>{row.percent.toFixed(1)} %</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}
                <div className="damage-cause-pie-chart">
                    <DamageCausePieChart data={pieChartData} />
                </div>
            </div>
        </div>
    );
}
