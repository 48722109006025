import {useLanguage, useLanguageTemplate} from '../../i18n';
import {Carnivore} from '../../constants/carnivore';
import {causeOfDeathType} from '../../constants/causeOfDeathType';
import {Gender} from '../../constants/gender';
import {Evaluation} from '../../constants/evaluation';
import React from 'react';
import {getStringFromEnum, notNullOrEmpty} from './tooltip.util';
import {DodeRovdyr} from '../../model/stedfesting/StedfestingTypes';
import {IndividLink} from './tooltip';
import {useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import './tooltip.scss';
import {OutcomeDeadCarnivore} from '../../constants/outcomeDeadCarnivore';

const weightNotNullIsh = (weight: string): boolean => notNullOrEmpty(weight) && parseInt(weight) !== 0 && weight !== '0,00';
const getWeight = (rovdyr: DodeRovdyr) => {
    const l = useLanguage();
    if (weightNotNullIsh(rovdyr.helvekt)){
        return `${rovdyr.helvekt} kg`
    }
    if (weightNotNullIsh(rovdyr.slaktevekt)){
        return `${rovdyr.slaktevekt} kg (${l('deadCarnivore.slaughterWeight')})`;
    }
    return null;
}

function useCauseOfDeath(dodeRovdyr: DodeRovdyr): string {
    const l = useLanguage();
    const theAnimal = l(`theCarnivore.${getStringFromEnum(Carnivore, dodeRovdyr.artsID)}`);
    const causeOfDeath = getStringFromEnum(causeOfDeathType, dodeRovdyr.bakgrunnArsakID);
    const bakgrunnArsak = parseInt(dodeRovdyr.bakgrunnArsakID);
    const utfall = parseInt(dodeRovdyr.utfallID);
    const causeOfDeathWithOutcome = [causeOfDeathType.LicenseHunting, causeOfDeathType.QuotaHunting, causeOfDeathType.DamageControl, causeOfDeathType.DefenceOfAnimal, causeOfDeathType.SnoMission, causeOfDeathType.SelfDefence];
    const withOutcome = causeOfDeathWithOutcome.includes(parseInt(dodeRovdyr.bakgrunnArsakID));
    const outcome = withOutcome ? `${getStringFromEnum(OutcomeDeadCarnivore, dodeRovdyr.utfallID)}.` : '';
    const causeOfDeathIntetkjonn = [causeOfDeathType.CollisionRoad, causeOfDeathType.CollisionRail];
    const erIntetkjonn = parseInt(dodeRovdyr.artsID) === Carnivore.Lynx && (causeOfDeathIntetkjonn.includes(bakgrunnArsak) || (causeOfDeathWithOutcome.includes(bakgrunnArsak) && utfall === OutcomeDeadCarnivore.ShotDamageConfirmed));
    const intetkjonn = erIntetkjonn ? 'intetkjonn.' : '';
    
    const deathCausePhrase = l(`deadCarnivore.deathCausePhrase.${intetkjonn}${outcome}${causeOfDeath}`);
    
    return `${theAnimal} ${deathCausePhrase}. `;
}

function useIntro(dodeRovdyr: DodeRovdyr): string {
    const lt = useLanguageTemplate();
    const l = useLanguage();
    
    const ageIsNumber: boolean = notNullOrEmpty(dodeRovdyr.alder) && !isNaN(parseInt(dodeRovdyr.alder));

    const year = ageIsNumber ? l('deadCarnivore.year') : '';
    const ageSuffix = dodeRovdyr.alderFastsattNINA ? l('deadCarnivore.ageByNINA') : (dodeRovdyr.alderFastsattSVA ? l('deadCarnivore.ageBySVA') : l('deadCarnivore.agePreliminary'));

    const age =
        dodeRovdyr.alder.length > 0
            ? `${dodeRovdyr.alder.toLowerCase()} ${year} (${ageSuffix})`
            : l('deadCarnivore.unknown').toLowerCase();
    const sex =
        dodeRovdyr.kjønnID.length > 0
            ? l(`deadCarnivore.gender.${getStringFromEnum(Gender, dodeRovdyr.kjønnID)}`).toLowerCase()
            : l('deadCarnivore.gender.unknown').toLowerCase();

    const weight = getWeight(dodeRovdyr) ?? l('deadCarnivore.unknown').toLowerCase();
    
    return lt('tooltip.dead', {
        sex: sex,
        weight: weight,
        age: age,
        documentation: l(`evaluation.${getStringFromEnum(Evaluation, dodeRovdyr.vurderingID)}`).toLowerCase(),
    });
}

function useDocumentation(dodeRovdyr: DodeRovdyr): string {
    const l = useLanguage();
    return notNullOrEmpty(dodeRovdyr.vurderingID) ? 
        `${l('deadCarnivore.documentation')} ${l(`evaluation.${getStringFromEnum(Evaluation, dodeRovdyr.vurderingID)}`).toLowerCase()}. `
        : 
        '';
}

export function DodeRovdyrTooltipDescription(): JSX.Element {
    const dodeRovdyr = useSelector((state: ApplicationState) => state.tooltip.stedfesting) as DodeRovdyr;
    const lt = useLanguageTemplate();
    const l = useLanguage();
    
    const causeOfDeath = useCauseOfDeath(dodeRovdyr);
    const intro = useIntro(dodeRovdyr);
    const documentation = useDocumentation(dodeRovdyr);
    
    const theAnimal = l(`theCarnivore.${getStringFromEnum(Carnivore, dodeRovdyr.artsID)}`);
    const identity = lt('tooltip.dead.identity', {
        theAnimal: theAnimal,
    });
    
    return (
        <div className="tooltip-description">
            {causeOfDeath}
            {intro}
            {documentation}
            {dodeRovdyr.individ && <IndividLink individ={[dodeRovdyr.individ]} text={identity}/>}
            {dodeRovdyr.yngling && 
                <div className={'observation-types'}>
                    {l('observations.offspring')}
                </div>
            }
        </div>
    );
}
