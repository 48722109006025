/* eslint-disable react/prop-types */
import {Chip, MenuItem, Paper, TextField, Theme, Typography, useTheme} from '@material-ui/core';
import {BaseTextFieldProps} from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import SearchIcon from '@material-ui/icons/Search';
import {createStyles, makeStyles} from '@material-ui/styles';
import classnames from 'classnames';
import {default as React, HTMLAttributes, useState} from 'react';
import AsyncSelect from 'react-select/async';
import {ValueContainerProps} from 'react-select/src/components/containers';
import {ControlProps} from 'react-select/src/components/Control';
import {MenuProps, NoticeProps} from 'react-select/src/components/Menu';
import {MultiValueProps} from 'react-select/src/components/MultiValue';
import {ActionMeta} from 'react-select/src/types';
import {AreaSearchTypes} from '../../constants/areasearchTypes';
import {useLanguage} from '../../i18n';
import areaService, {AreaSearchResult} from '../../services/areaService';
import './searchableSelect.scss';
import {IconButton} from '../themeButtton/themeButton';
import {Area} from '../../model/area/area';
import {OptionProps} from 'react-select/src/components/Option';
import {ReindeerLocation} from '../../model/area/reindeerLocation';
import {SingleValueProps} from 'react-select/src/components/SingleValue';

type Location = Area | ReindeerLocation;

type OwnProps = {
    areas: Location[]
    onChange: (selectedOption: Location | Location[], action: ActionMeta<Location>) => void;
    placeholder: string;
    noOptionsMessage: string;
    filterMenu: boolean;
    isMultiSelect: boolean;
    type: AreaSearchTypes;
};

function areaSearch(input: string, type: AreaSearchTypes): Promise<AreaSearchResult[]> {
    switch (type) {
        case AreaSearchTypes.all:
            return areaService.getAllAreas(input);
        case AreaSearchTypes.norway:
            return areaService.getNorwayAreas(input);
        case AreaSearchTypes.reindeer:
            return areaService.getReindeerAreas(input);
        default:
            throw Error(`Unhandled case: ${type}`);
    }
}

/**Hentet fra https://material-ui.com/components/autocomplete/#react-select */

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            border: `1px solid ${theme.palette.grey[400]}`,
            minHeight: '44px',
            backgroundColor: theme.palette.background.paper,
        },
        rootMobile: {
            flexGrow: 1,
            minWidth: 290,
        },
        input: {
            display: 'flex',
            padding: 0,
            height: 'auto',
            borderBottom: 0,
            underline: 0,
        },
        valueContainer: {
            display: 'flex',
            flexWrap: 'wrap',
            flex: 1,
            alignItems: 'center',
            overflow: 'hidden',
            minHeight: '42px',
        },
        singleValue: {
            fontSize: 16,
            maxWidth: '95%',
            padding: '8px 4px'
        },
        chip: {
            margin: theme.spacing(0.5, 0.25),
            backgroundColor: theme.palette.grey[300],
            '&:focus': {
                backgroundColor: theme.palette.grey[200],
                border: `2px solid ${theme.palette.grey[500]}`,
            },
        },
        noOptionsMessage: {
            padding: theme.spacing(1, 2),
        },
        paper: {
            position: 'absolute',
            zIndex: 1,
            marginTop: '1px',
            left: '-38px',
            right: 0,
        },
        divider: {
            height: theme.spacing(2),
        },
        icon: {
            color: theme.palette.primary.main
        }
    })
);

function NoOptionsMessage(props: NoticeProps<Location, false>): JSX.Element {
    return (
        <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

function inputComponent({ inputRef, ...props }: InputComponentProps): JSX.Element {
    return <div ref={inputRef} {...props} />;
}

function Control(props: ControlProps<Location, true>): JSX.Element {
    const {
        children,
        innerProps,
        innerRef,
        selectProps: { classes, TextFieldProps },
    } = props;

    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
}

function Option(props: OptionProps<Location, false>): JSX.Element {
    return (
        <MenuItem
            ref={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 'normal' : 'lighter',
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function ValueContainer(props: ValueContainerProps<Location, false>): JSX.Element {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function SingleValue(props: SingleValueProps<Location>): JSX.Element {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function MultiValue(props: MultiValueProps<Location>): JSX.Element {
    return (
        <Chip
            tabIndex={0}
            label={props.children}
            className={props.selectProps.classes.chip}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

function Menu(props: MenuProps<Location, false>): JSX.Element {
    return (
        <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Control,
    Menu,
    Option,
    MultiValue,
    NoOptionsMessage,
    SingleValue,
    ValueContainer,
};

/** Hentet slutt */

export default function AreaSearchableSelect(props: OwnProps): JSX.Element {
    const theme = useTheme();
    const [inputText, setInputText] = useState<string>('');
    const classes = useStyles({});
    const searchWrapperClass = classnames(classes.root, 'locationSearch');
    const iconClass = classnames(classes.icon, 'icon-button-wrapper');
    const [activeTimerId, setActiveTimerId] = useState<number>();
    const l = useLanguage();
    const selectStyles = {
        input: (base) => ({
            ...base,
            color: theme.palette.text.primary,
            margin: '0px',
            //position: 'absolute',
            '& input': {
                font: 'inherit',
            },
        }),
    };

    function getOptionsWithTimeout(input: string): Promise<AreaSearchResult[]> {
        clearTimeout(activeTimerId);
        return new Promise<void>(resolve => {
            const timer = window.setTimeout(function() {
                clear();
                resolve();
            }, 1000);
            setActiveTimerId(timer);
        }).then(() => {
            return areaSearch(input, props.type);
        });
    }

    function clear(): void {
        clearTimeout(activeTimerId);
        setActiveTimerId(activeTimerId);
    }
    
    return (
        <div className={searchWrapperClass}>
            <span className={iconClass} aria-hidden={true}>
                <SearchIcon />
            </span>
            <span className="locationSearch-label">
                <AsyncSelect
                    isClearable={true}
                    key={props.type}
                    styles={selectStyles}
                    aria-label={l('ariaLabel.geographySearch')}
                    classes={classes}
                    inputId="react-select-location"
                    isMulti={props.isMultiSelect}
                    classNamePrefix="searchable-select"
                    cacheOptions
                    defaultOptions
                    value={props.areas}
                    inputValue={inputText}
                    onInputChange={setInputText}
                    className="searchable-select-container"
                    placeholder={props.placeholder}
                    loadOptions={getOptionsWithTimeout}
                    onChange={props.onChange}
                    maxMenuHeight={250}
                    components={components}
                    noOptionsMessage={() => props.noOptionsMessage}
                />
            </span>
        </div>
    );
}
