import React from "react";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {DnaTooltipDescription} from "./DNATooltipDescription";
import {RovviltobservasjonTooltipDescription} from "./rovviltobservasjonTooltipDescription";
import {RovviltskadeTooltipDescription} from "./rovviltskadeTooltipDescription";
import {DodeRovdyrTooltipDescription} from "./dodeRovdyrTooltipDescription";
import {Datatype} from "../../model/stedfesting/StedfestingTypes";


export function TooltipDescription(): JSX.Element {
    const datatype = useSelector((state: ApplicationState) => state.tooltip.stedfesting?.datatype);
    
    switch (datatype) {
        case Datatype.DNA: 
            return <DnaTooltipDescription />    
        case Datatype.DodeRovdyr:
            return <DodeRovdyrTooltipDescription />
        case Datatype.Rovviltobservasjon: 
            return <RovviltobservasjonTooltipDescription />
        case Datatype.Rovviltskade: 
            return <RovviltskadeTooltipDescription />
        default:
            return null;
    }
}