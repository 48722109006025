import VectorSource from "ol/source/Vector";
import {Extent} from "ol/extent";
import {fromExtent} from "ol/geom/Polygon";
import WKT from "ol/format/WKT";
import {Layer} from "../model/layer/layer";
import queryString from "query-string";
import Feature from "ol/Feature";
import { pipe, map } from 'ramda'

type SimpleFeature = {
    id: string;
    wkt: string;
    datatype: string;
}

let wkt = new WKT();

function toFeature(simplefeature: SimpleFeature): Feature {
    return new Feature({
        geometry: wkt.readGeometry(simplefeature.wkt)
    });
}

function createUrl(extent: Extent, layer: Layer): string {
    let extentGeom = fromExtent(extent);
    let wktPolygon = wkt.writeGeometry(extentGeom);

    const urlBase = layer.urls[0];
    const params = {extent: wktPolygon};
    return urlBase
        .concat(('?'))
        .concat(queryString.stringify(params));
}

const addToSource = (source: VectorSource) => (feature: Feature): void => {
    source.addFeature(feature);
};

const parseAndAdd = (source: VectorSource) => pipe(toFeature, addToSource(source));

export const VectorLayerLoader = (source: VectorSource, layer: Layer) => (extent: Extent): void => {
    const url = createUrl(extent, layer);

    fetch(url, {method: 'POST'})
        .then(res => {
            if (!res.ok) {
                throw Error(res.statusText);
            } else {
                return res.json();
            }
        })
        .then((json: SimpleFeature[]) => {
            json.forEach(parseAndAdd(source));
        })
        .catch(error => {
            source.removeLoadedExtent(extent);
            // eslint-disable-next-line no-console
            console.error(error);
        });

};