import { AnyAction } from 'redux';
import { MenuActionType } from './types';

export function openMenu(): AnyAction {
    return {
        type: MenuActionType.OPEN
    };
}

export function closeMenu(): AnyAction {
    return {
        type: MenuActionType.CLOSE
    };
}

export function toggleMenu(): AnyAction {
    return {
        type: MenuActionType.TOGGLE
    };
}
