import {Action, AnyAction, Dispatch} from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ApplicationState } from '..';
import statisticService from '../../services/statisticService';
import {
    isLoadingAction,
    RECEIVE_REFUNDLISTDATA,
    RECEIVE_REFUNDSEARCHRESULTSHEEP,
    RefundDataListdata,
    RefundFetchActionTypes,
    REQUEST_REFUNDLISTDATA,
    SET_ISFETCHING,
    SheepStatistics,
    UPDATE_LOCATION,
    UPDATE_FROMYEAR,
    UPDATE_TOYEAR,
    ReindeerStatistics,
    RECEIVE_REFUNDSEARCHRESULTREINDEER,
    UPDATE_LOCATIONREINDEER, NumberListItem, UPDATE_TOYEAR_REIN, UPDATE_FROMYEAR_REIN,
} from './types';
import {ReindeerLocation} from "../../model/area/reindeerLocation";

export function updateLocation(result: any): RefundFetchActionTypes {
    return {
        type: UPDATE_LOCATION,
        location: result,
    };
}

export function updateLocationReindeer(result: ReindeerLocation): RefundFetchActionTypes {
    return {
        type: UPDATE_LOCATIONREINDEER,
        location: result,
    };
}

export function updateFromYear(result: NumberListItem): AnyAction {
    return {
        type: UPDATE_FROMYEAR,
        fromYear: result,
    };
}

export function updateToYear(result: NumberListItem): AnyAction {
    return {
        type: UPDATE_TOYEAR,
        toYear: result,
    };
}
export function updateFromYearRein(result: NumberListItem): AnyAction {
    return {
        type: UPDATE_FROMYEAR_REIN,
        fromYearRein: result,
    };
}
export function updateToYearRein(result: NumberListItem): AnyAction {
    return {
        type: UPDATE_TOYEAR_REIN,
        toYearRein: result,
    };
}
export function requestResults(): RefundFetchActionTypes {
    return {
        type: REQUEST_REFUNDLISTDATA,
    };
}

export function receiveResults(result: RefundDataListdata): RefundFetchActionTypes {
    return {
        type: RECEIVE_REFUNDLISTDATA,
        result: result,
    };
}

export function receiveSearchResultSheep(result: SheepStatistics): RefundFetchActionTypes {
    return {
        type: RECEIVE_REFUNDSEARCHRESULTSHEEP,
        result: result,
    };
}

export function receiveSearchResultReindeer(result: ReindeerStatistics): RefundFetchActionTypes {
    return {
        type: RECEIVE_REFUNDSEARCHRESULTREINDEER,
        result: result,
    };
}

export function setIsFetching(isFetching: boolean): isLoadingAction {
    return {
        type: SET_ISFETCHING,
        isFetching,
    };
}

export function fetchSearchResultSheep(): ThunkAction<void, ApplicationState, null, Action<string>> {
    return async function(dispatch: Dispatch, getState): Promise<any> {
        dispatch(requestResults()); // isFetching = true;
        const { refundFilter } = getState().refund;
        const refundSearchfilter = {
            ...refundFilter,
            FromYear: refundFilter.FromYear && refundFilter.FromYear.value,
            ToYear: refundFilter.ToYear && refundFilter.ToYear.value,
        };
        const data = await statisticService.getStatisticResultSheep(refundSearchfilter);
        dispatch(receiveSearchResultSheep(data));
    };
}

export function fetchSearchResultReindeer(): ThunkAction<void, ApplicationState, null, Action<string>> {
    return async function(dispatch: Dispatch, getState): Promise<any> {
        dispatch(requestResults()); // isFetching = true;
        const { refundFilter } = getState().refund;
        const refundSearchfilter = {
            ...refundFilter,
            FromYear: refundFilter.FromYearRein && refundFilter.FromYearRein.value,
            ToYear: refundFilter.ToYearRein && refundFilter.ToYearRein.value,
        };
        const data = await statisticService.getStatisticResultReindeer(refundSearchfilter);
        
        data && dispatch(receiveSearchResultReindeer(data));
    };
}

export function fetchRefundData(): ThunkAction<void, ApplicationState, null, Action<string>> {
    return async function(dispatch: Dispatch): Promise<any> {
        dispatch(requestResults()); // isFetching = true;
        const data = await statisticService.getStatisticData();
        return dispatch(receiveResults(data));
    };
}

