import React, {ReactElement} from "react";
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";
import {getCoordinates, getCoordinateType} from "./tooltip.util";
import {useLanguage} from "../../i18n";
import classnames from "classnames";
import {makeStyles, Theme} from "@material-ui/core";
import {createStyles} from "@material-ui/styles";
import './tooltip.scss'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        borderTop: {
            borderTop: `1px solid ${theme.palette.grey[200]}`,
        },
        borderBottom: {
            borderBottom: `1px solid ${theme.palette.grey[200]}`
        },
    })
);

export function StedfestingInfo(): ReactElement {
    const stedfesting = useSelector((state: ApplicationState) => state.tooltip.stedfesting);
    const site = useSelector((state: ApplicationState) => state.settings.site);
    const coordinates = getCoordinates(stedfesting.wkt, site);
    const l = useLanguage();
    const classes = useStyles({});
    const stedfestingIDClass = classnames(classes.borderTop, classes.borderBottom, "stedfesting-info-pair");
    const stedfestingCoordinateClass = classnames(classes.borderBottom, "stedfesting-info-pair");
    return (
        <div className="stedfesting-info">
            <div className={stedfestingIDClass}>
                <span>ID</span> {stedfesting.id}
            </div>
            <div className={stedfestingCoordinateClass}>
                <span>{l('tooltip.north')} {getCoordinateType(site)}</span>
                {coordinates[1].toFixed(0)}
            </div>
            <div className={stedfestingCoordinateClass}>
                <span>{l('tooltip.east')} {getCoordinateType(site)}</span>
                {coordinates[0].toFixed(0)}
            </div>
        </div>
    )
}