import {useMediaQuery} from '@material-ui/core';

export function useMobileBreakpointInnsyn(): boolean {
    return useMediaQuery('(max-width:599px)');
}

// sender inn option noSsr:true (som betyr ingen serverside rendring)
// dette fikset problem hvor hooken først returnerer false, og deretter true
export function useMobileBreakpointPortraitInnsyn(): boolean {
    return useMediaQuery('(max-width:599px) and (orientation: portrait)', {noSsr: true});
}

// sender inn option noSsr:true (som betyr ingen serverside rendring)
// dette fikset problem hvor hooken først returnerer false, og deretter true
export function useMobileBreakpointLandscapeInnsyn(): boolean {
    return useMediaQuery('(max-height:599px) and (orientation: landscape)', {noSsr: true});
}

export function useTabletBreakpointInnsyn(): boolean {
    return useMediaQuery('(max-width:959px)');
}

export function useDesktopBreakpointInnsyn(): boolean {
    return useMediaQuery('(min-width:1450px)');
}