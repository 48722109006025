import React from 'react';
import DropdownSelector from '../dropdownSelector/dropdownSelector';
import { NumberListItem } from '../../store/refund/types';
import { useSelector } from 'react-redux';
import { ApplicationState, useActionCreator } from '../../store';
import {updateFromYear, updateFromYearRein, updateToYear, updateToYearRein} from '../../store/refund/action';
import { RefundAnimal } from '../../constants/refundAnimal';

import isNil from 'ramda/es/isNil';
import './yearSelector.scss';
import { useLanguage } from '../../i18n';

type Props = {
    animal: RefundAnimal;
};

export default function YearSelector(props: Props): JSX.Element {
    const l = useLanguage();
    const isSheep = props.animal === RefundAnimal.Sheep;
    const listData = useSelector((state: ApplicationState) => state.refund.listData);
    const fromYear = useSelector((state: ApplicationState) => state.refund.refundFilter.FromYear);
    const toYear = useSelector((state: ApplicationState) => state.refund.refundFilter.ToYear);
    const fromYearRein = useSelector((state: ApplicationState) => state.refund.refundFilter.FromYearRein);
    const toYearRein = useSelector((state: ApplicationState) => state.refund.refundFilter.ToYearRein);
    const setFromYearFilter = useActionCreator(updateFromYear);
    const setToYearFilter = useActionCreator(updateToYear);
    const setFromYearReinFilter = useActionCreator(updateFromYearRein);
    const setToYearReinFilter = useActionCreator(updateToYearRein);
    const yearsList = isSheep ? listData.sauerstatningÅr : listData.reinerstatningÅr;

    function onFromYearChange(year: NumberListItem): void {
        isSheep ? setFromYearFilter(year) : setFromYearReinFilter(year);
    }
    function onToYearChange(year: NumberListItem): void {
        isSheep ? setToYearFilter(year) : setToYearReinFilter(year);
    }

    return (
        <div className="year-selector-wrapper">
            <DropdownSelector
                selectedValue={isSheep ? fromYear : fromYearRein}
                disabledValue={isNil(toYear) ? undefined : ( isSheep ? toYear.value : toYearRein.value )}
                disabledAbove={true}
                onChange={onFromYearChange}
                selectionList={yearsList}
                header={l('compensation.from')}
            />
            <DropdownSelector
                selectedValue={isSheep ? toYear : toYearRein}
                disabledValue={isNil(fromYear) || isNil(fromYearRein) ? undefined : ( isSheep ? fromYear.value : fromYearRein.value )}
                onChange={onToYearChange}
                selectionList={yearsList}
                header={l('compensation.to')}
            />
        </div>
    );
}
