import React, {ReactElement} from "react";
import classnames from 'classnames'
import './watermark.scss';

type WatermarkProps = {
    className?: string;
}

export function Watermark(props: WatermarkProps): ReactElement {
    const combinedClassName = classnames("watermark", props.className);
    const testSite = window.location.origin.includes('test');
    
    if (testSite) { 
        return (
            <div className={combinedClassName}>
                TEST
            </div>
        )
    } else {
        return null;
    };
}

export function WatermarkMapLayer(): ReactElement {
    const testSite = window.location.origin.includes('test');
    if (testSite) {
        return (
            <div className={"watermark-map-layer"}>
                <div>TEST</div>
            </div>
        )
    } else {
        return null;
    };
}