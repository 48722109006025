import React from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {useLanguage} from '../../i18n';
import {ApplicationState} from '../../store';
import {Logo, LogoMdirText} from '../logo/logo';
import './footer.scss';
import {Site} from '../../store/settings/types';
import {mdirInfo, naturvardsInfo, stripPhoneNumber} from '../../constants/contactInfo';
import {ExternalLink} from '../externalLInk/externalLink';

export function Footer(): JSX.Element {
    const l = useLanguage();
    const site = useSelector((state: ApplicationState) => state.settings.site);
    const language = useSelector((state: ApplicationState) => state.settings.language);
    const contactInfo = site === Site.NO ? mdirInfo : naturvardsInfo;

    return (
        <footer className="footer">
            <div className="footer-logo-wrapper">
                {site === Site.NO ? <LogoMdirText language={language}/> : <Logo site={site} language={language} width={60} alt={l('contact.Naturvardsverket')} />}
            </div>
            <div className="footer-content-wrapper">
                <div className="footer-link">
                    <ExternalLink href={'https://www.miljodirektoratet.no/personvernerklaring/'}>{l('menu.privacyPolicy')}</ExternalLink>
                </div>
                <div className="footer-link">
                    <NavLink to="/tilgjengelighet" activeClassName="selected" accessKey="0">
                        {l('menu.accessibilityStatement')}
                    </NavLink>
                </div>
                <div className="footer-link">
                    <NavLink to="/kontakt" activeClassName="selected" accessKey="7">
                        {l('menu.contact')}
                    </NavLink>
                </div>
                <div className="footer-link">
                    <a href={`mailto:${contactInfo.mail}`}>{`${l('menu.email')}:  ${contactInfo.mail}`}</a>
                </div>
                <div className="footer-link">
                    <a href={`tel:${stripPhoneNumber(contactInfo.phone)}`}>
                        {`${l('menu.phone')}: ${contactInfo.phone}`}
                    </a>
                </div>
            </div>
        </footer>
    );
}