import React, {ReactElement} from "react";
import {useLanguage} from "../../i18n";
import {GroupedFilterLists} from "../filterbar/filterLists";
import {ApplicationState} from "../../store";
import {containsAll} from "../searchfilter/searchfilter.utils";
import {dnaObservations} from "../../constants/observations";
import {useSelector} from "react-redux";
import {ExpansionPanel, ExpansionPanelContent, ExpansionPanelTitle} from "../expansionPanel/expansionPanel";
import {CheckboxList} from "../checkboxList/checkboxList";
import {Evaluation} from "../../constants/evaluation";
import {getStringFromEnumNumber} from "../tooltip/tooltip.util";

export function EvaluationFilter(): ReactElement {
    const l = useLanguage();
    const { registrationEvaluation } = GroupedFilterLists();
    const searchFilter = useSelector((state: ApplicationState) => state.searchFilter.searchFilter);
    const evaluationFilterNeeded = searchFilter.CarnivoreDamage.length > 0 || !containsAll(dnaObservations, searchFilter.Observation);
    const evaluation = searchFilter.Evaluation;
    
    let title = '';
    if (evaluation.length > 0) {
        evaluation.sort((a, b) => a - b)
            .map((val, key) => {
            if (title === '') {
                title = l(`evaluation.title.${getStringFromEnumNumber(Evaluation, val)}`);
            } else if (key === evaluation.length -1 ) {
                title = title.concat(` & ${l(`evaluation.title.${getStringFromEnumNumber(Evaluation, val)}`).toLowerCase()}`);
            }
            else {
                title = title.concat(`, ${l(`evaluation.title.${getStringFromEnumNumber(Evaluation, val)}`).toLowerCase()}`);
            }
        });
    } else {
        title = l('evaluation.title.noneChecked');
    }
    
    return (
        <>
            {evaluationFilterNeeded &&
                <div id="evaluation-filter">
                    <h3>{l('filterbar.evaluation')}</h3>
                    <ExpansionPanel className="searchfilter-expansion-panel">
                        <ExpansionPanelTitle>{title}</ExpansionPanelTitle>
                        <ExpansionPanelContent>
                           <CheckboxList checkboxList={registrationEvaluation} />
                        </ExpansionPanelContent>
                    </ExpansionPanel>
                </div>
            }
        </>
    )
}
