import React, {useState} from 'react';
import {makeStyles, createStyles, Theme, InputBase} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import {useLanguage} from '../../i18n';
import {useActionCreator, ApplicationState} from '../../store';
import {useSelector} from 'react-redux';
import {SetSearchinput} from '../../store/searchfilter/action';
import {SearchSuggestion, suggestionSearch} from '../searchbar/suggestionService';
import './searchbarInput.scss';
import {IconButton} from '../themeButtton/themeButton';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        searchbar: {
            border: `1px solid ${theme.palette.grey[400]}`,
            backgroundColor: theme.palette.common.white,
            
            '&:focus-within': {
                outline: `1px solid ${  theme.palette.primary.light}`,
                outlineOffset: '2px'
            }
        },
        input: {
            textOverflow: 'ellipsis',
            '&::placeholder': {
                opacity: 0.8,
            },      
        },
        iconButton: {
            padding: 8,
            [theme.breakpoints.up('sm')]: {
                padding: 10,
            },
        },
    })
);

type OwnProps = {
    searchText: string;
    onSearch: (text: string) => void;
    setSuggestions: (suggestions: SearchSuggestion[]) => void;
    suggestions: SearchSuggestion[];
    clearSearch: () => void;
    handleSuggestionClick: (suggestion: SearchSuggestion, e: React.MouseEvent) => void;
    placeholderText: string;
    label: string;
};

export function SearchbarInput(props: OwnProps): JSX.Element {
    const searchText = useSelector((state: ApplicationState) => state.searchFilter.searchinput);
    const setSearchText = useActionCreator(SetSearchinput);
    const [showSuggestions, setShowSuggestions] = useState<boolean>();
    const l = useLanguage();
    const classes = useStyles({});
    const searchbarClass = classnames(classes.searchbar, 'searchbar');
    const maxLengthInput = 200;

    function handleSearchTextUpdate(text: string): void {
        
        // Serveren har en makslengde på URL'er som støttes, og vi ser at noen brukere møter denne grensen.
        // Dette fører til en exception. Ved å begrense lengden på input-feltet unngår vi dette.
        
        // kutter søkefeltet etter maxLengthInput
        const inputTextTruncated = text.substring(0, maxLengthInput);
        // oppdaterer søkefeltet med den avkuttede teksten
        setSearchText(inputTextTruncated);
        
        if (text.length > 1) { // Søker etter individ når man har skrevet minst 2 bokstaver.
            suggestionSearch(inputTextTruncated).then(result => {
                props.setSuggestions(result);
                setShowSuggestions(result.length > 0);
            });
        } else {
            setShowSuggestions(false);
        }
    }

    function handleOnEnter(event): void {
        if (event.keyCode === 13) {
            props.onSearch(searchText);
        }
    }

    return (
        <div className={searchbarClass} role={'search'}>
            <IconButton
                tabIndex={-1}
                className={classes.iconButton}
                onClick={() => props.onSearch(searchText)}

                aria-label={l('ariaLabel.search')}
            >
                <SearchIcon/>
            </IconButton>
            <InputBase
                value={searchText}
                id="searchbar"
                className={'input-wrapper'}
                placeholder={props.placeholderText}
                onKeyDown={handleOnEnter}
                onFocus={() => setShowSuggestions(true)}
                onChange={e => handleSearchTextUpdate(e.target.value)}
                inputProps={{className: classes.input, 'aria-label': props.label}}
            />
            {searchText !== '' && (
                <IconButton onClick={props.clearSearch} className={classes.iconButton} aria-label={l('uu.clear')}>
                    <ClearIcon/>
                </IconButton>
            )}

            {showSuggestions && searchText !== '' && (
                <div className="searchbar__suggestionBox">
                    {props.suggestions.map((suggestion, i) => (
                        <button
                            key={i}
                            className="searchbar__suggestionItem"
                            onClick={e => props.handleSuggestionClick(suggestion, e)}
                        >
                            <span>{`${suggestion.id} ${suggestion.tekst}`}</span>
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
}
