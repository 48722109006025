import {
    Table,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core';
import React, { Fragment } from 'react';
import { DamageCause } from '../../constants/damageCause';
import { sheepCompensationDamageCause } from '../../constants/statistics';
import { useLanguage } from '../../i18n';
import { getStringFromEnum } from '../tooltip/tooltip.util';
import {ExpansionPanel, ExpansionPanelContent, ExpansionPanelTitle} from '../expansionPanel/expansionPanel';
import './damageCauseTable.scss';

type Props = {
    apiData: sheepCompensationDamageCause[];
};

export default function DamageCauseTableSmall(props: Props): JSX.Element {
    const l = useLanguage();
    return (
        <Fragment>
            {props.apiData.map((row, key) => (
                <ExpansionPanel key={key} theme="dark">
                    <ExpansionPanelTitle theme="dark">
                            {row.artID === 'Totalt'
                                ? 'Totalt'
                                : l(`carnivore.${getStringFromEnum(DamageCause, row.artID)}`)}
                    </ExpansionPanelTitle>
                    <ExpansionPanelContent>
                        <div>
                            <Table size="small" className="damage-cause-table-small">
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            {l('refund.compensatedSheep')}
                                        </TableCell>
                                        <TableCell align="right" >
                                            {row.compensatedSheep.toLocaleString()}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            {l('refund.compensatedLamb')}
                                        </TableCell>
                                        <TableCell align="right" >
                                            {row.compensatedLamb.toLocaleString()}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            {l('refund.compensatedTotal')}
                                        </TableCell>
                                        <TableCell align="right" >
                                            {row.compensatedTotal.toLocaleString()}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            {l('refund.compensatedPercent')}
                                        </TableCell>
                                        <TableCell align="right" >
                                            {row.percent} %
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </div>
                    </ExpansionPanelContent>
                </ExpansionPanel>
            ))}
        </Fragment>
    );
}
