import {isEmpty} from 'ramda';
import * as React from 'react';
import {useSelector} from 'react-redux';
import CompensationSelector from '../components/compensationSelector/compensationSelector';
import DamageCauseTableSheep from '../components/damageCauseTable/damageCauseTable';
import {LoadingSpinner} from '../components/loader/loader';
import StatisticalInfoBox from '../components/statisticalInfoBox/statisticalInfoBox';
import StatisticalInfoYears from '../components/statisticalInfoYears/statisticalInfoYears';
import StatisticalTableSheep from '../components/statisticalTable/statisticalTableSheep';
import {createLabel} from '../components/yearpicker/yearperiodpicker';
import {RefundAnimal} from '../constants/refundAnimal';
import {useLanguage} from '../i18n';
import {ApplicationState, useActionCreator} from '../store';
import {fetchRefundData, fetchSearchResultSheep} from '../store/refund/action';
import {AreaSearchTypes} from '../constants/areasearchTypes';
import StaticPageFrame from '../components/staticPageFrame/staticPageFrame';
import {ErrorBoundary} from '../components/errorBoundary/errorBoundary';
import {Suspense} from 'react';
import {LocationType} from '../constants/locationType';
import {Area} from '../model/area/area';
import {Country} from '../constants/country';
import {HoppTilHovedinnhold} from "../components/themeButtton/themeButton";
import {NumberListItem} from "../store/refund/types";

function toYearSheep(year: number): NumberListItem {
    return { value: year, label: year.toString() };
}

export function RefundSheep(): JSX.Element {
    const animal = RefundAnimal.Sheep;
    const getListData = useActionCreator(fetchRefundData);
    const getDataSheep = useActionCreator(fetchSearchResultSheep);
    const isFetching = useSelector((state: ApplicationState) => state.refund.isFetching);
    const filter = useSelector((state: ApplicationState) => state.refund.refundFilter);
    const l = useLanguage();
    const [location, setLocation] = React.useState<Area[]>([
        { value: '1', label: l('country.norway'), locationType: LocationType.Country, countryID: Country.Norway },
    ]);
    const header = createHeader();
    const fromYear = filter.FromYear;
    const toYear = filter.ToYear;
    const locationPlaceholder = l('searchableSelect.locationSearchInputPlaceholder');
    const areaSearchType: AreaSearchTypes = AreaSearchTypes.norway;
    const multipleYears = fromYear && fromYear.value !== toYear.value;
    
    React.useEffect(() => {
        getListData();
    }, []);
  
    React.useEffect(() => {
        getDataSheep();
    }, [filter, animal]);

    function createHeader(): string {
        let year = fromYear
            ? createLabel(fromYear, toYear)
            : '';
        let locationString = isEmpty(location) ? l('country.norway') : location[0].label;
        return `${locationString} ${year}`;
    }
    
    return (
        <StaticPageFrame>
            <HoppTilHovedinnhold mainContentId={"locationSearchField"} />
            <h2>
                {l('compensation.header')}
                {l('carnivoreDamage.sheep').toLowerCase()}
            </h2>
            
            <Suspense fallback={<div>Loading...</div>}>
                <CompensationSelector
                    animal={animal}
                    location={location}
                    onLocationChange={setLocation}
                    locationPlaceholder={locationPlaceholder}
                    areaSearchType={areaSearchType}
                />
            </Suspense>

            {/* Grafer */}
            {isFetching ? (
                <div className="compensation-loader">
                    <LoadingSpinner />
                </div>
            ) : (
                <ErrorBoundary>
                    <StatisticalInfoBox animal={animal} header={header} period={isEmpty(fromYear) && isEmpty(toYear)} />
                    {multipleYears && (
                        <StatisticalInfoYears fromYear={fromYear.label} toYear={toYear.label} animal={animal} />
                    )}
                    <StatisticalTableSheep />
                    <DamageCauseTableSheep />
                </ErrorBoundary>
            )}
        </StaticPageFrame>
    );
}
