import React, {ReactElement, ReactNode} from "react";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {useLanguage} from "../../i18n";

type LinkProps = {
    href: string;
    children?: ReactNode;
}

export function ExternalLink (props: LinkProps): ReactElement {
    const l = useLanguage();
    return (
        <a href={props.href}
           target="_blank"
           rel="noopener"
           title={props.href}
        >
            {props.children}
                <OpenInNewIcon
                    role="img"
                    className="open-in-new"
                    aria-label={l('uu.openNewWindow')}
                    titleAccess={l('uu.openNewWindow')}
                />
        </a>
    )
}