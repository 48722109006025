import React from 'react';
import StaticPageFrame from '../components/staticPageFrame/staticPageFrame';
import { mdirInfo, naturvardsInfo, stripPhoneNumber } from '../constants/contactInfo';
import { useLanguage } from '../i18n';
import { ErrorBoundary } from '../components/errorBoundary/errorBoundary';
import {ExternalLink} from '../components/externalLInk/externalLink';
import {ExpansionPanel, ExpansionPanelContent, ExpansionPanelTitle} from '../components/expansionPanel/expansionPanel';
import {HoppTilHovedinnhold} from "../components/themeButtton/themeButton";

export function ContactMiljodir(): JSX.Element {
    const l = useLanguage();
    return (
        <div className="contactinfo__text">
            <div className="contactinfo__name">Miljødirektoratet</div>
            <div className="contactinfo__visitAddress">
                <label>{l('contact.visitAddress')}: </label>
                <span>{mdirInfo.visitAdress}</span>
            </div>
            <div className="contactinfo__mailAddress">
                <label>{l('contact.postAddress')}: </label>
                <span>{mdirInfo.postAdress}</span>
            </div>
            <div className="contactinfo__email">
                <label>{l('menu.email')}: </label>
                <a href={`mailto:${mdirInfo.mail}`}>{mdirInfo.mail}</a>
            </div>
            <div className="contactinfo__phone">
                <label>{l('menu.phone')}: </label>
                <a href={`tel:${stripPhoneNumber(mdirInfo.phone)}`}>{mdirInfo.phone}</a>
            </div>
        </div>
    );
}

export function ContactNaturvard(): JSX.Element {
    const l = useLanguage();
    return (
        <div className="contactinfo__text">
            <div className="contactinfo__name">Naturvårdsverket</div>
            <div className="contactinfo__visitAddress">
                <label>{l('contact.visitAddress')} Stockholm: </label>
                <span>{naturvardsInfo.visitAdress[0]}</span>
            </div>
            <div className="contactinfo__visitAddress">
                <label>{l('contact.visitAddress')} Östersund: </label>
                <span>{naturvardsInfo.visitAdress[1]}</span>
            </div>
            <div className="contactinfo__mailAddress">
                <label>{l('contact.postAddress')}: </label>
                {naturvardsInfo.postAdress}
            </div>
            <div className="contactinfo__email">
                <label>{l('menu.email')}: </label>
                <a href={`mailto:${naturvardsInfo.mail}`}>{naturvardsInfo.mail}</a>
            </div>
            <div className="contactinfo__phone">
                <label>{l('menu.phone')}: </label>
                <a href={`tel:${stripPhoneNumber(naturvardsInfo.phone)}`}>{naturvardsInfo.phone}</a>
            </div>
        </div>
    );
}

export function Contact(): JSX.Element {
    const l = useLanguage();
    return (
        <ErrorBoundary>
            <StaticPageFrame>
                <HoppTilHovedinnhold mainContentId={"mainContent"} />
                <div className={'contact-page'} id={"mainContent"}>
                    <h2>{l('menu.contact')}</h2>
                    <ExpansionPanel theme="dark">
                        <ExpansionPanelTitle theme="dark">{l('contact.panel1.header')}</ExpansionPanelTitle>
                        <ExpansionPanelContent>
                            {`${l('contact.panel1')} `}
                            <ExternalLink href={'https://www.miljodirektoratet.no/ansvarsomrader/naturoppsyn/'}>Statens naturoppsyns rovviltkontakter</ExternalLink>
                            {` (${l('country.norway')}) ${l('contact.or')} `}
                            <ExternalLink href={'https://www.lansstyrelsen.se/'}>länsstyrelsen</ExternalLink> {`(${l('country.sweden')}).`}
                        </ExpansionPanelContent>
                    </ExpansionPanel>
                    <ExpansionPanel theme="dark">
                        <ExpansionPanelTitle theme="dark">{l('contact.panel2.header')}</ExpansionPanelTitle>
                        <ExpansionPanelContent>
                            {l('contact.panel2')}
                            <ExternalLink href={'https://www.statsforvalteren.no'}>Statsforvalteren</ExternalLink>
                            {` ${l('contact.or')} `}
                            <ExternalLink href={'https://www.landbruksdirektoratet.no/no/reindriften/for-siidaandeler/melding-om-reindrift'}>Reindriftsforvaltningen</ExternalLink>
                            {` (${l('country.norway')}) ${l('contact.or')} `}
                            <ExternalLink href={'https://www.lansstyrelsen.se/'}>länsstyrelsen</ExternalLink>
                            {` (${l('country.sweden')}).`}
                        </ExpansionPanelContent>
                    </ExpansionPanel>
                   <ExpansionPanel theme="dark">
                       <ExpansionPanelTitle theme="dark">{l('contact.panel3.header')}</ExpansionPanelTitle>
                       <ExpansionPanelContent>
                           {`${l('contact.panel3-1')} `}
                           <ExternalLink href={'https://www.miljodirektoratet.no/ansvarsomrader/arter-naturtyper/vilt/rovvilt/'}>{l('contact.atMDIR')}</ExternalLink>.
                           {` ${l('contact.panel3-2')} `}
                           <ExternalLink href={'https://www.naturvardsverket.se/amnesomraden/jakt-och-vilt/viltforvaltning/rovdjursforvaltning/'}>{l('contact.atNaturvardsverket')}</ExternalLink>.
                       </ExpansionPanelContent>
                   </ExpansionPanel>
                    <p>
                        {l('contact.p3-2')} <ExternalLink href={'https://www.miljodirektoratet.no'}>{l('contact.MDIR')}</ExternalLink>
                        {` ${l('contact.or')} `}
                        <ExternalLink href={'http://www.naturvardsverket.se/'}>{l('contact.Naturvardsverket')}</ExternalLink>
                    </p>
                    <div className="contactinfo_contactPage">
                        <ContactMiljodir />
                        <ContactNaturvard />
                    </div>
                </div>
            </StaticPageFrame>
        </ErrorBoundary>
    );
}
