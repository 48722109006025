import {Individ} from "../model/stedfesting/StedfestingTypes";
import { IndividualData } from "../components/searchresultList/searchresultTitle";

export async function getIndividualData(individID: string): Promise<IndividualData> {
    const req = createRequest();
    const response = await fetch(`/api/individualData/${individID}`, req);
    if (response.ok) {
        return await response.json();
    }
}

function createRequest(): object {
    let headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
    });
    return {
        method: 'GET',
        headers: headers
    };
}
