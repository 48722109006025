import {useLanguage} from '../../i18n';
import React from 'react';
import {ErrorBoundary} from '../../components/errorBoundary/errorBoundary';
import StaticPageFrame from '../../components/staticPageFrame/staticPageFrame';
import {AccessibilityModalContent} from '../../components/accessibilityModal/accessibilityModal';
import {AccesibilityContentNO} from './accessibiltyContent-no';
import {useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {AccesibilityContentSE} from './accessibiltyContent-se';
import {Site} from '../../store/settings/types';
import {HoppTilHovedinnhold} from "../../components/themeButtton/themeButton";

export function Accessibility(): JSX.Element {
    const l = useLanguage();
    const site = useSelector((state: ApplicationState) => state.settings.site);
    return (
        <ErrorBoundary>
            <StaticPageFrame>
                <HoppTilHovedinnhold mainContentId={"mainContent"} />
                <div className="accessibility" id={"mainContent"}>
                    {site === Site.SE && <AccesibilityContentSE />}
                    {site === Site.NO && <AccesibilityContentNO />}
                    <section>
                        <h2>{l('accessibility.tips')}</h2>
                        <AccessibilityModalContent />
                    </section>                    
                </div>
            </StaticPageFrame>
        </ErrorBoundary>
    );
}
