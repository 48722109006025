import React, {useEffect, useState} from 'react';
import StaticPageFrame from '../staticPageFrame/staticPageFrame';
import {ErrorBoundary} from '../errorBoundary/errorBoundary';
import {HoppTilHovedinnhold, SecondaryButton} from '../themeButtton/themeButton';
import './reportpage.scss';
import {getReportId, getReports, ReportsResponse, useLayout} from '../../services/reportService';
import {PowerBIEmbed} from 'powerbi-client-react';
import {Embed, models, Report} from 'powerbi-client';
import {LoadingSpinner} from '../loader/loader';
import {useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {Language} from '../../constants/language';
import {Culture, getCulture} from '../../constants/culture';
import {getLanguageCode} from '../../constants/languageCode';
import {Site} from '../../store/settings/types';
import {ReportKeys} from '../../constants/reportKeys';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import {useLanguage} from '../../i18n';
import classnames from 'classnames';

const getSiteparam = (site: Site) : string => site === Site.SE ? 'se' : 'no';

const createCultureFilter = (culture:Culture): models.IBasicFilter => {
    return {
        $schema: 'https://powerbi.com/product/schema#basic',
        target: {
            table: 'Languages',
            column: 'DefaultCulture'
        },
        operator: 'In',
        values: [culture],
        filterType: models.FilterType.Basic,
        requireSingleSelection: true
    };
};

const createSiteFilter = (site:Site): models.IBasicFilter => {
    return {
        $schema: 'https://powerbi.com/product/schema#basic',
        target: {
            table: 'UserSelection',
            column: 'domain'
        },
        operator: 'In',
        values: [getSiteparam(site)],
        filterType: models.FilterType.Basic,
        requireSingleSelection: true
    };
};

function createConfig(reportId: string, embedInfo: ReportsResponse, language:Language, layout: models.LayoutType): models.IReportEmbedConfiguration {
    const report = embedInfo.reports.find(r => r.reportId === reportId);
    const culture = getCulture(language);
    const languageCode = getLanguageCode(language);

    return {
        type: 'report',
        id: report.reportId,
        embedUrl: report.embedUrl,
        accessToken: embedInfo.embedToken.token,
        tokenType: models.TokenType.Embed,
        settings: {
            localeSettings: {
                formatLocale: culture,
                language: languageCode
            },
            panes: {
                filters: {
                    expanded: false,
                    visible: false
                },
                pageNavigation: {
                    visible: false
                }
            },
            layoutType: layout
        }
    };
}

export function Reportpage(props:ReportpageProps): JSX.Element {
    const [config, setConfig] = useState<models.IReportEmbedConfiguration>();
    const [reportInstance, setReportInstance] = useState<Report>();
    const language = useSelector((state: ApplicationState) => state.settings.language);
    const site = useSelector((state: ApplicationState) => state.settings.site);
    const layout = useLayout();
    const usingMobileLayout = layout === models.LayoutType.MobilePortrait || layout === models.LayoutType.MobileLandscape;
    const staticPageFrameClass = classnames({'compact-mobile': usingMobileLayout});
        const powerBiCssClasses = classnames('report-component', {
        'mobileLayout': usingMobileLayout
    });
    const l = useLanguage();
    const {fullscreenEnabled} = document;
    const showFullscreenButton = false; // fullscreenEnabled;
    
    useEffect(() => {
        Promise
            .allSettled([getReportId(props.reportKey), getReports()])
            .then(([id, res]) => {
                if (res.status === 'fulfilled' && id.status === 'fulfilled') {
                    const reportId = id.value;
                    const embedInfo = res.value;
                    const config = createConfig(reportId, embedInfo, language, layout);
                    setConfig(config);
                }
            });
    }, [props.reportKey, language, site, layout]);
    
    const embeddedComponentHandler = (embedObject: Embed) : void => {
        const theReport = embedObject as Report;
        
        theReport.on('loaded', async () => {
            const filters = [createCultureFilter(getCulture(language)), createSiteFilter(site)];
            await theReport.updateFilters(models.FiltersOperations.Replace, filters);
        });

        theReport.on('pageChanged', async () => {
            const filters = [createCultureFilter(getCulture(language)), createSiteFilter(site)];
            await theReport.updateFilters(models.FiltersOperations.Replace, filters);
        });
        
        setReportInstance(theReport);
    };
    
    const fullScreenHandler = ():void => {
        if(fullscreenEnabled && reportInstance){
            reportInstance.fullscreen();
        }
    };
    
    return (
        <ErrorBoundary>
            <StaticPageFrame className={staticPageFrameClass}>
                <HoppTilHovedinnhold mainContentId={'mainContent'}/>
                <div className={'report-page'} id={'mainContent'}>
                    <h2>{props.reportName}</h2>
                    {props.reportDescription && <p>{props.reportDescription}</p>}
                    {config 
                        ? <div className="report-container">
                            {showFullscreenButton && <SecondaryButton className="fullscreen-button" onClick={fullScreenHandler}>
                                  <FullscreenIcon className="fullscreen-icon" aria-hidden={true}/>
                                  {l('report.fullscreen')}
                              </SecondaryButton>}
                              <PowerBIEmbed embedConfig={config}
                                            cssClassName={powerBiCssClasses}
                                            getEmbeddedComponent={embeddedComponentHandler}
                              />
                            {usingMobileLayout && <a href={'#rovbaseApp'} className="goto-top" >{l('report.toTopOfPage')}</a>}
                          </div>
                        : <LoadingSpinner/>}
                </div>
            </StaticPageFrame>
        </ErrorBoundary>
    );
}

type ReportpageProps = {
    reportKey: ReportKeys;
    reportName: string;
    reportDescription?: string;
};