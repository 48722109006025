import React, {ReactElement, useState} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import {ApplicationState, useActionCreator} from '../../store';
import {Language} from '../../constants/language';
import {useLanguage, useLanguageTemplate} from '../../i18n';
import './mobileTooltip.scss';
import {IconButton, LinkButton} from '../themeButtton/themeButton';
import CloseIcon from '@material-ui/icons/Close';
import {setTooltip} from '../../store/tooltip/actions';
import {selectStedfesting} from '../../store/map/actions';
import {getStedfestingUrl, stedfestingTitle} from '../tooltip/tooltip.util';
import {makeStyles, Theme} from '@material-ui/core';
import {createStyles} from '@material-ui/styles';
import classnames from 'classnames';
import findIndex from 'ramda/es/findIndex';
import {equals} from 'ramda';
import ArrowIosIcon from '@material-ui/icons/ArrowForwardIos';
import {Share} from '../share/share';
import {TooltipDescription} from '../tooltip/tooltipDescription';
import {StedfestingInfo} from '../tooltip/stedfestingInfo';
import {TooltipHeaderInfo} from '../tooltip/tooltipHeaderInfo';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        clusterNavigation: {
            backgroundColor: theme.palette.secondary.main,
        },
        tooltipBody: {
            borderTop: `1px solid ${theme.palette.grey[200]}`
        },
        title: {
            color: theme.palette.primary.main,
            textDecoration: 'underline'
        }
    })
);

function ClusterNavigation(): ReactElement {
    const l = useLanguage();
    const classes = useStyles({});
    const clusterNavigationClass = classnames('cluster-navigation', classes.clusterNavigation);
    const stedfesting = useSelector((state: ApplicationState) => state.tooltip.stedfesting);
    const selectedStedfestings = useSelector((state: ApplicationState) => state.map.selectedStedfesting);
    const setTooltipStedfesting = useActionCreator(setTooltip);
    const clusterSize = useSelector((state: ApplicationState) => state.map.selectedStedfesting).length;
    const index = findIndex(equals(stedfesting))(selectedStedfestings);
    const showPreviousButton = index >= 1;
    const showNextButton = index + 1 < clusterSize;

    const handlePrevious = (): void => {
        setTooltipStedfesting(selectedStedfestings[index - 1]);
    };

    const handleNext = (): void => {
        setTooltipStedfesting(selectedStedfestings[index + 1]);
    };
    

    return (
        <div className={clusterNavigationClass}>
            {showPreviousButton &&
                <LinkButton onClick={handlePrevious} className="previous-tooltip-button">
                    <ArrowIosIcon />
                    {l('tooltip.previousPost')}
                </LinkButton>
            }
           
            <div className="counter">{`${index + 1} ${l('tooltip.of')} ${clusterSize}`}</div>
            {showNextButton && 
                <LinkButton onClick={handleNext} className="next-tooltip-button" disabled={index + 1 >= clusterSize}>
                    {l('tooltip.nextPost')}
                    <ArrowIosIcon />
                </LinkButton> 
            }
        </div>
    );
}



type HeaderProps = {
    OnTitleClick: () => void,
    TooltipSize: TooltipSize
}

function TooltipHeader(props: HeaderProps): ReactElement {
    const stedfesting = useSelector((state: ApplicationState) => state.tooltip.stedfesting);
    const language: Language = useSelector((state: ApplicationState) => state.settings.language, shallowEqual);
    const l = useLanguage();
    const setTooltipStedfesting = useActionCreator(setTooltip);
    const setSelectedStedfestings = useActionCreator(selectStedfesting);
    const classes = useStyles({});
    const titleClass = classnames('tooltip-title', classes.title);


    const handleCloseTooltip = () => {
        setTooltipStedfesting(null);
        setSelectedStedfestings([]);
    };
    return (
        <div className="tooltip-header">
            {props.TooltipSize === TooltipSize.Small && 
                <button className="tooltip-content" onClick={props.OnTitleClick} type="button">
                    <span className={titleClass}>{stedfestingTitle(language, stedfesting)}</span>
                    <TooltipHeaderInfo stedfesting={stedfesting}/>
                </button>
            }
            {props.TooltipSize === TooltipSize.Large &&
            <div className="tooltip-content">
                <div className="tooltip-title">{stedfestingTitle(language, stedfesting)}</div>
                <TooltipHeaderInfo stedfesting={stedfesting}/>
            </div>
            }
            <IconButton
                className="close-button"
                onClick={handleCloseTooltip}
                aria-label={l('uu.close')}
            >
                <CloseIcon />
            </IconButton>
        </div>
    );
}

enum TooltipSize {
    Small = 0,
    Large = 1
}

export function MobileTooltip(){
    const lt = useLanguageTemplate();
    const language: Language = useSelector((state: ApplicationState) => state.settings.language, shallowEqual);
    const [tooltipSize, setTooltipSize] = useState<TooltipSize>(TooltipSize.Small);
    const stedfesting = useSelector((state: ApplicationState) => state.tooltip.stedfesting);
    const isCluster = useSelector((state: ApplicationState) => state.map.selectedStedfesting).length > 1;
    const classes = useStyles({});
    const tooltipBodyClass = classnames('tooltip-body', classes.tooltipBody);
    const mailSubject = stedfestingTitle(language, stedfesting);
    const mailLink = `mailto:?subject=Rovbase: ${mailSubject}&body=${lt('tooltip.mailBody', {
        title: mailSubject,
    })} ${getStedfestingUrl(stedfesting)}`;
    const onTitleClick = () => {
        setTooltipSize(TooltipSize.Large);  
    };

    
    return (
        <div className={tooltipSize === TooltipSize.Large ? 'mobile-tooltip large': 'mobile-tooltip'}>
            {isCluster && <ClusterNavigation />}
            <TooltipHeader OnTitleClick={onTitleClick} TooltipSize={tooltipSize}/>
            {tooltipSize === TooltipSize.Large &&
                <div className={tooltipBodyClass}>
                    <TooltipDescription/>
                    <StedfestingInfo />
                    <Share
                    copyUrl={getStedfestingUrl(stedfesting)}
                    mailLink={mailLink}
                    feedbackObject={stedfesting}
                    />
                </div>
            }
        </div>
    );
}