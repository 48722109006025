import {createTheme} from '@material-ui/core/styles';
import theme from '../styles/materialTheme';

const themeSE = createTheme({
    ...theme,
    palette: {
        ...theme.palette,
        primary: {
            main: '#3A588F',
            light: '#5393C3',
            dark: '#244582',
        },
        // primary: {
        //     main: '#244582',
        //     light: '#0064C8',
        //     dark: '#003366',
        // },
        secondary: {
            main: '#EFEFEF',
            light: '#ffffff',
            dark: '#D8D8D8',
        },
    },
});

export default themeSE;
