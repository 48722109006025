import { Paper, useTheme } from '@material-ui/core';
import React from 'react';
import Select, {GroupTypeBase} from 'react-select';
import { MenuProps } from 'react-select/src/components/Menu';
import { useLanguage } from '../../i18n';
import { NumberListItem } from '../../store/refund/types';
import './dropdownSelector.scss';

type Props = {
    selectedValue: NumberListItem;
    disabledValue?: number;
    disabledAbove?: boolean;
    onChange: (year: NumberListItem) => void;
    selectionList: NumberListItem[];
    header?: string;
};

function Menu(props: MenuProps<NumberListItem, false, GroupTypeBase<NumberListItem>>): JSX.Element {
    return (
        <Paper square className="select-year-menu" {...props.innerProps}>
            {props.children}
        </Paper>
    );
}

const components = {
    Menu,
    IndicatorSeparator: () => null,
};

export default function DropdownSelector(props: Props): JSX.Element {
    const theme = useTheme();
    const l = useLanguage();
    const colourStyles = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { isDisabled, isFocused, isSelected }) => {
            const color = theme.palette.secondary.dark;
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? null
                    : isSelected
                    ? theme.palette.secondary.main
                    : isFocused
                    ? color
                    : null,
                color: isDisabled ? '#ccc' : isSelected,
                cursor: isDisabled ? 'not-allowed' : 'default',
                fontWeight: isSelected ? 'bold' : 'normal',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled && (isSelected ? theme.palette.secondary.main : color),
                },
            };
        },
    };
    const ariaLabel = props.header ? props.header : 'select';
    const isDisabled = (value: NumberListItem): boolean => {
        return props.disabledValue && props.disabledAbove
            ? value.value > props.disabledValue
            : value.value < props.disabledValue;
    };
    const handleChange = (value: NumberListItem): void => {
        const selected = props.selectionList.find(x => x.value === value.value);
        props.onChange(selected);
    };

    return (
        <label>
            <span className="select-year-label">{props.header}</span>
            <Select
                className="select-year"
                value={props.selectedValue}
                isOptionDisabled={isDisabled}
                isClearable={false}
                isSearchable={true}
                onChange={handleChange}
                name="year"
                options={props.selectionList}
                aria-label={ariaLabel}
                // placeholder={'år'}
                styles={colourStyles}
                components={components}
                noOptionsMessage={() => l('searchableSelect.noOptionsMessage')}
            />
        </label>
    );
}
