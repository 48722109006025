import {RefundSearchFilter} from '../store/refund/types';
import {appInsight} from "../applicationInsights";

export default {
    async getStatisticData() {
        const req = createRequest();
        const response = await fetch('/api/statistics/lists', req);
        if (response.ok) {
            return await response.json();
        }
    },
    async getStatisticResultSheep(search: RefundSearchFilter) {
        const req = createSearchRequest(search);
        const response = await fetch('/api/statistics/sheep', req);
        if (response.ok) {
            appInsight?.trackEvent({name: 'SHEEP_STAT', properties: {County: search.County, Municipality: search.Municipality }});
            return await response.json();
        }
    },
    async getStatisticResultReindeer(search: RefundSearchFilter) {
        const req = createSearchRequest(search);
        const response = await fetch('/api/statistics/reindeer', req);
        if (response.ok) {
            appInsight?.trackEvent({name: 'REINDEER_STAT', properties: {Reinbeiteomrade: search.Reinbeiteomrade, Reinbeitedistrikt: search.Reinbeitedistrikt }});
            return await response.json();
        }
    }
};

function createSearchRequest(search: RefundSearchFilter): object {
    let headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
    });
    return {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(search)
    };
}

function createRequest(): object {
    let headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
    });
    return {
        method: 'GET',
        headers: headers
    };
}
