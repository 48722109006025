import {createStyles, makeStyles, Theme} from '@material-ui/core';
import classnames from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';
import {ApplicationState, useActionCreator} from '../../store';
import {OpenSearchresultlist} from '../../store/sidePanel/action';
import {MenuBar} from '../menubar/menubar';
import {Searchfilter} from '../searchfilter/searchfilter';
import SearchresultList from '../searchresultList/searchresultList';
import './sidepanel.scss';
import {useLanguage} from '../../i18n';
import {PrimaryButton, SecondaryButton} from '../themeButtton/themeButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {ErrorBoundary} from '../errorBoundary/errorBoundary';
import {useMobileBreakpointInnsyn} from '../../hooks/breakpoint';


type OwnProps = {
    isOpen: boolean;
    onClose: () => void;
    onToggleBtn: (state: boolean) => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sidepanelWrapper: {
            borderRight: `1px solid ${theme.palette.primary.main}`,
            backgroundColor: theme.palette.common.white,
        },
        buttonToggle: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.common.white,
            '&:focus': {
                outline: `2px solid ${theme.palette.primary.main}`,
                outlineOffset: '2px',
            },
        },
    })
);

function Sidepanel(props: OwnProps): JSX.Element {
    const l = useLanguage();
    const classes = useStyles({});
    const resultCount: number = useSelector((state: ApplicationState) => state.map.stedfesting.length);
    const sidepanel = props.isOpen
        ? classnames(classes.sidepanelWrapper, 'sidepanel_wrapper sidepanel_open')
        : classnames(classes.sidepanelWrapper, 'sidepanel_wrapper sidepanel_closed');
    const mobileScreen = useMobileBreakpointInnsyn();
    const resultListOpen = useSelector((state: ApplicationState) => state.sidePanelFilter.menuState.open);
    const openSearchresultList = useActionCreator(OpenSearchresultlist);
    const buttonToggleOpen = classnames(classes.buttonToggle, 'btnToggleSidepanel btnOpenSidepanel');
    const buttonToggleClosed = classnames(classes.buttonToggle, 'btnToggleSidepanel btnCloseSidepanel');
    const ariaLabelButton = props.isOpen ? l('uu.closePanel') : l('uu.openPanel');
    const searchfilterOpen = props.isOpen && !resultListOpen;
    const showDetachedToggleButton = !mobileScreen || !props.isOpen;

    const btnIcon = props.isOpen ? (
        <ArrowBackIosIcon titleAccess={`${l('uu.closePanel')} ${l('uu.icon')}`}/>
    ) : (
        <ArrowForwardIosIcon titleAccess={`${l('uu.openPanel')} ${l('uu.icon')}`}/>
    );
    return (
        <>
            <div className={sidepanel}>
                <ErrorBoundary>
                    {props.isOpen && (
                        <>
                            <MenuBar />
                            {searchfilterOpen ? (
                                <div className="sidepanel-filter-content">
                                    {!showDetachedToggleButton && (
                                        <button
                                            className={props.isOpen ? buttonToggleClosed : buttonToggleOpen}
                                            onClick={() => props.onToggleBtn(!props.isOpen)}
                                            aria-label={ariaLabelButton}
                                        >
                                            {btnIcon}
                                        </button>
                                    )}
                                    <Searchfilter />
                                    <div className="sidepanel_btngroup" id="sidepanel_btngroup">
                                        {resultCount > 0 && (
                                            <SecondaryButton onClick={() => openSearchresultList()}>
                                                {l('sidepanel.resultList')}
                                            </SecondaryButton>
                                        )}
                                        {mobileScreen && (
                                            <PrimaryButton onClick={props.onClose}>
                                                {l('sidepanel.resultMap')}
                                            </PrimaryButton>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <SearchresultList closeSidePanel={props.onClose} />
                            )}
                        </>
                    )}
                </ErrorBoundary>
            </div>
            {showDetachedToggleButton && (
                <button
                    className={props.isOpen ? buttonToggleClosed : buttonToggleOpen}
                    onClick={() => props.onToggleBtn(!props.isOpen)}
                    aria-label={ariaLabelButton}>
                    {btnIcon}
                </button>
            )}
        </>
    );
}

export default Sidepanel;
