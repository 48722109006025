export enum Carnivore {
    Wolf = 1,
    Bear = 2,
    Lynx = 3,
    Wolverine = 4,
    Eagle = 6,
    UnknownProtected = 7,
}

export const mainCarnivoreArray = [Carnivore.Wolf, Carnivore.Bear, Carnivore.Lynx, Carnivore.Wolverine];
export const otherCarnivoresArray = [Carnivore.Eagle, Carnivore.UnknownProtected];

export const allCarnivoreArray = mainCarnivoreArray.concat(otherCarnivoresArray);

// Other carnivores only affects a subset of the filter options
