import React, { Fragment } from 'react';
import Dead from '../../assets/svg/deadcarnivores/dod.svg';
import Dna from '../../assets/svg/dna/Dna.svg';
import UnanalysedDna from '../../assets/svg/dna/UnanalysedDna.svg';
import Layers from '../../assets/svg/maptool/lag.svg';
import ZoomIn from '../../assets/svg/maptool/zoominn.svg';
import ZoomOut from '../../assets/svg/maptool/zoomut.svg';
import Bed from '../../assets/svg/observasjonstyper/Bed.svg';
import Digging from '../../assets/svg/observasjonstyper/Digging.svg';
import ErroneousReport from '../../assets/svg/observasjonstyper/ErroneousReport.svg';
import Faeces from '../../assets/svg/observasjonstyper/Faeces.svg';
import Footprint from '../../assets/svg/observasjonstyper/Footprint.svg';
import HairOrFeather from '../../assets/svg/observasjonstyper/HairOrFeather.svg';
import Observation from '../../assets/svg/observasjonstyper/Observation.svg';
import Reproduction from '../../assets/svg/observasjonstyper/Reproduction.svg';
import TerritorialMarking from '../../assets/svg/observasjonstyper/TerritorialMarking.svg';
import TreeMarkings from '../../assets/svg/observasjonstyper/TreeMarkings.svg';
import VisualObservation from '../../assets/svg/observasjonstyper/VisualObservation.svg';
import Cattle from '../../assets/svg/rovviltskade/Cattle.svg';
import Dog from '../../assets/svg/rovviltskade/Dog.svg';
import Goat from '../../assets/svg/rovviltskade/Goat.svg';
import Reindeer from '../../assets/svg/rovviltskade/Reindeer.svg';
import Sheep from '../../assets/svg/rovviltskade/Sheep.svg';
import Arrow from '../../assets/svg/arrow/arrow.svg';
import { getColor } from '../components/searchresultItem/searchresultItem';
import { CarnivoreDamage } from '../constants/carnivoreDamage';
import { Observations } from '../constants/observations';
import { isDNA, isDodeRovdyr, isRovviltobservasjon, isRovviltskade } from '../model/stedfesting/Stedfesting';
import { DNA, Rovviltobservasjon, Rovviltskade, Stedfesting } from '../model/stedfesting/StedfestingTypes';
import { Evaluation } from '../constants/evaluation';

type Props = {
    stedfesting: Stedfesting;
    className?: string;
};

type IconProps = {
    type: string;
};

export function MapToolIcon(props: IconProps): JSX.Element {
    if (props.type === 'zoomin') {
        return <ZoomIn />;
    }
    if (props.type === 'zoomout') {
        return <ZoomOut />;
    }
    if (props.type === 'layers') {
        return <Layers />;
    }
}

export function IconComponent(props: Props): JSX.Element {
    const color = getColor(props.stedfesting);
    if (isRovviltobservasjon(props.stedfesting)) {
        return <RovviltobservasjonComponent {...props} />;
    }
    if (isRovviltskade(props.stedfesting)) {
        return <RovviltskadeComponent {...props} />;
    }
    if (isDodeRovdyr(props.stedfesting)) {
        return <Dead style={{ fill: color }} className={props.className} aria-hidden={true} />;
    }
    if (isDNA(props.stedfesting)) {
        return <DNAComponent {...props} />;
    }
}

function DNAComponent(props: Props): JSX.Element {
    const analysedSample = (props.stedfesting as DNA).prøvestatusID.length > 0;
    const color = getColor(props.stedfesting);
    return (
        <Fragment>
            {analysedSample && <Dna style={{ fill: color }} className={props.className} aria-hidden={true} />}
            {!analysedSample && (
                <UnanalysedDna style={{ fill: '#FFFFFF' }} className={props.className} aria-hidden={true} />
            )}
        </Fragment>
    );
}

function RovviltskadeComponent(props: Props): JSX.Element {
    const color = getColor(props.stedfesting);
    const damageType = parseInt((props.stedfesting as Rovviltskade).skadetypeID);
    switch (damageType) {
        case CarnivoreDamage.Sheep:
            return <Sheep style={{ fill: color }} className={props.className} aria-hidden={true} />;
        case CarnivoreDamage.Reindeer:
            return <Reindeer style={{ fill: color }} className={props.className} aria-hidden={true} />;
        case CarnivoreDamage.Dog:
            return <Dog style={{ fill: color }} className={props.className} aria-hidden={true} />;
        case CarnivoreDamage.Goat:
            return <Goat style={{ fill: color }} className={props.className} aria-hidden={true} />;
        case CarnivoreDamage.Cattle:
            return <Cattle style={{ fill: color }} className={props.className} aria-hidden={true} />;
       default:
            return null;
    }
}

function RovviltobservasjonComponent(props: Props): JSX.Element {
    const observations = (props.stedfesting as Rovviltobservasjon).observasjoner;
    const color = getColor(props.stedfesting);
    if (parseInt((props.stedfesting as Rovviltobservasjon).vurderingID) === Evaluation.ErroneousReport) {
        return <ErroneousReport style={{ fill: color }} className={props.className} aria-hidden={true} />;
    }
    if (observations.length > 1 || observations.length === 0) {
        return <Observation style={{ fill: color }} className={props.className} aria-hidden={true} />;
    }
    const [observationType] = observations;
    // TODO: endring av observasjonstyper
    switch (observationType) {
        case Observations.DeadCarnivores:
            return <Dead style={{ fill: color }} className={props.className} aria-hidden={true} />;
        case Observations.VisualObservation:
            return <VisualObservation style={{ fill: color }} className={props.className} aria-hidden={true} />;
        case Observations.Footprint:
            return <Footprint style={{ fill: color }} className={props.className} aria-hidden={true} />;
        case Observations.Faeces:
            return <Faeces style={{ fill: color }} className={props.className} aria-hidden={true} />;
        case Observations.TerritorialMarking:
            return <TerritorialMarking style={{ fill: color }} className={props.className} aria-hidden={true} />;
        case Observations.Reproduction:
            return <Reproduction style={{ fill: color }} className={props.className} aria-hidden={true} />;
        case Observations.TreeMarkings:
            return <TreeMarkings style={{ fill: color }} className={props.className} aria-hidden={true} />;
        case Observations.HairOrFeather:
            return <HairOrFeather style={{ fill: color }} className={props.className} aria-hidden={true} />;
        case Observations.Bed:
            return <Bed style={{ fill: color }} className={props.className} aria-hidden={true} />;
        case Observations.Digging:
            return <Digging style={{ fill: color }} className={props.className} aria-hidden={true} />;
        default:
            return <Observation style={{ fill: color }} className={props.className} aria-hidden={true} />;
    }
}

export function ArrowComponent(): JSX.Element {
    return (
        <Arrow
            style={{ fill: '#FF0000', height: '25px', transform: ' rotate(90deg)', pointerEvents: 'none' }}
            className="testing"
        />
    );
}
