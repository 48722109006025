import {useLanguage} from "../../i18n";
import {LinkButton} from "../themeButtton/themeButton";
import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core";

type NavigateProps = {
    offset: number;
    offsetStep: number;
    setOffset: (offset: number) => void;
    listLength: number;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        sidepanel: {
            backgroundColor: theme.palette.common.white,
            height: '100%',
            overflow: 'auto',
        },
        header: {
            borderColor: theme.palette.secondary.dark,
            backgroundColor: theme.palette.common.white,
        },
        closeIcon: {
            padding: '10px',
        },
        footer: {
            borderTop: `1px solid ${theme.palette.secondary.dark}`,
        },
        navigateButton: {
            textDecoration: 'underline',
        },
    })
);

export function NavigateSearchResult(props: NavigateProps): JSX.Element {
    const classes = useStyles({});
    const l = useLanguage();
    const handlePreviousItems = (): void => {
        if (props.offset > props.listLength) {
            props.setOffset(0);
        }
        if (props.offset > props.offsetStep) {
            props.setOffset(props.offset - props.offsetStep);
        }
    };
    const handleNextItems = (): void => {
        if (props.offset < props.listLength) {
            props.setOffset(props.offset + props.offsetStep);
        }
    };
    return (
        <>
        {props.listLength > props.offsetStep && (
            <div className="navigate">
                <LinkButton
                    className={classes.navigateButton}
                    onClick={() => handlePreviousItems()}
                    disabled={props.offset <= props.offsetStep}
                >
                    {l('tooltip.previousPage')}
                </LinkButton>
                <span className="page-count">{`${props.offset - (props.offsetStep - 1)} - ${Math.min(
                    props.offset,
                    props.listLength
                )} ${l('searchresultList.ofTotal')} ${props.listLength}`}</span>
                <LinkButton
                    className={classes.navigateButton}
                    onClick={() => handleNextItems()}
                    disabled={props.offset > props.listLength}
                >
                    {l('tooltip.nextPage')}
                </LinkButton>
            </div>)}
        </>
    );
}
