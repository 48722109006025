import React, { PropsWithChildren, ReactChild, ReactElement, useState, HTMLAttributes, useEffect } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
import './expansionPanel.scss';

type CollapseProps = {
    children: [ReactElement, ReactChild];
    expanded?: boolean;
    onChangeExpanded?: () => void;
    theme?: 'light' | 'dark';
};

export function ExpansionPanel({
    children,
    expanded,
    className,
    onChangeExpanded,
    theme
}: CollapseProps & HTMLAttributes<HTMLDivElement>): JSX.Element {
    const [open, setOpen] = useState<boolean>(false);
    const [overflow, setOverflow] = useState<boolean>(false);

    const expansionPanelClass = classnames(className, theme, 'expansion-panel');
    const expansionPanelOpen = open ? 'panel-open' : 'panel-closed';
    useEffect(() => {
        setOpen(expanded);
    }, [expanded !== undefined && expanded !== open]);

    useEffect(() => {
        if (open) {
            setTimeout(function() {
                setOverflow(true);
            }, 300);
        }
        setOverflow(false);
    }, [open]);

    return (
        <div className={expansionPanelClass}>
            {React.cloneElement(children[0], {
                clickHandler: () => {
                    setOpen(!open);
                    onChangeExpanded && onChangeExpanded();
                },
                open: open,
            })}
            <div className={overflow ? `${expansionPanelOpen} overflow` : expansionPanelOpen}>
                <div className="panel-content">{children[1]}</div>
            </div>
        </div>
    );
}

ExpansionPanel.defaultProps = {
    theme: 'light'
}

type CollapseTitleProps = {
    clickHandler?: () => void;
    open?: boolean;
    theme?: 'light' | 'dark';

};

export const ExpansionPanelTitle = (
    props: PropsWithChildren<CollapseTitleProps> & HTMLAttributes<HTMLButtonElement>
): JSX.Element => {
    const titleClass = classnames(props.className, props.theme, 'expansion-panel-title');
    const handler = (event): void => {
        event.preventDefault();
        props.clickHandler();
    };

    return (
        <button className={titleClass} onClick={handler} aria-expanded={props.open}>
            <span>{props.children}</span>
            <span className={props.open ? 'icon expanded' : 'icon'}>
                <ExpandMoreIcon fontSize="large" />
            </span>
        </button>
    );
};

ExpansionPanelTitle.defaultProps = {
    theme: 'light'
}

export const ExpansionPanelContent = (props: PropsWithChildren<{}>): JSX.Element => {
    return <>{props.children}</>;
};