import {
    Table,
    TableBody,
    TableCell,
    TableRow
} from '@material-ui/core';
import React, { Fragment } from 'react';
import { DamageCause } from '../../constants/damageCause';
import { reindeerCompensationDamageCause } from '../../constants/statistics';
import { useLanguage } from '../../i18n';
import { getStringFromEnum } from '../tooltip/tooltip.util';
import {ExpansionPanel, ExpansionPanelContent, ExpansionPanelTitle} from '../expansionPanel/expansionPanel';

type Props = {
    apiData: reindeerCompensationDamageCause[];
};

export default function DamageCauseTableReindeerSmall(props: Props): JSX.Element {
    const l = useLanguage();
    return (
        <Fragment>
            {props.apiData.map((row, key) => (
                <ExpansionPanel key={key} theme="dark">
                    <ExpansionPanelTitle theme="dark">
                            {row.artID === 'Totalt'
                                ? 'Totalt'
                                : l(`carnivore.${getStringFromEnum(DamageCause, row.artID)}`)}
                    </ExpansionPanelTitle>
                    <ExpansionPanelContent>
                        <div>
                            <div>
                                <Table size="small" className="damage-cause-table-small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                Antall kalv erstattet
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.compensatedCalf.toLocaleString()}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Antall okserein erstattet
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.compensatedBull.toLocaleString()}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Antall simlerein erstattet
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.compensatedDoe.toLocaleString()}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Antall kjørerein erstattet
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.compensatedDriving.toLocaleString()}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Totalt antall erstattet
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.compensatedTotal.toLocaleString()}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                Artens andel av totalt tap
                                            </TableCell>
                                            <TableCell align="right">
                                                {row.percent} %
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </div>
                        </div>
                    </ExpansionPanelContent>
                </ExpansionPanel>
            ))}
        </Fragment>
    );
}
