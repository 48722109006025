import { Stedfesting } from '../../model/stedfesting/StedfestingTypes';
import { Action } from 'redux';

export type TooltipState = {
    stedfesting: Stedfesting;
};

export type SetTooltipAction = Action & {
    stedfesting: Stedfesting;
};

export const SET_TOOLTIP = '@tooltip/SET_TOOLTIP';
export const CLEAR_TOOLTIP = '@tooltip/CLEAR_TOOLTIP';
