import React, {ReactElement} from 'react';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import {useLanguage} from '../../i18n';
import './accessibilityModal.scss';
import { IconButton } from '../themeButtton/themeButton';

type ModalProps = {
    open: boolean;
    handleClose: () => void;
}

export default function AccessibilityModal(props: ModalProps) {
    const l = useLanguage();
    return (
            <Modal
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
                open={props.open}
                onClose={props.handleClose}
            >
                <div className="accessibility-modal">
                    <div className="modal-title">
                        <h2 id="modal-title">{l('accessibility.label')}</h2>
                        <IconButton onClick={() => props.handleClose()} aria-label={l('uu.close')} accessKey={'0'}>
                            <CloseIcon color="primary" titleAccess={l('uu.close')} />
                        </IconButton>
                    </div>
                    <AccessibilityModalContent />
                </div>
            </Modal>
    );
}

export function AccessibilityModalContent(): ReactElement {
    const l = useLanguage();
    return (
        <div className='accessibility-modal-content'>
            <h3>{l('accessibility.shortcuts')}</h3>
            <p>
                {l('accessibility.shortcuts.p1')}
            </p>
            <h3>{l('accessibility.command')}</h3>
            <p>
                {l('accessibility.command.p1')}
            </p>

            <div className="browsers">
                <h4>Windows</h4>
                <p>Chrome: <kbd>ALT</kbd> + <kbd>{`<${l('accessibility.shortcut')}>`}</kbd></p>
                <p>Edge: <kbd>ALT</kbd> + <kbd>{`<${l('accessibility.shortcut')}>`}</kbd></p>
                <p>Firefox: <kbd>ALT</kbd> + <kbd>SHIFT</kbd> + <kbd>{`<${l('accessibility.shortcut')}>`}</kbd></p>
            </div>

            <div className="browsers">
                <h4>Mac</h4>
                <p>Chrome: <kbd>Control</kbd> + <kbd>ALT</kbd> + <kbd>{`<${l('accessibility.shortcut')}>`}</kbd></p>
                <p>Safari: <kbd>Control</kbd> + <kbd>ALT</kbd> + <kbd>{`<${l('accessibility.shortcut')}>`}</kbd></p>
                <p>Firefox: <kbd>Control</kbd> + <kbd>ALT</kbd> + <kbd>{`<${l('accessibility.shortcut')}>`}</kbd></p>
            </div>
            <ul>
                <li>{`1 - ${l('accessibility.homepage')}`}</li>
                {/*<li>2 - </li>*/}
                <li>{`3 - ${l('menu')}`}</li>
                <li>{`4 - ${l('accessibility.search')}`}</li>
                <li>{`5 - ${l('menu.about')} (${l('accessibility.fromMenu')})`}</li>
                {/*<li>6 - </li>*/}
                <li>{`7 - ${l('menu.contact')} (${l('accessibility.fromMenu')})`}</li>
                {/*<li>8 - </li>*/}
                {/*<li>9 - </li>*/}
                <li>{`0 - ${l('accessibility.info')}`}</li>
            </ul>
        </div>
    );
}
