import React, {ReactElement, useEffect, useState} from 'react';
import TooltipInfoCircle from '../../Icons/tooltipInfoCircle.svg';
import './tooltip.scss';
import {useLanguage} from '../../i18n';
import classnames from 'classnames';

type TooltipProps = {
    tooltipForLabel: string;
    text: string;
    bubbleDirection?: 'up' |'down' | 'left' | 'right'  //default verdi er "up"
}

export function Tooltip({tooltipForLabel, text, bubbleDirection = "up"} : TooltipProps): ReactElement {
    const [visible, setVisible] = useState(false);
    const l = useLanguage();
    

    const onEsc = (e: KeyboardEvent): void =>{
        if(e.key === 'Escape'){
            setVisible(false);
        }
    };
    const onOutsideClick = (): void => setVisible(false);

    useEffect(() => {
        if(visible){
            document.addEventListener('keyup', onEsc);
            document.addEventListener('click', onOutsideClick);
        }

        return () => {
            document.removeEventListener('keyup', onEsc);
            document.removeEventListener('click', onOutsideClick);
        };
    }, [visible]);

    const bobleClasses = classnames('boble', {'boble-hidden': !visible}, bubbleDirection );
    return (
        <div className="tooltip-container" aria-live="polite" aria-atomic="true">
            <span className={bobleClasses} role="status"> 
                {visible && text}
                <i/>
            </span>
            <button type="button"
                    className="tooltip-btn"
                    onClick={() => setVisible(!visible)}
                    aria-label={classnames(l("tooltip.label"), tooltipForLabel)}>
                <TooltipInfoCircle/>
            </button>
        </div>
    );
}