import {useEffect, useState} from 'react';

export type systemvarsel = {
    overskrift: string;
    beskrivelse: string;
    forfatter: string;
    systemvarselType: number;
}

export const useSystemvarsel = () => {
    const [currentSystemvarsel, setCurrentSystemvarsel] = useState<systemvarsel>();
    useEffect(() => {
        getSystemvarsel().then(res => {
            setCurrentSystemvarsel(res);
        });
    }, []);
    
    return currentSystemvarsel;
};

export async function getSystemvarsel(): Promise<systemvarsel> {
    const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json'
    });
    const req: RequestInit = {
        method: 'GET',
        headers: headers
    };
    return fetch('/api/systemvarsel', req)
        .then(res => {
            if (res.ok)
                return res.json();
            else
                return Promise.reject(res.statusText);
        });
}
