import {useEffect, useState} from 'react';

export const useLoadingDots = (): string => {
    const [state, setState] = useState('');
    const [count, setCount] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCount(count + 1);
            setState('.'.repeat((count % 4)));
        }, 500);

        return () => clearInterval(interval);
    });

    return state;
};