import React from 'react';
import {Site} from '../../store/settings/types';
import {defaultTo} from 'ramda';
import {Language} from '../../constants/language';
import {useLanguage} from "../../i18n";

type Props = {
    site: Site;
    language: Language;
    width?: number;
    alt: string;
};

function getLogoVersion(site: Site): string {
    const mdirLogo = 'mdir_logo_M';
    const path = '/assets/svg/logo/';
    let file;
    const ifNotNil = defaultTo(mdirLogo);

    switch (site) {
        case Site.NO:
            file = mdirLogo;
            break;
        case Site.SE:
            file = 'naturvardsverket';
            break;
        default:
            file = mdirLogo;
            break;
    }

    return `${path}${ifNotNil(file)}.svg`;
}

type OwnProps = {
    language: Language;
    width?: number;
};

export function LogoMdirText(props: OwnProps): JSX.Element {
    const l = useLanguage();
    return (
        <>
            {props.language === Language.EN ?
                <img
                    src={'/assets/svg/logo/mdir_logo_en_horisontal.svg'}
                    alt={l('contact.MDIR')}
                    width={props.width || 192}
                />
            :
                <img
                    src={'/assets/svg/logo/mdir_logo_horisontal.svg'}
                    alt={l('contact.MDIR')}
                    width={props.width || 192}
                />}
        </>
            
    );
}

export function Logo(props: Props): JSX.Element {
    return (
            <img
                src={getLogoVersion(props.site)}
                alt={props.alt}
                width={props.width || 44}
                height={props.width || 44}
            />
    );
}
