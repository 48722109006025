import React, {ReactElement, Fragment} from 'react';
import { useLanguage } from '../../i18n';
import { ADD_FILTERLISTELEMENT, REMOVE_FILTERLISTELEMENT } from '../../store/searchfilter/types';
import { CheckboxWrapper } from '../checkbox/checkbox';
import { ListobjectWithHeader} from '../filterbar/filterLists';
import './checkboxList.scss';
import { LinkButton } from '../themeButtton/themeButton';
import {useActionCreator} from '../../store';
import {DeSelectAllAndSearch, SelectAllAndSearch, ToggleFilterListElementOgSok} from '../../store/searchfilter/action';

type CheckboxListProps = {
    checkboxList: ListobjectWithHeader;
    disableBoxes?: boolean;
}

export function CheckboxList(props: CheckboxListProps): ReactElement {
    const l = useLanguage();

    const selectAll = useActionCreator(SelectAllAndSearch);
    const deselectAll = useActionCreator(DeSelectAllAndSearch);
    const toggleElement = useActionCreator(ToggleFilterListElementOgSok);
    function handleToggle(id: number, selected: boolean, sort: string): void {
        toggleElement(selected ? REMOVE_FILTERLISTELEMENT : ADD_FILTERLISTELEMENT, sort, id);
    }
    return (
        <div className="expansionpanel-mobile">
            <div>
                <LinkButton
                    className={'check-all-button'}
                    onClick={() =>
                        selectAll(props.checkboxList.stateArrayName, props.checkboxList.list.map(item => item.id))
                    }
                >
                    {l('button.selectAll')}
                </LinkButton>
                <LinkButton
                    className={'check-all-button'}
                    onClick={() => deselectAll(props.checkboxList.stateArrayName)}
                >
                    {l('button.selectNone')}
                </LinkButton>
            </div>
            <div  className={props.checkboxList.list.length > 6 ? 'checkbox-content double' : 'checkbox-content'}>
                {props.checkboxList.list.map(
                    (listItem, i): JSX.Element => {
                        let selected = props.checkboxList.groupState.includes(listItem.id);
                        
                        // Disable checkboxes if the list item can be disabled and a trigger, disableBoxes, is set to true
                        const disable = props.disableBoxes && listItem.canBeDisabled;
                        return (
                            <Fragment key={i}>
                            {!disable && (
                                <CheckboxWrapper
                                    label={listItem.label}
                                    onChange={(): void =>
                                        handleToggle(listItem.id, selected, props.checkboxList.stateArrayName)
                                    }
                                    selected={selected}
                                />
                            )}
                            </Fragment>
                        );
                    }
                )}
            </div>
        </div>
    );
}
