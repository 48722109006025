import {useEffect, useState} from 'react';
import {checkReportStatus} from '../services/reportService';

export function useDodeRovdyrRapport() : boolean {
    const [toggleReportDodeRovdyr, setToggleReportDodeRovdyr] = useState<boolean>(false);    
    
    useEffect(() => {
        async function fetchReportStatus():Promise<void> {
            const status = await checkReportStatus('doderovdyr');
            setToggleReportDodeRovdyr(status ?? false);
        }
        fetchReportStatus();
    }, []);
    
    return toggleReportDodeRovdyr;
}

export function useRovviltskadeRapport() : boolean{

    const [toggleReportRovviltskade, setToggleReportRovviltskade] = useState<boolean>(false);
    useEffect(() => {
        async function fetchReportStatus():Promise<void> {
            const status = await checkReportStatus('rovviltskade');
            setToggleReportRovviltskade(status ?? false);
        }
        fetchReportStatus();
    }, []);

    return toggleReportRovviltskade;
}