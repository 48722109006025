import React, {ChangeEvent, ReactElement} from 'react';
import {ExpansionField, ExpansionFieldContent, ExpansionFieldTitle} from '../expansionField/expansionField';
import FilterLists from '../filterbar/filterLists';
import {ADD_FILTERLISTELEMENT, REMOVE_FILTERLISTELEMENT} from '../../store/searchfilter/types';
import {useSelector} from 'react-redux';
import {ApplicationState, useActionCreator} from '../../store';
import {ToggleFilterListElementOgSok} from '../../store/searchfilter/action';
import {useLanguage} from '../../i18n';
import {ToggleSwitch} from '../toggleSwitch/toggleSwitch';
import './carnivoreFilter.scss';

export function CarnivoreFilter(): ReactElement {
    const { mainCarnivore, otherCarnivore } = FilterLists();
    const carnivoreFilter = useSelector((state: ApplicationState) => state.searchFilter.searchFilter.Carnivore);
    const toggleCarnivoreElements = useActionCreator(ToggleFilterListElementOgSok);
    const l = useLanguage();
    function handleToggle(id: number, selected: boolean, sort: string): (e: ChangeEvent<HTMLInputElement>) => void {
        return (e: ChangeEvent<HTMLInputElement>) => {
            toggleCarnivoreElements(selected ? REMOVE_FILTERLISTELEMENT : ADD_FILTERLISTELEMENT, sort, id);
            e.preventDefault();
        };
    }
    
    return (
        <div className="carnivore-filter">
            <h3>{l('carnivoreFilter.carnivore')}</h3>
            <div className="main-carnivore-box" id="carnivoreFilter">
                {mainCarnivore
                    .sort((obj1, obj2) => obj1.label.localeCompare(obj2.label))
                    .map(
                        (carnivore, i): JSX.Element => {
                            const selected = carnivoreFilter.includes(carnivore.id);
                            return (
                                <div key={i} className="switch-frame">
                                    <ToggleSwitch
                                        id={`carnivore-filter-${carnivore.label}`}
                                        label={carnivore.label}
                                        checked={selected}
                                        onChange={handleToggle(carnivore.id, selected, 'Carnivore')}/>
                                </div>
                            );
                        }
                    )}

            </div>
            <ExpansionField>
                <ExpansionFieldTitle  label={[l('expandableList.showMore'), l('expandableList.showLess')]} />
                <ExpansionFieldContent  >
                    <div className="carnivore-switch-box">
                        {otherCarnivore
                            .map(
                                (carnivore, i): ReactElement => {
                                    const selected = carnivoreFilter.includes(carnivore.id);
                                    return (
                                        <div key={i} className="switch-frame">
                                            <ToggleSwitch 
                                                id={`carnivore-filter-${carnivore.label}`}
                                                label={carnivore.label}
                                                checked={selected} 
                                                onChange={handleToggle(carnivore.id, selected, 'Carnivore')}/>
                                        </div>
                                    );
                                }
                            )}
                    </div>
                </ExpansionFieldContent>
            </ExpansionField>
        </div>
    );
}
