import { useSelector } from 'react-redux';
import { Carnivore } from '../../constants/carnivore';
import { CarnivoreDamage } from '../../constants/carnivoreDamage';
import { Observations } from '../../constants/observations';
import { useLanguage } from '../../i18n';
import { ApplicationState } from '../../store';
import { Evaluation } from '../../constants/evaluation';

export type Listobject = {
    id: number;
    label: string;
    // For list items that need the possibility to be disabled
    canBeDisabled?: boolean;
}

export type ListobjectWithHeader = {
    header: string;
    list: Listobject[];
    groupState: number[];
    stateArrayName: string;
}

type filterList = {
    mainCarnivore: Listobject[];
    otherCarnivore: Listobject[];
    carnivoreDamages: Listobject[];
    evaluation: Listobject[];
    observations: Listobject[];
}

type FilterListsWithHeader = {
    carnivoreDamages: ListobjectWithHeader;
    observations: ListobjectWithHeader;
    registrationEvaluation: ListobjectWithHeader;
}

export default function FilterLists(): filterList {
    const l = useLanguage();
    const mainCarnivore = [
        {
            id: Carnivore.Wolf,
            label: l('carnivore.wolf'),
        },
        {
            id: Carnivore.Bear,
            label: l('carnivore.bear'),
        },
        {
            id: Carnivore.Lynx,
            label: l('carnivore.lynx'),
        },
        {
            id: Carnivore.Wolverine,
            label: l('carnivore.wolverine'),
        },
    ];
    const otherCarnivore = [
        {
            id: Carnivore.Eagle,
            label: l('filterlist.goldenEagle'),
        },
        {
            id: Carnivore.UnknownProtected,
            label: l('filterlist.unknownProtected'),
        },
    ];

    const carnivoreDamages = [
        { id: CarnivoreDamage.Sheep, label: l('carnivoreDamage.sheep')},
        { id: CarnivoreDamage.Reindeer, label: l('carnivoreDamage.reindeer')},
        { id: CarnivoreDamage.Dog, label: l('carnivoreDamage.dog')},
        { id: CarnivoreDamage.Goat, label: l('carnivoreDamage.goat')},
        { id: CarnivoreDamage.Cattle, label: l('carnivoreDamage.cattle')},
    ];

    const evaluation = [
        { id: Evaluation.Documented, label: l('evaluation.documented')},
        { id: Evaluation.AssessedSure, label: l('evaluation.assessedSure')},
        { id: Evaluation.Unsure, label: l('evaluation.unsure')},
        { id: Evaluation.ErroneousReport, label: l('evaluation.erroneousReport')},
    ];

    const observations = [
        { id: Observations.DeadCarnivores, label: l('observationsFilterList.deadCarnivores')},
        { id: Observations.VisualObservation, label: l('observationsFilterList.visualObservation')},
        { id: Observations.Footprint, label: l('observationsFilterList.footprint')},
        { id: Observations.AnalysedDna, label: l('observationsFilterList.analysedDna'), canBeDisabled: true },
        { id: Observations.UnanalysedDna, label: l('observationsFilterList.unanalysedDna'), canBeDisabled: true },
    ];

    return {
        mainCarnivore,
        otherCarnivore,
        carnivoreDamages,
        evaluation: evaluation,
        observations,
    };
}

export function GroupedFilterLists(): FilterListsWithHeader {
    const searchFilterState = useSelector((state: ApplicationState) => state.searchFilter.searchFilter);
    const filterList = FilterLists();
    const l = useLanguage();

    const observation: ListobjectWithHeader = {
        header: l('filterbar.observation'),
        list: filterList.observations,
        groupState: searchFilterState.Observation,
        stateArrayName: 'Observation',
    };

    const damagesAfterCarnivore: ListobjectWithHeader = {
        header: l('filterbar.carnivoreDamage'),
        list: filterList.carnivoreDamages,
        groupState: searchFilterState.CarnivoreDamage,
        stateArrayName: 'CarnivoreDamage',
    };

    const evaluation: ListobjectWithHeader = {
        header: l('filterbar.documented'),
        list: filterList.evaluation,
        groupState: searchFilterState.Evaluation,
        stateArrayName: 'Evaluation',
    };
    const registrationEvaluation = evaluation;
    const observations = observation;
    const carnivoreDamages = damagesAfterCarnivore;

    return {
        carnivoreDamages,
        observations,
        registrationEvaluation,
    };
}
