// import React from 'react';
// import './yearperiodpicker.css';
// import Select from '@material-ui/core/Select';
// import MenuItem from '@material-ui/core/MenuItem';
// import { createStyles, Theme, useTheme } from '@material-ui/core';
// import { makeStyles } from '@material-ui/styles';
import { NumberListItem } from '../../store/refund/types';

// type Props = {
//     fromYear: NumberListItem;
//     toYear: NumberListItem;
//     onFromChange: (value: number, label: string) => void;
//     onToChange: (value: number, label: string) => void;
//     yearsList: NumberListItem[];
// };

export function createLabel(fromYear: NumberListItem, toYear: NumberListItem): string {
    if (!fromYear) {
        if (!toYear) {
            return '';
        }
        return toYear.label;
    }
    if (!toYear) {
        return fromYear.label;
    }
    if (fromYear.value === toYear.value) {
        return fromYear.label;
    } else {
        return `${fromYear.label} - ${toYear.label}`;
    }
}
// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             width: '50px',
//             border: `0.2px solid ${theme.palette.background.paper}`,
//         },
//         dropdownStyle: {
//             maxHeight: 200,
//         },
//     })
// );

// export default function Yearperiodpicker(props: Props): JSX.Element {
//     const classes = useStyles({});

//     function handleFromYearChange(event: React.ChangeEvent<{ name?: string; value: number }>): void {
//         if (event.target.value <= props.toYear.value) {
//             const { label } = props.yearsList.find(x => x.value === event.target.value);
//             props.onFromChange(event.target.value, label);
//         }
//     }
//     function handleToYearChange(event: React.ChangeEvent<{ name?: string; value: number }>): void {
//         if (event.target.value >= props.fromYear.value) {
//             const { label } = props.yearsList.find(x => x.value === event.target.value);
//             props.onToChange(event.target.value, label);
//         }
//     }
//     const label = createLabel(props.fromYear, props.toYear);

//     return (
//         <div>
//             {/* <Inputbase value={label}> */}
//             <Select
//                 native
//                 classes={{ root: classes.root }}
//                 MenuProps={{ classes: { paper: classes.dropdownStyle } }}
//                 value={props.fromYear.value}
//                 onChange={handleFromYearChange}
//             >
//                 {props.yearsList !== undefined
//                     ? props.yearsList.map((opt, i) => (
//                           <MenuItem key={i} value={opt.value} disabled={opt.value > props.toYear.value}>
//                               {' '}
//                               {opt.label}{' '}
//                           </MenuItem>
//                       ))
//                     : null}
//             </Select>
//             {/* </Inputbase> */}

//             {/* <Select classes={{ root: classes.root }} MenuProps={{ classes: { paper: classes.dropdownStyle } }} value={props.toYear.value} onChange={handleToYearChange}>
//                 {props.yearsList !== undefined
//                     ? props.yearsList.map((opt, i) => (
//                         <MenuItem key={i} value={opt.value} disabled={opt.value < props.fromYear.value}>
//                             {' '}
//                             {opt.label}{' '}
//                         </MenuItem>
//                     ))
//                     : null}
//             </Select> */}
//         </div>
//     );
// }
