import toPairs from 'ramda/es/toPairs';
import values from 'ramda/es/values';
import { Reducer } from 'redux';
import { store } from '..';
import { layers } from '../../constants/layers';
import { transparentEffect } from '../../helpers';
import { withBaatToken } from '../../map/layerLoader';
import { mapDispatch } from '../../map/map';
import { addLayersToMap, toggleMapLayers } from '../../map/mapActions';
import { Layer, isVisible, LayerGroup } from '../../model/layer/layer';
import { setLayers, toLayerCollection } from './actions';
import {
    CLEAR_SELECTED,
    HoveredStedfestingAction,
    HOVER_STEDFESTING,
    INIT_LAYERS,
    LayerAction,
    LayerGroupToggleAction,
    MapState,
    REQUEST_GKTOKEN,
    SelectedStedfestingAction,
    SELECT_STEDFESTING,
    SET_STEDFESTING,
    StedfestingAction,
    TOGGLE_LAYER_GROUP, 
    KARTLAGFEILER, 
    SetKartlagFeilerAction,
} from './types';
import {curry, identity} from 'ramda';

const initialState: MapState = {
    layers: {},
    stedfesting: [],
    selectedStedfesting: [],
    hoveredStedfesting: null,
    errorLoadingKartlag: ""
};

const mapLayers = localStorage.mapLayers || [];
let storedLayers: string[] = [];
try {
    storedLayers = JSON.parse(mapLayers);
} catch (e) {
    localStorage.clear();
}
const layerInSelectedLayersGroup = (selectedArray: Layer[], layer: Layer): boolean =>
    selectedArray[0].group && layer.group && selectedArray[0].group === layer.group;

const toggleLayerVisibility = (layer: Layer): void =>
    (layer.options = {
        ...layer.options,
        visible: !layer.options.visible,
    });
const disableLayerVisibility = (layer: Layer): void => {
    layer.options = {
        ...layer.options,
        visible: false,
    };
};

export const mapReducer: Reducer<MapState> = (state = initialState, action): MapState => {
    switch (action.type) {
        case REQUEST_GKTOKEN: {
            getGKToken();
            return state;
        }

        case INIT_LAYERS: {
            const { layers } = action as LayerAction;
            const layersArray: Layer[] = toPairs(layers).map(([name, layer]) => ({
                ...layer,
                options: {
                    ...layer.options,
                    visible: storedLayers.length > 0 ? storedLayers.includes(name) : layer.options.visible,
                },
            }));
            mapDispatch(addLayersToMap(layersArray));
            const layerCollection = toLayerCollection(layersArray);

            return {
                ...state,
                layers: layerCollection,
            };
        }
        case TOGGLE_LAYER_GROUP: {
            const { layers, active } = action as LayerGroupToggleAction;

            const setVisible = curry(
                (visible: boolean, layer: Layer): Layer => ({
                    ...layer,
                    options: {
                        ...layer.options,
                        visible,
                    },
                })
            );

            const hasGroup = values(layers).some(layer => layer.group in LayerGroup);

            const groups = values(layers).map(layer => layer.group).filter(identity);
            
            const layersInGroup = hasGroup
                ? toLayerCollection(
                      values(state.layers)
                          .filter(layer => groups.includes(layer.group))
                          .map(setVisible(false))
                  )
                : {};
            // Alle endrede kartlag oppdateres med riktig visible-verdi
            const changedLayers = toLayerCollection(values(layers).map<Layer>(setVisible(active)));

            // Oppretter ny MapState
            const newState: MapState = {
                ...state,
                layers: {
                    ...state.layers,
                    ...layersInGroup,
                    ...changedLayers,
                },
            };

            // Varsler kartet om hvilke kartlag som nå skal være aktiv
            const visibleLayerIds = values(newState.layers)
                .filter(isVisible)
                .map(layer => layer.id);
            mapDispatch(toggleMapLayers(visibleLayerIds));

            return newState;
        }

        case SET_STEDFESTING: {
            const { stedfestings } = action as StedfestingAction;
            return {
                ...state,
                stedfesting: stedfestings,
            };
        }

        case SELECT_STEDFESTING: {
            const { selectedStedfesting } = action as SelectedStedfestingAction;
            return {
                ...state,
                selectedStedfesting: selectedStedfesting,
            };
        }

        case HOVER_STEDFESTING: {
            const { hoveredStedfesting } = action as HoveredStedfestingAction;
            return {
                ...state,
                hoveredStedfesting: hoveredStedfesting,
            };
        }

        case CLEAR_SELECTED: {
            return {
                ...state,
                selectedStedfesting: [],
            };
        }
        
        case KARTLAGFEILER: {
            const { kartlagnavn } = action as SetKartlagFeilerAction;
            return {
                ...state,
                errorLoadingKartlag: kartlagnavn
            }
        }
        
        default: {
            return state;
        }
    }
};
const setToken = (token: string): LayerAction => store.dispatch(setLayers(withBaatToken(layers, token)));

function getGKToken(): void {
    fetch('/api/gktoken', { headers: { Accept: 'text/html' } })
        .then(result => result.text())
        .then(text => text.replace(/"/g, ''))
        .then(transparentEffect(setToken))
        .catch(err => {
            throw Error(err);
        });
}
