import { MenuActionType, MenuState } from './types';
import { AnyAction, Reducer } from 'redux';

const initialState: MenuState = {
    open: false
};

const reducer: Reducer<MenuState> = (state = initialState, action: AnyAction): MenuState => {
    switch (action.type) {
        case MenuActionType.OPEN:
            return {
                ...state,
                open: true
            };
        case MenuActionType.CLOSE:
            return {
                ...state,
                open: false
            };
        case MenuActionType.TOGGLE:
            return {
                ...state,
                open: !state.open
            };
        default:
            return state;
    }
};

export { reducer as menuReducer };
