import {useLanguage, useLanguageTemplate} from '../../i18n';
import {Evaluation} from '../../constants/evaluation';
import {IncidentArea} from '../../constants/incidentArea';
import React from 'react';
import {getStringFromEnum, notNullOrEmpty} from './tooltip.util';
import {Rovviltskade} from '../../model/stedfesting/StedfestingTypes';
import './tooltip.scss';
import {useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {DamageState} from '../../constants/damageState';
import {CarnivoreDamageInspectedBy} from '../../constants/carnivoreDamageInspectedBy';

function useIntro(stedfesting: Rovviltskade) {
    const lt = useLanguageTemplate();
    const l = useLanguage();
    
    const damageState = l(`damageState.${getStringFromEnum(DamageState, stedfesting.tilstandID)}`).toLowerCase();
    const inspectedBy = l(`inspectedBy.${getStringFromEnum(CarnivoreDamageInspectedBy, stedfesting.undersoktAvID)}`);
    
    return lt('tooltip.damage', {
        object: l('tooltip.animalDamaged'),
        damageState: damageState,
        inspectedBy: inspectedBy,
        documentation: l(`evaluation.${getStringFromEnum(Evaluation, stedfesting.vurderingID)}`).toLowerCase(),
    });
    
    
}

function useArea(stedfesting: Rovviltskade){
    const lt = useLanguageTemplate();
    const l = useLanguage();
    const incidentArea = l(`incidentArea.${getStringFromEnum(IncidentArea, stedfesting.skadestedID)}`).toLowerCase();
    
    return notNullOrEmpty(stedfesting.skadestedID) &&
        lt('tooltip.damage.area', {
            area: incidentArea
        });
}

export function RovviltskadeTooltipDescription(): JSX.Element {
    const stedfesting = useSelector((state: ApplicationState) => state.tooltip.stedfesting) as Rovviltskade;
    
    const intro = useIntro(stedfesting);
    const area = useArea(stedfesting);
        
    return (
        <div className="tooltip-description">
            {intro}
            {area}
        </div>
    );
}
