import {createTheme} from '@material-ui/core/styles';
const theme = createTheme({
    typography: {
        fontFamily: 'Palatino',
        button: {
            textTransform: 'none',
        },
    },
    palette: {
        primary: {
            main: '#4C6E6E', // Mellomgrønn
            light: '#79A8A8', // Grønn
            dark: '#325553', // Mørkegrønn
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#EBF3F3', // Lysegrønn
            light: '#FFFFFF',
            dark: '#D7E6E6', // Grågrønn
            contrastText: '#3E3E3E',
        },
        common: {
            white: '#ffffff',
            black: '#3E3E3E',
        },
        background: {
            default: '#f5f5f5',
            paper: '#ffffff',
        },
        grey: {
            100: '#f5f5f5', // light grey color (border, background etc)
            200: '#efefef', // button secondary
            300: '#d8d8d8',
            400: '#c3c3c3',
            500: '#b0afaf', // dark border color
            600: '#979797',
            700: '#707070', // Mørkegrå (også farge til ukjent fredet)
            900: '#3E3E3E',
        },
        warning: {
            main: '#F3D5CF',
        },
        text: {
            primary: '#3E3E3E',
            secondary: '#3E3E3E',
            disabled: '#707070',
        },
        stroke: {
            selectedCluster: '#EF7D00',
        },
        action: {
            active: '#C9D3D3', // Fokus
        },

        // Animals
        bear: {
            main: '#784212',
            focus: '#572f0d',
        },
        wolf: {
            main: '#cb4335',
            focus: '#802b21',
        },
        lynx: {
            main: '#00a677',
            focus: '#008c65',
        },
        wolverine: {
            main: '#2582a0',
            focus: '#164e60',
        },
        raccoonDog: {
            main: '#404040',
            focus: '#000000',
        },
        goldenEagle: {
            main: '#76448a',
            focus: '#4a2a57',
        },
        unknownProtected: {
            main: '#707070',
            focus: '#505050',
        },
        dna: {
            main: '#ffffff',
            border: '#707070',
            symbol: '#353535',
        },
        unselected: {
            main: '#EFEFEF',
            color: '#8f8f8f',
            text: '#8f8f8f',
        },
    },
    overrides: {},
    spacing: 6,
});

export default theme;
