import { Reducer } from 'redux';
import {
    ADD_FILTERLISTELEMENT,
    ADD_LOCATIONLISTELEMENT,
    CLEAR_LOCATIONLISTELEMENTS,
    DESELECT_ALL_IN_LIST,
    Filter,
    REMOVE_FILTERLISTELEMENT,
    REMOVE_LOCATIONLISTELEMENT,
    SearchFilterState,
    SELECT_ALL_IN_LIST,
    SET_SEARCHINPUT,
    UPDATE,
    UPDATE_BOTH_DATES,
    UPDATE_FILTERELEMENT,
    UPDATE_PARTIAL,
} from './types';
import {defaultObservationsFilter} from '../../constants/observations';
import {defaultEvaluationFilter} from '../../constants/evaluation';
import {defaultCarnivoreDamageFilter} from '../../constants/carnivoreDamage';
import {dateToISOString} from '../../helpers';
import {subtractDays} from '../../components/dateFilter/dateFilter.util';

const today = dateToISOString(new Date()); 
const lastThirty = subtractDays(today, 30);

export const initialSearchFilters: Filter = {
    Carnivore: [],
    CarnivoreDamage:  defaultCarnivoreDamageFilter,
    Evaluation: defaultEvaluationFilter,
    Observation: defaultObservationsFilter,
    Offspring: false,
    FromDate: lastThirty,
    ToDate: today,
    Country: [],
    Region: [],
    County: [],
    Municipality: [],
    IndividualNameOrID: '',
    Barcode: [],
    Rovdjursforum: false,
    ID: [],
};

const initialState: SearchFilterState = {
    searchFilter: initialSearchFilters,
    searchinput: '',
};

const reducer: Reducer<SearchFilterState> = (state = initialState, action): SearchFilterState => {
    switch (action.type) {
        case UPDATE:
            return {
                ...state,
                searchFilter: action.searchFilter,
            };

        case UPDATE_PARTIAL:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    ...action.searchFilterPartial,
                },
            };

        case ADD_FILTERLISTELEMENT:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    [action.filterArrayName]: state.searchFilter[action.filterArrayName].concat(action.id),
                },
            };
        case REMOVE_FILTERLISTELEMENT:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    [action.filterArrayName]: state.searchFilter[action.filterArrayName].filter(
                        item => item !== action.id
                    ),
                },
            };

        case ADD_LOCATIONLISTELEMENT:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    [action.filterArrayName]: state.searchFilter[action.filterArrayName].concat(action.stringId),
                },
            };
        case REMOVE_LOCATIONLISTELEMENT:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    [action.filterArrayName]: state.searchFilter[action.filterArrayName].filter(
                        item => item !== action.stringId
                    ),
                },
            };

        case CLEAR_LOCATIONLISTELEMENTS:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    Country: [],
                    County: [],
                    Region: [],
                    Municipality: [],
                },
            };

        case UPDATE_FILTERELEMENT:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    [action.fieldName]: action.payload,
                },
            };
        case UPDATE_BOTH_DATES:
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    FromDate: action.fromDate,
                    ToDate: action.toDate,
                },
            };
        case SELECT_ALL_IN_LIST: {
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    [action.arrayName]: action.array,
                },
            };
        }

        case DESELECT_ALL_IN_LIST: {
            return {
                ...state,
                searchFilter: {
                    ...state.searchFilter,
                    [action.arrayName]: [],
                },
            };
        }

        case SET_SEARCHINPUT: {
            return {
                ...state,
                searchinput: action.searchinput,
            };
        }
        default: {
            return state;
        }
    }
};

export { reducer as searchFilterReducer };
