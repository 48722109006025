import {Subscription} from 'rxjs';
import {Layer} from '../model/layer/layer';
import {toFeature} from '../model/stedfesting/Stedfesting';
import {Stedfesting} from '../model/stedfesting/StedfestingTypes';
import {createLayer, isPromiseOfLayer, isResolvedBaseLayer, setTileLoadeError} from './layerLoader';
import {olMap, onMapAction, pointSource} from './map';
import {MapActionType} from './mapActions';
import {boundingExtent, getCenter} from 'ol/extent';
import VectorLayer from 'ol/layer/Vector';
import TileLayer from 'ol/layer/Tile';
import {OSM} from 'ol/source';

export const initMapListeners = (): Subscription[] => {
    return [
        onMapAction(MapActionType.ADD_FEATURES).subscribe((stedfestings: Stedfesting[]): void => {
            pointSource.addFeatures(stedfestings.map(toFeature));
        }),

        onMapAction(MapActionType.CLEAR_FEATURES).subscribe(() => {
            pointSource.clear();
        }),

        onMapAction<Layer[]>(MapActionType.ADD_LAYERS).subscribe((layers: Layer[]): void => {
            const layerArray = olMap.getLayers().getArray();
            
            layers
                // Har midlertidig problemer med svenske kartlag, fjerner derfor disse (og erstatter med osmlayer)
                .filter(layer => !layer.id.includes('sverige'))
                .map(createLayer)
                .filter(isResolvedBaseLayer)
                .filter(layer => !layerArray.some(l => l.get('layerName') === layer.get('layerName')))
                .forEach(layer =>olMap.addLayer(layer));
            
            layers
                // Har midlertidig problemer med svenske kartlag, fjerner derfor disse (og erstatter med osmlayer)
                .filter(layer => !layer.id.includes('sverige'))
                .map(createLayer)
                .filter(isPromiseOfLayer)

                .forEach(layer =>
                    layer.then(layer => {
                        if (!layerArray.some(l => l.get('layerName') === layer.get('layerName'))) {
                            olMap.addLayer(layer);
                            setTileLoadeError(layer);
                        }
                    })
                );
           
           // Har midlertidig problemer med svenske kartlag, fjerner derfor disse (og erstatter med osmlayer)
            const osmlayer = new TileLayer({
                source: new OSM()
            });
            osmlayer.set('layerName', 'osm');

            olMap.addLayer(osmlayer);
        }),

        onMapAction<string[]>(MapActionType.TOGGLE_LAYER).subscribe((visibleLayers: string[]): void => {
            const olLayers = olMap
                .getLayers()
                .getArray()
                .filter(layer => !(layer instanceof VectorLayer) || layer.get('layerName') === 'samebygrenser');  

            // Layers som skal slås av
            const disable = olLayers
                // OSM-laget er ikke en del av de vanlige kartlagene, og finnes derfor ikke i visibleLayers. Filtrerer ut denne først så den ikke blir disablet
                .filter(layer => layer.get('layerName') !== 'osm')
                // .filter(layer => layer.getVisible() && !visibleLayers.includes(layer.get('layerName')));
            disable.forEach(layer => layer.setVisible(false));

            // Layers som skal slås på
            const enable = olLayers.filter(
                layer => !layer.getVisible() && visibleLayers.includes(layer.get('layerName'))
            );
            enable.forEach(layer => {layer.setVisible(true);});
        }),

        onMapAction<string>(MapActionType.ZOOM_TO_FEATURE).subscribe((id: string): void => {
            const feature = pointSource.getFeatures().find(f => f.get('stedfesting').id === id);
            // @ts-ignore
            const featureCoord = feature.getGeometry().getCoordinates();
            //  olMap.getView().setCenter(featureCoord);
            olMap.getView().cancelAnimations();
            olMap.getView().animate({
                zoom: 8,
                center: featureCoord,
            });
        }),

        onMapAction<string>(MapActionType.ZOOM_TO_FEATURE_MOBILE).subscribe((id: string): void => {
            const feature = pointSource.getFeatures().find(f => f.get('stedfesting').id === id);
            // @ts-ignore
            const featureCoord = feature.getGeometry().getCoordinates();
            //  olMap.getView().setCenter(featureCoord);
            const extent = boundingExtent([featureCoord]);
            olMap.getView().cancelAnimations();
            olMap.getView().fit(extent, {
                padding: [100, 100, 400, 100],
                duration: 1500,

                maxZoom: 8,
            });
        }),

        onMapAction(MapActionType.ZOOM_TO_FEATURES).subscribe((): void => {
            const features = pointSource.getFeatures();
            if (features.length === 1) {
                // @ts-ignore
                const coordinates = features.map(feature => feature.getGeometry().getCoordinates());
                const center = getCenter(boundingExtent(coordinates));
                olMap.getView().cancelAnimations();
                olMap.getView().animate({
                    zoom: 8,
                    center: center,
                });
            } else if (features.length > 1) {
                // @ts-ignore
                const coordinates = features.map(feature => feature.getGeometry().getCoordinates());
                const extent = boundingExtent(coordinates);
                olMap.getView().cancelAnimations();
                olMap.getView().fit(extent, {
                    padding: [100, 100, 100, 100],
                    duration: 1500,
                    maxZoom: 8,
                });
            }
        }),

        onMapAction(MapActionType.ZOOM_TO_FEATURES_MOBILE).subscribe((): void => {
            const features = pointSource.getFeatures();
            if (features.length === 1) {
                // @ts-ignore
                const coordinates = features.map(feature => feature.getGeometry().getCoordinates());
                const extent = boundingExtent(coordinates);
                olMap.getView().cancelAnimations();
                olMap.getView().fit(extent, {
                    padding: [60, 10, 400, 10],
                    duration: 1500,
                    maxZoom: 8,
                });
            } else if (features.length > 1) {
                // @ts-ignore
                const coordinates = features.map(feature => feature.getGeometry().getCoordinates());
                const extent = boundingExtent(coordinates);
                olMap.getView().cancelAnimations();
                olMap.getView().fit(extent, {
                    padding: [60, 20, 200, 20],
                    duration: 1500,
                    maxZoom: 8,
                });
            }
        }),
    ];
};
