import React from 'react';
import {
    ComposedChart,
    CartesianGrid,
    XAxis,
    YAxis,
    Area,
    Line,
    Legend,
    Tooltip,
    AreaChart,
    ResponsiveContainer,
} from 'recharts';
import { RefundAnimal } from '../../constants/refundAnimal';
import { useTheme, createStyles, Theme } from '@material-ui/core';
import { toYearReindeer } from '../../pages/refundReindeer';
import { makeStyles } from '@material-ui/styles';
import {alpha} from '@material-ui/core/styles';
import { useLanguage } from '../../i18n';
import { CustomizedLegendBarChart } from '../statisticalInfoBox/statisticalInfoBox';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tooltipWrapper: {
            backgroundColor: alpha(theme.palette.common.white, 0.95),
            padding: '4px 6px 4px 10px',
            lineHeight: '0.7',
            minWidth: '150px',
            border: `1px solid ${theme.palette.grey[100]}`,
        },
        tooltipLabel: {
            fontWeight: 'bold',
        },
        tooltipRow: {
            display: 'flex',
            justifyContent: 'space-between',
            gridTemplateColumns: '150px 1fr',
        },
        tooltipRowNOK: {
            display: 'flex',
            flexDirection: 'column',
        },
        span: {
            paddingRight: '10px',
        },
    })
);

function amountYAxizTick(props: any): JSX.Element {
    const { x, y, payload } = props;
    return (
        <g aria-hidden={true}>
            <text x={x} y={y} dy={5} textAnchor="end" fill="#33333">
                {payload.value.toLocaleString()}
            </text>
        </g>
    );
}

function amountNOKYAxizTick(props: any): JSX.Element {
    const { x, y, payload } = props;
    return (
        <g aria-hidden={true}>
            <text x={x} y={y} dy={5} textAnchor="end" fill="#33333">
                {payload.value.toLocaleString()} kr
            </text>
        </g>
    );
}

function yearXAxizTick(props: any): JSX.Element {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`} aria-hidden={true}>
            <text x={0} y={0} dy={16} textAnchor="end" fill="#333333" transform="rotate(-35)">
                {toYearReindeer(parseInt(payload.value)).label}
            </text>
        </g>
    );
}

function TooltipNOK(props: any): JSX.Element {
    const classes = useStyles({});
    const { active } = props;
    if (active) {
        const { payload, label, animal } = props;
        return (
            <div className={classes.tooltipWrapper}>
                {animal === RefundAnimal.Sheep ? (
                    <p className={classes.tooltipLabel}>{`${label}`}</p>
                ) : (
                    <p className={classes.tooltipLabel}>{`${toYearReindeer(parseInt(label)).label}`}</p>
                )}

                {payload.map((row, i) => (
                    <p className={classes.tooltipRowNOK} key={i}>
                        <span className={classes.span}>{`${row.name}   `}</span>
                        <br />
                        <span>{`${row.value.toLocaleString()} kr`} </span>
                    </p>
                ))}
            </div>
        );
    }
    return null;
}

export function TooltipYears(props: any): JSX.Element {
    const classes = useStyles({});
    const { active } = props;
    if (active) {
        const { payload, label, animal } = props;
        return (
            <div className={classes.tooltipWrapper}>
                {animal === RefundAnimal.Sheep ? (
                    <p className={classes.tooltipLabel}>{`${label}`}</p>
                ) : (
                    <p className={classes.tooltipLabel}>{`${toYearReindeer(parseInt(label)).label}`}</p>
                )}

                {payload &&
                    payload.map((row, i) => (
                        <p className={classes.tooltipRow} key={i}>
                            <span className={classes.span}>{`${row.name}   `}</span>
                            <br />
                            <span>{`${row.value.toLocaleString()}`} </span>
                        </p>
                    ))}
            </div>
        );
    }
    return null;
}

type ChartProps = {
    apiData: any;
    width: number | string;
    height: number;
};

export function SheepCompensationChart(props: ChartProps): JSX.Element {
    const l = useLanguage();
    const theme = useTheme();
    return (
        <ResponsiveContainer width={props.width} height={props.height}>
            <ComposedChart
                data={props.apiData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                aria-label={l('refund.graphicalImage')}
            >
                <CartesianGrid vertical={false} strokeDasharray="3 3" fill="white" aria-hidden={true} />
                <XAxis dataKey={'year'} height={50} tickLine={false} aria-hidden={true} />
                <YAxis
                    type="number"
                    interval="preserveStart"
                    minTickGap={10}
                    tickLine={false}
                    tick={amountYAxizTick}
                    width={100}
                    aria-hidden={true}
                />
                <Area
                    stackId="a"
                    dataKey={'normalLossTotal'}
                    stroke={theme.palette.primary.light}
                    fill={theme.palette.primary.light}
                    fillOpacity="1"
                    legendType="square"
                    name={l('refund.normalLoss')}
                    isAnimationActive={false}
                />
                <Area
                    stackId="a"
                    dataKey={'compensatedTotal'}
                    stroke={theme.palette.primary.main}
                    fill={theme.palette.primary.main}
                    legendType="square"
                    fillOpacity="0.9"
                    name={l('refund.totalCompensated')}
                    isAnimationActive={false}
                />
                <Line
                    type="monotone"
                    dataKey={'totalLossTotal'}
                    stroke="#86660E"
                    fill="#86660E"
                    name={l('refund.totalLoss')}
                    isAnimationActive={false}
                />

                <Tooltip content={<TooltipYears animal={RefundAnimal.Sheep} />} />

                <Legend
                    payload={[
                        {
                            value: l('refund.normalLoss'),
                            type: 'rect',
                            id: '1',
                            color: theme.palette.primary.light,
                        },
                        {
                            value: l('refund.totalCompensated'),
                            type: 'rect',
                            id: '2',
                            color: theme.palette.primary.main,
                        },
                        {
                            value: l('refund.totalLoss'),
                            type: 'line',
                            id: '3',
                            color: '#86660E',
                        },
                    ]}
                    content={<CustomizedLegendBarChart />}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
}

export function ReindeerCompensationChart(props: ChartProps): JSX.Element {
    const l = useLanguage();
    const theme = useTheme();
    return (
        <ResponsiveContainer width={props.width} height={props.height}>
            <ComposedChart
                data={props.apiData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                aria-label={l('refund.graphicalImage')}
            >
                <CartesianGrid vertical={false} strokeDasharray="3 3" fill="white" aria-hidden={true} />
                <XAxis dataKey={'year'} height={80} tick={yearXAxizTick} />
                <YAxis type="number" interval="preserveStart" minTickGap={10} tickLine={false} tick={amountYAxizTick} />
                <Area
                    stackId="a"
                    dataKey={'totalLossTotal'}
                    stroke={theme.palette.primary.light}
                    fill={theme.palette.primary.light}
                    fillOpacity="0.7"
                    legendType="square"
                    name={l('refund.totalLoss')}
                    isAnimationActive={false}
                />
                <Area
                    stackId="c"
                    dataKey={'appliedLossTotal'}
                    stroke={theme.palette.primary.main}
                    fill={theme.palette.primary.main}
                    fillOpacity="0.7"
                    legendType="square"
                    name={l('refund.appliedLoss')}
                    isAnimationActive={false}
                />
                <Area
                    stackId="b"
                    dataKey={'compensatedTotal'}
                    stroke={theme.palette.primary.dark}
                    fill={theme.palette.primary.dark}
                    legendType="square"
                    fillOpacity="0.7"
                    name={l('refund.totalCompensated')}
                    isAnimationActive={false}
                />
                <Tooltip content={<TooltipYears animal={RefundAnimal.Reindeer} />} />

                <Legend
                    payload={[
                        {
                            value: l('refund.totalCompensated'),
                            type: 'rect',
                            id: '1',
                            color: theme.palette.primary.dark,
                        },
                        {
                            value: l('refund.appliedLoss'),
                            type: 'rect',
                            id: '2',
                            color: theme.palette.primary.main,
                        },
                        {
                            value: l('refund.totalLoss'),
                            type: 'rect',
                            id: '3',
                            color: theme.palette.primary.light,
                        },
                    ]}
                    content={<CustomizedLegendBarChart />}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );
}

type OwnProps = {
    header: string;
    animal: RefundAnimal;
};

type CompensationAmountProps = ChartProps & OwnProps;

export function CompensationAmountChart(props: CompensationAmountProps): JSX.Element {
    const l = useLanguage();
    const theme = useTheme();
    return (
        <>
            <div className="header">{props.header}</div>
            <ResponsiveContainer width={props.width} height={props.height}>
                <AreaChart
                    data={props.apiData}
                    margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                    aria-label={`${l('refund.graphicalImage')} ${props.header}`}
                >
                    <CartesianGrid vertical={false} strokeDasharray="3 3" fill="white" aria-hidden={true} />
                    {props.animal === RefundAnimal.Reindeer ? (
                        <XAxis
                            dataKey={'year'}
                            padding={{ left: 30, right: 30 }}
                            tick={yearXAxizTick}
                            height={80}
                            aria-hidden={true}
                        />
                    ) : (
                        <XAxis
                            dataKey={'year'}
                            padding={{ left: 30, right: 30 }}
                            height={40}
                            tickLine={false}
                            aria-hidden={true}
                        />
                    )}

                    <YAxis width={100} tick={amountNOKYAxizTick} tickLine={false} aria-hidden={true} />
                    <Area
                        dataKey={'compensationAmount'}
                        stroke={theme.palette.primary.main}
                        fill={theme.palette.primary.main}
                        fillOpacity="1"
                        legendType="square"
                        name={l('refund.compensationAmount')}
                        isAnimationActive={false}
                    />
                    <Legend
                        payload={[
                            {
                                value: l('refund.compensationAmount'),
                                type: 'rect',
                                id: '1',
                                color: theme.palette.primary.main,
                            },
                        ]}
                        content={<CustomizedLegendBarChart />}
                    />
                    <Tooltip content={<TooltipNOK animal={props.animal} />} />
                </AreaChart>
            </ResponsiveContainer>
        </>
    );
}
