import { Action } from 'redux';

// Objekt som sendes til backend -> Skal være lik 'SearchFilter' modellen i backend
export type Filter = {
    Carnivore: number[];
    CarnivoreDamage: number[];
    Evaluation: number[];
    Observation: number[];
    Offspring: boolean;
    FromDate: string;
    ToDate: string;
    Country: string[];
    Region: string[];
    County: string[];
    Municipality: string[];
    IndividualNameOrID: string;
    Barcode: string[];
    Rovdjursforum: boolean;
    ID: string[];
}

// Hvordan state skal se ut i store
export type SearchFilterState = {
    searchFilter: Readonly<Filter>;
    searchinput: string;
}

type Update = {
    searchFilter: Filter;
}

export type ToggleRovdyrAction = {
    type: string;
    filterArrayName: string;
    id: number;
}

export type ToggleLocationAction = {
    type: string;
    filterArrayName: string;
    stringId: string;
}

export type ClearLocationAction = {
    type: string;
}

export type UpdateFilterElementAction = {
    type: string;
    fieldName: string;
    payload: any;
}

export type UpdateBothDatesAction = {
    type: string;
    fromDate: string;
    toDate: string;
}

export type ToggleAllInListAction = {
    type: string;
    arrayName: string;
    array?: number[];
}

export type SetSearchinputAction = {
    type: string;
    searchinput: string;
}

export type UpdateAction = Update & Action
export type UpdatePartialAction = { searchFilterPartial: Partial<Filter> } & Action

// Hvilke action types vi skal ha
export const UPDATE = '@searchFilter/UPDATE';
export const UPDATE_PARTIAL = '@searchFilter/UPDATE_PARTIAL';

export const ADD_FILTERLISTELEMENT = '@searchFilter/ADD_FILTERLISTELEMENT';
export const REMOVE_FILTERLISTELEMENT = '@searchFilter/REMOVE_FILTERLISTELEMENT';
export const ADD_LOCATIONLISTELEMENT = '@searchFilter/ADD_LOCATIONLISTELEMENT';
export const REMOVE_LOCATIONLISTELEMENT = '@searchFilter/REMOVE_LOCATIONLISTELEMENT';
export const CLEAR_LOCATIONLISTELEMENTS = '@searchFilter/CLEAR_LOCATIONLISTELEMENTS';
export const UPDATE_FILTERELEMENT = '@searchFilter/UPDATE_FILTERELEMENT';
export const UPDATE_BOTH_DATES = '@searchFilter/UPDATE_BOTH_DATES';
export const SELECT_ALL_IN_LIST = '@searchFilter/SELECT_ALL_IN_LIST';
export const DESELECT_ALL_IN_LIST = '@searchFilter/DESELECT_ALL_IN_LIST';
export const SET_SEARCHINPUT = '@searchFilter/SET_SEARCHINPUT';
