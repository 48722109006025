import React, { useEffect, useState } from 'react';
import { Reportpage } from '../../components/reportpage/reportpage';
import { useLanguage } from '../../i18n';
import {useDodeRovdyrRapport} from '../../hooks/featureToggles';
export function ReportDodeRovdyr(): JSX.Element {
    const l = useLanguage();
    const showReportDodeRovdyr = useDodeRovdyrRapport();

    if (!showReportDodeRovdyr) {
        return null;
    }

    return (
        <Reportpage reportKey={'doderovdyr'} reportName={l('menu.reportDodeRovdyr')} reportDescription={l('deadCarnivore.report.description')} />
    );
}