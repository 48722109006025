import { Action, AnyAction } from 'redux';
import { mapDispatch } from '../../map/map';
import { addFeatures, clearFeatures } from '../../map/mapActions';
import { Layer, LayerCollection } from '../../model/layer/layer';
import { Stedfesting } from '../../model/stedfesting/StedfestingTypes';
import {
    INIT_LAYERS,
    LayerAction,
    LayerGroupToggleAction,
    LayerToggleAction,
    REQUEST_GKTOKEN,
    SelectedStedfestingAction,
    SELECT_STEDFESTING,
    SET_STEDFESTING,
    StedfestingAction,
    TOGGLE_LAYER,
    TOGGLE_LAYER_GROUP,
    HoveredStedfestingAction,
    HOVER_STEDFESTING,
    CLEAR_SELECTED,
    KARTLAGFEILER
} from './types';
import fromPairs from 'ramda/es/fromPairs';

export function requestGKToken(): Action {
    return {
        type: REQUEST_GKTOKEN,
    };
}

export const toLayerCollection = (layers: Layer[]): LayerCollection =>
    fromPairs(layers.map(layer => [layer.id, layer]));

export function setLayers(layers: Layer[]): LayerAction {
    const layerMap = toLayerCollection(layers);
    return {
        type: INIT_LAYERS,
        layers: layerMap,
    };
}

export function toggleLayer(layer: Layer): LayerToggleAction {
    return {
        type: TOGGLE_LAYER,
        layer,
    };
}

export function toggleLayerGroup(layerGroup: LayerCollection, active: boolean): LayerGroupToggleAction {
    return {
        type: TOGGLE_LAYER_GROUP,
        layers: layerGroup,
        active,
    };
}

export function setStedfesting(stedfestings: Stedfesting[]): StedfestingAction {
    mapDispatch(clearFeatures());
    mapDispatch(addFeatures(stedfestings));
    return {
        type: SET_STEDFESTING,
        stedfestings,
    };
}

export function selectStedfesting(selectedStedfesting: Stedfesting[]): SelectedStedfestingAction {
    return {
        type: SELECT_STEDFESTING,
        selectedStedfesting,
    };
}

export function hoverStedfesting(hoveredStedfesting: Stedfesting): HoveredStedfestingAction {
    return {
        type: HOVER_STEDFESTING,
        hoveredStedfesting,
    };
}

export function clearSelected(): Action {
    return {
        type: CLEAR_SELECTED,
    };
}

export function setKartlagFeiler(kartlagnavn: string): AnyAction{
    return {
        type: KARTLAGFEILER,
        kartlagnavn
    };
}
