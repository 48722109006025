export type SearchSuggestion = {
    id: string;
    tekst: string;
};
let aborts: AbortController[] = [];

const abortAll = (): void => {
    aborts.forEach(a => a.abort());
    aborts = [];
};

export function suggestionSearch(text: string): Promise<SearchSuggestion[]> {
    abortAll();
    const abortController = new AbortController();
    aborts.push(abortController);
    const trimmedText = text.trim(); // Tar bort whitespace foran og etter texten, da dette fører til feil i api-kallet.
    const encodedText = encodeURIComponent(trimmedText);
    const { signal } = abortController;
    return fetch(`api/individual/${encodedText}`, { signal })
        .then(res => res.json())
}
