import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {Bar, BarChart, CartesianGrid, ComposedChart, LabelList, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import {RefundAnimal} from '../../constants/refundAnimal';
import {sheepCompensationData} from '../../constants/statistics';
import {ApplicationState} from '../../store';
import './statisticalInfoBox.scss';
import {useTheme} from '@material-ui/core';
import {alpha, Theme} from '@material-ui/core/styles';
import {createStyles, makeStyles} from '@material-ui/styles';
import {useLanguage} from '../../i18n';
import BarChartIcon from '@material-ui/icons/BarChart';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import StopIcon from '@material-ui/icons/Stop';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import {useMobileBreakpointInnsyn} from '../../hooks/breakpoint';

type OwnProps = {
    animal: RefundAnimal;
    header: string;
    period: boolean;
    fromYear?: number;
    toYear?: number;
    sheepCompensationData?: sheepCompensationData;
    reindeerCompensationData?: object[];
};

type Props = OwnProps;

// function HiddenLabel(props: any): JSX.Element {
//     const { x, y, width, height, value } = props;
//     const label = `${value}`;
//     const labelWidth = label.length;
//     const theme = useTheme();
//     return (
//         <g>
//             <text
//                 x={x}
//                 y={y}
//                 //fill={theme.palette.primary.dark}
//                 //textAnchor="middle"
//                 //dominantBaseline="middle"
//                 aria-label={value}
//             >
//                 {' hei'}
//             </text>
//             {/* ) : (
//             <text
//                 x={x + width - 4 * labelWidth}
//                 y={y + height / 2}
//                 fill={theme.palette.common.white}
//                 textAnchor="middle"
//                 dominantBaseline="middle"
//             >
//                 {label}
//             </text>
//             )} */}
//         </g>
//     );
// }

function RenderLabel(props: any): JSX.Element {
    const { x, y, width, height, value } = props;
    const label = `${value.toLocaleString()} kr`;
    const labelWidth = label.length;
    const outside = 7 * labelWidth > width;
    const theme = useTheme();
    return (
        <g aria-hidden={true}>
            {' '}
            {outside ? (
                <text
                    x={x + width + 4 * labelWidth}
                    y={y + height / 2}
                    fill={theme.palette.primary.dark}
                    textAnchor="middle"
                    dominantBaseline="middle"
                >
                    {label}
                </text>
            ) : (
                <text
                    x={x + width - 4 * labelWidth}
                    y={y + height / 2}
                    fill={theme.palette.common.white}
                    textAnchor="middle"
                    dominantBaseline="middle"
                >
                    {label}
                </text>
            )}
        </g>
    );
}

type LegendProps = {
    type: string;
    color: string;
};

function LegendIcon(props: LegendProps): JSX.Element {
    return (
        <div style={{ color: props.color }}>
            {props.type === 'square' && <BarChartIcon />}
            {props.type === 'circle' && <FiberManualRecordIcon />}
            {props.type === 'line' && <ShowChartIcon />}
            {props.type === 'rect' && <StopIcon />}
            {props.type === 'star' && <DonutLargeIcon />}
        </div>
    );
}

export function CustomizedLegendBarChart(props: any): JSX.Element {
    const { payload } = props;

    return (
        <ul className="legend-list" aria-hidden={true}>
            {payload.map((entry, index) => (
                <li className="legend-list-item" key={`item-${index}`}>
                    <LegendIcon type={entry.type} color={entry.color} />
                    <span>{entry.value}</span>
                </li>
            ))}
        </ul>
    );
}

function CompensationGraphYAxizTick(props: any): JSX.Element {
    const { x, y, payload } = props;
    return (
        <g aria-hidden={true}>
            <text x={x} y={y} dy={5} textAnchor="end" fill="#33333">
                {payload.value.length > 16 ? `${payload.value.slice(0, 15)}...` : payload.value}
            </text>
        </g>
    );
}

function CompensationGraphYAxizTickMobile(props: any): JSX.Element {
    const { x, y, payload } = props;
    return (
        <g aria-hidden={true}>
            <text x={x} y={y} dy={5} textAnchor="end" fill="#33333">
                {payload.value.length > 5 ? `${payload.value.slice(0, 5)}...` : payload.value}
            </text>
        </g>
    );
}

function CompensationGraphXAxizTick(props: any): JSX.Element {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`} aria-hidden={true}>
            <text x={0} y={0} dy={16} textAnchor="middle" fill="#333333">
                {parseInt(payload.value).toLocaleString()}
            </text>
        </g>
    );
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        tooltipWrapper: {
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.primary.light}`,
            padding: '4px 6px 4px 10px',
            lineHeight: '0.7',
            minWidth: '160px',
        },
        tooltipLabel: {
            fontWeight: 'bold',
        },
        tooltipRow: {
            display: 'flex',
            justifyContent: 'space-between',
            gridTemplateColumns: '150px 1fr',
        },
        tooltipRowNOK: {
            display: 'flex',
            flexDirection: 'column',
        },
        span: {
            paddingRight: '10px',
        },
    })
);

function CustomTooltip(props: any): JSX.Element {
    const classes = useStyles({});
    const { active } = props;
    if (active) {
        const { payload, label } = props;
        return (
            <div className={classes.tooltipWrapper}>
                <p className={classes.tooltipLabel}>{`${label}`}</p>
                {payload.map((row, i) => (
                    <p className={classes.tooltipRow} key={i}>
                        <span className={classes.span}>{`${row.name}   `}</span>
                        <span>{row.value.toLocaleString()}</span>
                    </p>
                ))}
            </div>
        );
    }
    return null;
}

function CustomNOKTooltip(props: any): JSX.Element {
    const classes = useStyles({});
    const { active } = props;
    if (active) {
        const { payload, label, animal } = props;
        return (
            <div className={classes.tooltipWrapper}>
                <p className={classes.tooltipLabel} title={'test'}>{`${label}`}</p>
                {payload &&
                    payload.map((row, i) => (
                        <p className={classes.tooltipRowNOK} key={i}>
                            <span className={classes.span}>{`${row.name}   `}</span>
                            <br />
                            <span>{`${row.value.toLocaleString()} kr`} </span>
                        </p>
                    ))}
            </div>
        );
    }
    return null;
}

export default function StatisticalInfoBox(props: Props): JSX.Element {
    const l = useLanguage();
    const sheepData = useSelector((state: ApplicationState) =>
        state.refund.sheepStatistics.sheepCompensationArea
            .filter(obj => obj.area !== 'Totalt')
            .sort((a, b) => a.area.localeCompare(b.area))
    );
    const reindeerData = useSelector((state: ApplicationState) =>
        state.refund.reindeerStatistics.reindeerCompensationArea
            .filter(obj => obj.area !== 'Totalt')
            .sort((a, b) => a.area.localeCompare(b.area))
    );
    const apiData = props.animal === RefundAnimal.Sheep ? sheepData : reindeerData;
    const theme = useTheme();
    const mobileScreen = useMobileBreakpointInnsyn();
    const boxWidth = '100%';
    const boxHeight = apiData.length * 30 + 90;
    const YtickWidth = mobileScreen ? 50 : 140;
    
    return (
        <div>
            <h3>{props.header} </h3>
            <div className="statisticalInfoBox">
                <div className="firstInfoBox">
                    {props.animal === RefundAnimal.Sheep ? (
                        <Fragment>
                            <div className="header">{l('refund.sheepCompensationGraphTitle')}</div>

                            <ResponsiveContainer width={boxWidth} height={boxHeight}>
                                {/* */}
                                <ComposedChart
                                    data={sheepData}
                                    margin={{ left: 30, right: 30 }}
                                    layout="vertical"
                                    aria-label={l('refund.graphicalImage') + l('refund.sheepCompensationGraphTitle')}
                                >
                                    <CartesianGrid
                                        horizontal={false}
                                        strokeDasharray="3 3"
                                        fill="white"
                                        aria-hidden={true}
                                    />
                                    <XAxis
                                        type="number"
                                        height={50}
                                        tickLine={false}
                                        tick={CompensationGraphXAxizTick}
                                        aria-hidden={true}
                                    />
                                    <YAxis
                                        dataKey={'area'}
                                        type="category"
                                        width={YtickWidth}
                                        tickLine={false}
                                        tick={
                                            mobileScreen ? CompensationGraphYAxizTickMobile : CompensationGraphYAxizTick
                                        }
                                        tickMargin={10}
                                        aria-hidden={true}
                                    />
                                    <Tooltip isAnimationActive={false} content={<CustomTooltip />} />
                                    <Legend
                                        payload={[
                                            {
                                                value: l('refund.normalLoss'),
                                                type: 'square',
                                                id: '1',
                                                color: theme.palette.primary.light,
                                            },
                                            {
                                                value: l('refund.totalCompensated'),
                                                type: 'square',
                                                id: '2',
                                                color: theme.palette.primary.dark,
                                            },
                                            { value: l('refund.totalLoss'), type: 'circle', id: '3', color: '#86660E' },
                                        ]}
                                        aria-hidden={true}
                                        content={<CustomizedLegendBarChart />}
                                    />
                                    <Bar
                                        stackId="a"
                                        dataKey={'normalLossTotal'}
                                        name={l('refund.normalLoss')}
                                        fill={theme.palette.primary.light}
                                        maxBarSize={30}
                                        isAnimationActive={false}
                                    />
                                    <Bar
                                        stackId="a"
                                        dataKey={'compensatedTotal'}
                                        name={l('refund.totalCompensated')}
                                        fill={theme.palette.primary.dark}
                                        maxBarSize={30}
                                        isAnimationActive={false}
                                    />
                                    <Line
                                        type="linear"
                                        dataKey="totalLossTotal"
                                        name={l('refund.totalLoss')}
                                        stroke={alpha(theme.palette.common.black, 0)}
                                        fill="#86660E"
                                        isAnimationActive={false}
                                    />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <div className="header">{l('refund.reindeerCompensationGraphTitle')}</div>
                            <ResponsiveContainer width={boxWidth} height={boxHeight}>
                                <ComposedChart
                                    data={apiData}
                                    margin={{ left: 30, right: 30 }}
                                    layout="vertical"
                                    aria-label={l('refund.graphicalImage') + l('refund.reindeerCompensationGraphTitle')}
                                >
                                    <CartesianGrid
                                        horizontal={false}
                                        strokeDasharray="3 3"
                                        fill="white"
                                        aria-hidden={true}
                                    />
                                    <XAxis
                                        type="number"
                                        height={50}
                                        tickLine={false}
                                        tick={CompensationGraphXAxizTick}
                                        aria-hidden={true}
                                    />
                                    <YAxis
                                        dataKey={'area'}
                                        type="category"
                                        width={YtickWidth}
                                        tickLine={false}
                                        tick={
                                            mobileScreen ? CompensationGraphYAxizTickMobile : CompensationGraphYAxizTick
                                        }
                                        tickMargin={10}
                                        aria-hidden={true}
                                    />
                                    <Tooltip isAnimationActive={false} content={<CustomTooltip />} aria-hidden={true} />
                                    <Legend
                                        payload={[
                                            {
                                                value: l('refund.totalCompensated'),
                                                type: 'square',
                                                id: '1',
                                                color: theme.palette.primary.dark,
                                            },
                                            {
                                                value: l('refund.appliedLoss'),
                                                type: 'square',
                                                id: '2',
                                                color: theme.palette.primary.main,
                                            },

                                            {
                                                value: l('refund.totalLoss'),
                                                type: 'square',
                                                id: '3',
                                                color: theme.palette.primary.light,
                                            },
                                        ]}
                                        content={<CustomizedLegendBarChart />}
                                    />
                                    {/* <Area type="monotone" dataKey="amt" stroke="#f2f2f2" fill="#f2f2f2" /> */}
                                    <Bar
                                        stackId="b"
                                        dataKey={'compensatedTotal'}
                                        name={l('refund.totalCompensated')}
                                        fill={theme.palette.primary.dark}
                                        legendType="square"
                                        maxBarSize={40}
                                        isAnimationActive={false}
                                    />
                                    <Bar
                                        stackId="a"
                                        dataKey={'appliedLossTotal'}
                                        name={l('refund.appliedLoss')}
                                        fill={theme.palette.primary.main}
                                        legendType="square"
                                        maxBarSize={40}
                                        isAnimationActive={false}
                                    />
                                    <Bar
                                        stackId="c"
                                        dataKey={'totalLossTotal'}
                                        name={l('refund.totalLoss')}
                                        fill={theme.palette.primary.light}
                                        legendType="square"
                                        maxBarSize={40}
                                        isAnimationActive={false}
                                    />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Fragment>
                    )}
                </div>
                <div className="secondInfobox">
                    <div className="header">{l('refund.compensationAmountGraphTitle')}</div>
                    <ResponsiveContainer width={boxWidth} height={boxHeight}>
                        <BarChart
                            margin={{ left: 30, right: 50 }}
                            data={apiData}
                            layout="vertical"
                            maxBarSize={400}
                            aria-label={l('refund.graphicalImage') + l('refund.compensationAmountGraphTitle')}
                        >
                            <CartesianGrid horizontal={false} strokeDasharray="3 3" fill="white" aria-hidden={true} />
                            <XAxis type="number" height={50} tickLine={false} tick={CompensationGraphXAxizTick} />
                            <YAxis
                                dataKey="area"
                                type="category"
                                width={YtickWidth}
                                // height={50}
                                tick={mobileScreen ? CompensationGraphYAxizTickMobile : CompensationGraphYAxizTick}
                                tickLine={false}
                                tickMargin={10}
                            />
                            <Tooltip isAnimationActive={false} content={<CustomNOKTooltip />} />
                            <Legend
                                payload={[
                                    {
                                        value: l('refund.compensationAmount'),
                                        type: 'square',
                                        id: '1',
                                        color: theme.palette.primary.main,
                                    },
                                ]}
                                content={<CustomizedLegendBarChart />}
                            />
                            <Bar
                                dataKey={'compensationAmount'}
                                name={l('refund.compensationAmount')}
                                fill={theme.palette.primary.main}
                                legendType="square"
                                maxBarSize={30}
                                isAnimationActive={false}
                            >
                                <LabelList dataKey={'compensationAmount'} content={<RenderLabel />} />
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </div>
    );
}
