import React, {useEffect, useState} from 'react';
import {connect, useSelector} from 'react-redux';
import {InitSearch} from '../../src/index';
import Loader from '../components/loader/loader';
import {MainMap} from '../components/map/map';
import Searchbar from '../components/searchbar/searchbar';
import Sidepanel from '../components/sidepanel/sidepanel';
import {ApplicationState} from '../store';
import {UpdateUrl} from '../store/searchfilter/action';
import './main.scss';
import {HoppTilHovedinnhold} from '../components/themeButtton/themeButton';
import {MenuButton} from '../components/menubutton/menubutton';
import {ErrorBoundary} from '../components/errorBoundary/errorBoundary';
import AccessibilityModal from '../components/accessibilityModal/accessibilityModal';
import {useMobileBreakpointInnsyn} from '../hooks/breakpoint';
import {SystemMessageBanner} from '../components/SystemMessageBanner/systemMessageBanner';

type StateProps = {
    isLoading: boolean;
    isInitialized: boolean;
};

type Props = StateProps;

export function _Home(props: Props): JSX.Element {
    const [openModal, setOpenModal] = useState(false);
    const searchfilter = useSelector((state: ApplicationState) => state.searchFilter.searchFilter);
    const [openSidepanel, setOpenSidepanel] = React.useState(true);
    const mobileScreen = useMobileBreakpointInnsyn();

    useEffect(() => {
        if (props.isInitialized) {
            UpdateUrl(searchfilter);
        } else {
            InitSearch();
        }
    }, []);

    return (
        <ErrorBoundary>
            <div className="content">
                <HoppTilHovedinnhold mainContentId={"carnivoreFilter"} />
                {!props.isInitialized || props.isLoading ? <Loader /> : null}
                {!mobileScreen &&
                    <div className={openSidepanel ? 'search-n-menu--offset' : 'search-n-menu'}>
                        <div className={openSidepanel ? 'searchBar_wrapper_offset' : 'searchBar_wrapper'}>
                            <Searchbar />
                        </div>
                    </div>
                }
                {!mobileScreen && <MenuButton />}
                <Sidepanel
                    isOpen={openSidepanel}
                    onClose={() => setOpenSidepanel(false)}
                    onToggleBtn={(state: boolean) => setOpenSidepanel(state)}
                />
                <SystemMessageBanner sidePanelIsOpen={openSidepanel}/>
                <MainMap/>
                <button type="button" onClick={() => setOpenModal(true)} accessKey="0" className="accessibility-button" aria-label="Show accessibility information" />
                <AccessibilityModal open={openModal} handleClose={() => setOpenModal(false)}/>
            </div>
        </ErrorBoundary>
    );
}

const mapStateToProps = (state: ApplicationState): StateProps => {
    return {
        isInitialized: state.settings.isInitialized,
        isLoading: state.settings.isLoading,
    };
};

export const Home = connect(mapStateToProps, null)(_Home);
