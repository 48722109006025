import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core/';
import {createStyles, makeStyles} from '@material-ui/styles';
import React, {Fragment} from 'react';
import {useSelector} from 'react-redux';
import {ApplicationState} from '../../store';
import {alpha} from '@material-ui/core/styles';
import {useLanguage} from '../../i18n';
import '../statisticalTable/statisticalTable.scss';
import {useDesktopBreakpointInnsyn} from '../../hooks/breakpoint';
import {ExpansionPanel, ExpansionPanelContent, ExpansionPanelTitle} from '../expansionPanel/expansionPanel';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: '12px',
            maxWidth: '1308px',
            backgroundColor: theme.palette.background.paper,
            
            '& th': {
                fontSize: '16px',
                fontWeight: 'bold',
                padding: '6px 8px 6px 8px',
            },
            '& th:first-of-type': {
                textAlign: 'left',
            },

            '& td': {
                border: 0,
                fontSize: '16px',
                textAlign: 'right',
                padding: '8px 16px',
            },
            '& td:first-of-type': {
                textAlign: 'left',
            },

            '& tbody tr:nth-of-type(2n + 1)': {
                backgroundColor: alpha(theme.palette.grey[200], 0.5),
            },
        },
        alternateColumn: {
                // backgroundColor: alpha(theme.palette.grey[400], 0.2),
        },
        alternateColumn2: {
            backgroundColor: alpha(theme.palette.grey[400], 0.2),
        },
        head: {
            border: `1px solid ${theme.palette.grey[300]}`,
        },
        expandableTitle: {
            backgroundColor: '#D0E3E2',
            color: '#4C6E6E',
        },
        gapCell: {
            minWidth: 52,
            maxWidth: 52,
        },
        areaColumn: {
            minWidth: '175px',
            maxWidth: '255px',
        },
        applicationColumn: {
            minWidth: '75px',
            maxWidth: '85px',
           
        },
        compensationColumn: {
            minWidth: '130px',
            maxWidth: '130px',
        },
        alignCenter: {
          textAlign: 'center',  
        },
        alignRight: {
            textAlign: 'right',
        }
    })
);

export default function StatisticalTableReindeer(): JSX.Element {
    const l = useLanguage();
    const classes = useStyles({});
    const apiData = useSelector((state: ApplicationState) =>
        state.refund.reindeerStatistics.reindeerCompensationArea.filter(row => row.year === 'ALLE')
    );
    const total = apiData.filter(obj => obj.area === 'Totalt');
    const sorted = apiData
        .filter(obj => obj.area !== 'Totalt')
             .sort((a, b) => a.area.localeCompare(b.area));
    const largeScreen = useDesktopBreakpointInnsyn();
    return (
        <div className={'statistics-table-wrapper'} id="statistics-table">
            {largeScreen ? (
                <Fragment>
                    <Table className={classes.root}>
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell className={classes.areaColumn}>{l('refund.area')}</TableCell>
                                <TableCell className={`${classes.applicationColumn}  ${classes.alignCenter}`}>{l('refund.numberOfApplications')}</TableCell>
                                <TableCell className={`${classes.compensationColumn}  ${classes.alignCenter}`}>{l('refund.compensationAmount')}</TableCell>
                                <TableCell colSpan={3} align={'center'}>{l('refund.totalLoss')}</TableCell>
                                <TableCell colSpan={3} align={'center'} >{l('refund.appliedLoss')}</TableCell>
                                <TableCell colSpan={4} className={classes.alignCenter}>{l('refund.compensated')}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className={classes.gapCell} />
                                <TableCell />
                                <TableCell />
                                <TableCell className={classes.alignRight}>{l('refund.calf')}</TableCell>
                                <TableCell className={classes.alignRight}>{l('refund.bull')}</TableCell>
                                <TableCell className={classes.alignRight}>{l('refund.doe')}</TableCell>
                                <TableCell className={classes.alignRight}>{l('refund.calf')}</TableCell>
                                <TableCell className={classes.alignRight}>{l('refund.bull')}</TableCell>
                                <TableCell className={classes.alignRight}>{l('refund.doe')}</TableCell>
                                <TableCell className={classes.alignRight}>{l('refund.calf')}</TableCell>
                                <TableCell className={classes.alignRight}>{l('refund.bull')}</TableCell>
                                <TableCell className={classes.alignRight}>{l('refund.doe')}</TableCell>
                                <TableCell className={classes.alignRight}>{l('refund.driving')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sorted.map(row => (
                                <TableRow key={row.area} hover={true}>
                                    <TableCell scope="row" className={classes.areaColumn}>{row.area}</TableCell>
                                    <TableCell className={`${classes.applicationColumn}  ${classes.alternateColumn2}`}>{row.applications.toLocaleString()}</TableCell>
                                    <TableCell className={`${classes.compensationColumn}  ${classes.alternateColumn2}`}>{row.compensationAmount.toLocaleString()} kr</TableCell>
                                    <TableCell className={classes.alternateColumn}>{row.totalLossCalf.toLocaleString()}</TableCell>
                                    <TableCell className={classes.alternateColumn}>{row.totalLossBull.toLocaleString()}</TableCell>
                                    <TableCell className={classes.alternateColumn}>{row.totalLossDoe.toLocaleString()}</TableCell>
                                    <TableCell className={classes.alternateColumn2}>{row.appliedLossCalf.toLocaleString()}</TableCell>
                                    <TableCell className={classes.alternateColumn2}>{row.appliedLossBull.toLocaleString()}</TableCell>
                                    <TableCell className={classes.alternateColumn2}>{row.appliedLossDoe.toLocaleString()}</TableCell>
                                    <TableCell className={classes.alternateColumn}>{row.compensatedCalf.toLocaleString()}</TableCell>
                                    <TableCell className={classes.alternateColumn}>{row.compensatedBull.toLocaleString()}</TableCell>
                                    <TableCell className={classes.alternateColumn}>{row.compensatedDoe.toLocaleString()}</TableCell>
                                    <TableCell className={classes.alternateColumn}>{row.compensatedDriving.toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                            {sorted.length > 1
                                ? total.map(row => (
                                      <TableRow key={row.area} hover={true}>
                                          <TableCell scope="row" className={classes.areaColumn}>{row.area}</TableCell>
                                          <TableCell className={`${classes.applicationColumn}  ${classes.alternateColumn2}`}>{row.applications.toLocaleString()}</TableCell>
                                          <TableCell className={`${classes.compensationColumn}  ${classes.alternateColumn2}`}>{row.compensationAmount.toLocaleString()} kr</TableCell>
                                          <TableCell className={classes.alternateColumn}>{row.totalLossCalf.toLocaleString()} </TableCell>
                                          <TableCell className={classes.alternateColumn}>{row.totalLossBull.toLocaleString()} </TableCell>
                                          <TableCell className={classes.alternateColumn}>{row.totalLossDoe.toLocaleString()} </TableCell>
                                          <TableCell className={classes.alternateColumn2}>{row.appliedLossCalf.toLocaleString()} </TableCell>
                                          <TableCell className={classes.alternateColumn2}>{row.appliedLossBull.toLocaleString()} </TableCell>
                                          <TableCell className={classes.alternateColumn2}>{row.appliedLossDoe.toLocaleString()} </TableCell>
                                          <TableCell className={classes.alternateColumn}>{row.compensatedCalf.toLocaleString()} </TableCell>
                                          <TableCell className={classes.alternateColumn}>{row.compensatedBull.toLocaleString()} </TableCell>
                                          <TableCell className={classes.alternateColumn}>{row.compensatedDoe.toLocaleString()} </TableCell>
                                          <TableCell className={classes.alternateColumn}>{row.compensatedDriving.toLocaleString()} </TableCell>
                                      </TableRow>
                                  ))
                                : null}
                        </TableBody>
                    </Table>
                </Fragment>
            ) : (
                apiData
                    .filter(row => row.year === 'ALLE')
                    .map((row, key) => (
                        <ExpansionPanel key={key} theme="dark">
                            <ExpansionPanelTitle theme="dark">
                                {row.area}
                            </ExpansionPanelTitle>
                            <ExpansionPanelContent>
                                <div>
                                    <div>
                                        <b>{l('refund.numberOfApplications')}: </b> {row.applications.toLocaleString()}
                                    </div>
                                    <div>
                                        <b>{l('refund.compensationAmount')}: </b>{' '}
                                        {row.compensationAmount.toLocaleString()} kr
                                    </div>

                                    <div>
                                        <Table size="small" className={classes.root}>
                                            <TableHead className={classes.head}>
                                                <TableRow>
                                                    <TableCell className="small-table-reindeer-left-column"/>
                                                    <TableCell>
                                                        {l('refund.totalLoss')}
                                                    </TableCell>
                                                    <TableCell
                                                    >
                                                        {l('refund.appliedLoss')}
                                                    </TableCell>
                                                    <TableCell
                                                    >
                                                        {l('refund.totalCompensated')}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow hover={true}> 
                                                    <TableCell className="small-table-reindeer-left-column">{l('refund.calf')}</TableCell>
                                                    <TableCell>{row.totalLossCalf.toLocaleString()}</TableCell>
                                                    <TableCell>{row.appliedLossCalf.toLocaleString()}</TableCell>
                                                    <TableCell>{row.compensatedCalf.toLocaleString()}</TableCell>
                                                </TableRow>
                                                <TableRow hover={true}>
                                                    <TableCell className="small-table-reindeer-left-column">{l('refund.bull')}</TableCell>
                                                    <TableCell>{row.totalLossBull.toLocaleString()}</TableCell>
                                                    <TableCell>{row.appliedLossBull.toLocaleString()}</TableCell>
                                                    <TableCell>{row.compensatedBull.toLocaleString()}</TableCell>
                                                </TableRow>
                                                <TableRow hover={true}>
                                                    <TableCell className="small-table-reindeer-left-column">{l('refund.doe')}</TableCell>
                                                    <TableCell>{row.totalLossDoe.toLocaleString()}</TableCell>
                                                    <TableCell>{row.appliedLossDoe.toLocaleString()}</TableCell>
                                                    <TableCell>{row.compensatedDoe.toLocaleString()}</TableCell>
                                                </TableRow>
                                                <TableRow hover={true}>
                                                    <TableCell className="small-table-reindeer-left-column">{l('refund.driving')}</TableCell>
                                                    <TableCell>-</TableCell>
                                                    <TableCell>-</TableCell>
                                                    <TableCell>{row.compensatedDriving.toLocaleString()}</TableCell>
                                                </TableRow> 
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </ExpansionPanelContent>
                        </ExpansionPanel>
                    ))
            )}
        </div>
    );
}
