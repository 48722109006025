import {Language} from './language';

export enum LanguageCode {
    NB = 'nb',
    SV = 'sv',
    EN = 'en',
}

export function getLanguageCode(language: Language): LanguageCode {
    switch (language) {
        case Language.NO:
            return LanguageCode.NB;
        case Language.SV:
            return LanguageCode.SV;
        case Language.EN:
            return LanguageCode.EN;
        default:
            return LanguageCode.NB;
    }
}