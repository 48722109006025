import React, {ReactElement, useState} from 'react';
import {useLanguage} from '../../i18n';
import {GroupedFilterLists,} from '../filterbar/filterLists';
import {ApplicationState, useActionCreator} from '../../store';
import {useSelector} from 'react-redux';
import {containsAll} from '../searchfilter/searchfilter.utils';
import {otherCarnivoresArray} from '../../constants/carnivore';
import {ExpansionPanel, ExpansionPanelContent, ExpansionPanelTitle} from '../expansionPanel/expansionPanel';
import {CheckboxList} from '../checkboxList/checkboxList';
import InfoIcon from '@material-ui/icons/Info';
import {NavLink} from 'react-router-dom';
import {PresetButton} from '../themeButtton/themeButton';
import {UpdateOffspringElementAndSearch} from '../../store/searchfilter/action';
import {Language} from '../../constants/language';

export function RegistrationsFilter(): ReactElement {
    const [keyOpen, setKeyOpen] = useState<number>(null);
    const l = useLanguage();
    const { observations, carnivoreDamages } = GroupedFilterLists();
    const carnivoreFilter = useSelector((state: ApplicationState) => state.searchFilter.searchFilter.Carnivore);
    const offspringFilter = useSelector((state: ApplicationState) => state.searchFilter.searchFilter.Offspring);
    const showObservationFilter = !containsAll(otherCarnivoresArray, carnivoreFilter);
    const language = useSelector((state: ApplicationState) => state.settings.language);
    const UpdateFilterElement = useActionCreator(UpdateOffspringElementAndSearch);
    function toggleOffspring(selected: boolean): void {
        if(offspringFilter !== selected) {
            UpdateFilterElement(selected);
        }
    }
    
    return (
        <div className="registration-filter">
            <h3>{l('searchfilter.registrations')}</h3>
            
            {/* Observations filter */}
            {showObservationFilter && 
                <>
                    <ExpansionPanel
                        className="searchfilter-expansion-panel"
                        expanded={keyOpen === 1}
                        onChangeExpanded={() => setKeyOpen(keyOpen === 1 ? null : 1)}
                    >
                        <ExpansionPanelTitle>{observations.header}</ExpansionPanelTitle>
                        <ExpansionPanelContent>
                            <div className={language === Language.SV ? 'yngling-button-group svensk' : 'yngling-button-group'}>
                                <PresetButton
                                    selected={!offspringFilter}
                                    onClick={() => toggleOffspring(false,)}
                                >
                                    <span>{l('observationsFilter.all')}</span>
                                </PresetButton>

                                <PresetButton
                                    selected={offspringFilter}
                                    onClick={() => toggleOffspring(true)}
                                >
                                    <span>{l('observationsFilter.onlyOffspring')}</span>
                                </PresetButton>
                            </div>
                            <div className="observations-list">
                                <h3>{l('observations')}</h3>
                                <CheckboxList checkboxList={observations} disableBoxes={offspringFilter}/>
                            </div>
                        </ExpansionPanelContent>
                    </ExpansionPanel>
                    
                </>
            }
            
        {/*  Carnivore damages filter  */}
                <ExpansionPanel
                    className="searchfilter-expansion-panel"
                    expanded={keyOpen === 2}
                    onChangeExpanded={() => setKeyOpen(keyOpen === 2 ? null : 2)}
                >
                    <ExpansionPanelTitle>{carnivoreDamages.header}</ExpansionPanelTitle>
                    <ExpansionPanelContent>
                        <div className="info-message">
                            <InfoIcon aria-hidden={true} />
                            <span>{l('carnivoreDamage.infoMessage')}
                                <NavLink to="/om">
                                    {l('menu.about')}
                                </NavLink>.
                            </span>
                        </div>
                        <CheckboxList checkboxList={carnivoreDamages}/>
                    </ExpansionPanelContent>
                </ExpansionPanel>
            
        </div>
    );
}


