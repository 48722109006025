import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLanguage } from '../../i18n';
import { ApplicationState, useActionCreator } from '../../store';
import { clearSelected } from '../../store/map/actions';
import { SetSearchinput, UpdateSearchFilterAndSearch } from '../../store/searchfilter/action';
import {CloseSearchresultlist, RemoveIndivid} from '../../store/sidePanel/action';
import { ClearTooltip } from '../../store/tooltip/actions';
import { SearchbarInput } from '../searchbarInput/searchbarInput';
import './searchbar.scss';
import { SearchSuggestion } from './suggestionService';

export default function Searchbar(): JSX.Element {
    const searchText = useSelector((state: ApplicationState) => state.searchFilter.searchinput);
    const setSearchText = useActionCreator(SetSearchinput);
    const [suggestions, setSuggestions] = useState<SearchSuggestion[]>([]);
    const setSearchParameter = useActionCreator(UpdateSearchFilterAndSearch);
    const clearTooltip = useActionCreator(ClearTooltip);
    const clearSelectedStedfestings = useActionCreator(clearSelected);
    const closeSearchresultList = useActionCreator(CloseSearchresultlist);
    const clearIndivid = useActionCreator(RemoveIndivid);
    const l = useLanguage();

    function handleSuggestionClick(individ: SearchSuggestion, e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
        e.preventDefault();
        setSearchText( `${individ.id} ${individ.tekst}`);
        handleSearch(individ.id);
    }

    function handleSearch(text: string): void {
        // Clear tooltip and selected stedfestings from Redux
        clearTooltip();
        clearSelectedStedfestings();
        if (suggestions.some(s => s.id === text)) {
            setSearchParameter({
                IndividualNameOrID: text,
                ID: [],
                Barcode: [],
            });
        } else if (suggestions.length > 0) {
            setSearchParameter({
                IndividualNameOrID: suggestions[0].id,
                ID: [],
                Barcode: [],
            });
            setSearchText(`${suggestions[0].id} ${suggestions[0].tekst}`);
        }
        else {
            setSearchParameter({
                IndividualNameOrID: '',
                ID: text.replaceAll(' ', '').split(','),
                Barcode: text.replaceAll(' ', '').split(','),
            });
            clearIndivid();
        }
        setSuggestions([]);

    }

    function clearSearch(): void {
        setSearchText('');
        setSearchParameter({
            IndividualNameOrID: '',
            ID: [],
            Barcode: [],
        });
        setSuggestions([]);
        clearIndivid();
        closeSearchresultList();
    }

    return (
        <SearchbarInput
            searchText={searchText}
            onSearch={handleSearch}
            setSuggestions={setSuggestions}
            suggestions={suggestions}
            clearSearch={clearSearch}
            handleSuggestionClick={handleSuggestionClick}
            placeholderText={l('searchbar.placeholder')}
            label={l('searchbar.label')}
        />
    );
}
