import { Reducer } from 'redux';
import { RightPanelState, SET_RIGHTPANEL_CONTENT, RightPanelContentAction } from './types';
import { RightPanelContent } from '../../constants/rightpanelContent';

const initialState = {
    component: 'none' as RightPanelContent.None,
};

const reducer: Reducer<RightPanelState> = (state: RightPanelState = initialState, action): RightPanelState => {
    switch (action.type) {
        case SET_RIGHTPANEL_CONTENT:
            action as RightPanelContentAction;
            return {
                ...state,
                component: action.component === state.component ? RightPanelContent.None : action.component,
            };
        default: {
            return state;
        }
    }
};

export { reducer as RightPanelReducer };
