import { Action } from 'redux';
import { Layer, LayerCollection } from '../../model/layer/layer';
import { Stedfesting } from '../../model/stedfesting/StedfestingTypes';

export type MapState = {
    layers: LayerCollection;
    stedfesting: Stedfesting[];
    selectedStedfesting: Stedfesting[];
    hoveredStedfesting: Stedfesting;
    errorLoadingKartlag: string;
};

export type LayerAction = Action & {
    layers: LayerCollection;
};

export type LayerToggleAction = Action & {
    layer: Layer;
};

export type LayerGroupToggleAction = Action & {
    layers: LayerCollection;
    active: boolean;
};

export type StedfestingAction = Action & {
    stedfestings: Stedfesting[];
};

export type SelectedStedfestingAction = Action & {
    selectedStedfesting: Stedfesting[];
};

export type HoveredStedfestingAction = Action & {
    hoveredStedfesting: Stedfesting;
};

export type SetKartlagFeilerAction = Action & {
    kartlagnavn: string;
};

export const REQUEST_GKTOKEN = '@map/REQUEST_GKTOKEN';
export const INIT_LAYERS = '@map/INIT_LAYERS';
export const TOGGLE_LAYER = '@map/TOGGLE_LAYER';
export const TOGGLE_LAYER_GROUP = '@map/TOGGLE_LAYER_GROUP';
export const SET_STEDFESTING = '@map/SET_STEDFESTINGS';
export const SELECT_STEDFESTING = '@map/SELECT_STEDFESTING';
export const HOVER_STEDFESTING = '@map/HOVER_STEDFESTING';
export const CLEAR_SELECTED = '@map/CLEAR_SELECTED';
export const KARTLAGFEILER = '@Kartlag/KARTLAGFEILER'
