import {createStyles, makeStyles, Theme} from '@material-ui/core';
import classnames from 'classnames';
import React from 'react';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import {Language} from '../../constants/language';
import {useLanguage} from '../../i18n';
import {ApplicationState} from '../../store';
import {Site} from '../../store/settings/types';
import {Logo} from '../logo/logo';
import {MenuButton} from '../menubutton/menubutton';
import './menubar.scss';
import {Watermark} from '../watermark/watermark';
import {useMobileBreakpointInnsyn, useTabletBreakpointInnsyn} from '../../hooks/breakpoint';
import {Julehilsen} from '../julehilsen/julehilsen';

type StateProps = {
    site: Site;
    language: Language;
};

type Props = StateProps;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuBar: {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.dark,
        },
        logoLink: {
            textDecoration: 'none',
            '&:focus': {
                outline: 'none',
                '& img': {
                    outline: `1px solid ${theme.palette.primary.light}`,
                    outlineOffset: '2px'
                }

            }
        },
        siteName: {
            color: theme.palette.primary.dark,  
        },
        rovbaseLink: {
            textDecoration: 'none',
            '&:focus': {
                outline: `1px solid ${theme.palette.primary.light}`,
                outlineOffset: '2px'
            }
        }
    })
);

function _MenuBar(props: Props): JSX.Element {
    const classes = useStyles({});
    const menuBarClass = classnames(classes.menuBar, 'menu-bar');
    const siteNameClass = classnames(classes.siteName, 'site-name');
    const mobileScreen = useMobileBreakpointInnsyn();
    const tabletScreen = useTabletBreakpointInnsyn();
    const logoWidth = tabletScreen ? 44 : 60;
    const l = useLanguage();

    return (
        <div className={menuBarClass}>
            <div className="logo-title-container">
                <div className="logos">
                    <a
                        href="https://www.miljodirektoratet.no/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.logoLink}
                        tabIndex={2}
                    >
                        <Logo language={props.language} site={Site.NO} width={logoWidth} alt={`${l('contact.MDIR')}, ${l('uu.openNewWindow')}`}  />
                    </a>
                    <a
                        href="https://www.naturvardsverket.se/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.logoLink}
                        tabIndex={2}
                    >
                        <Logo language={props.language} site={Site.SE} width={logoWidth} alt={`${l('contact.Naturvardsverket')}, ${l('uu.openNewWindow')}`} />
                    </a>
                </div>
                <NavLink
                    className={classes.rovbaseLink}
                    to="/"
                    exact={true}
                    title={l('ariaLabel.openHomepage')}
                    aria-label={l('ariaLabel.openHomepage')}
                    tabIndex={2}
                    accessKey={'1'}
                >
                    <h1 className={siteNameClass}>Rovbase</h1>
                </NavLink>
                <Watermark className={'menu-watermark'} />
            </div>
            {/* Kommenter inn julehilsen for nisselue og snø på skjerm */}
            {!mobileScreen && <Julehilsen/>}
            {mobileScreen && <MenuButton />}
        </div>
    );
}

const mapStateToProps = (state: ApplicationState): StateProps => {
    return {
        site: state.settings.site,
        language: state.settings.language,
    };
};

export const MenuBar = connect(mapStateToProps, null)(_MenuBar);
