import { join, map, pipe, uniq, values } from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Layer } from '../../model/layer/layer';
import { ApplicationState } from '../../store';
import './attribution.scss';

const getAttributionString = (layers: Layer[]): string => {
    const attribution = pipe(
        map<Layer, string>(layer => layer.options?.attribution),
        strings => strings.filter(val => val && val !== ''),
        uniq,
        join(', ')
    )(layers);

    return ` \u00A9 ${attribution}`;
};

export function Attribution(): JSX.Element {
    const layerCollection = useSelector((state: ApplicationState) => state.map.layers);
    const visibleLayers: Layer[] = values(layerCollection).filter(layer => layer.options.visible);
    const attributions = React.useMemo(() => getAttributionString(visibleLayers), [layerCollection]);

    return <div className="attribution-wrapper">{attributions}</div>;
}
