import * as React from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useLanguage} from '../../i18n';
import {Layers} from '../../map/controls/layers';
import {ZoomIn, ZoomOut} from '../../map/controls/zoom';
import {olMap, useMap} from '../../map/map';
import {isStedfesting} from '../../model/stedfesting/Stedfesting';
import {ApplicationState} from '../../store';
import {LayerSelector} from '../layerselect/layerSelector';
import {MapTool, MapToolbar} from '../toolbar/toolbar';
import './map.scss';
import {Tooltip} from '../tooltip/tooltip';
import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {Attribution} from '../attribution/attribution';
import {WatermarkMapLayer} from '../watermark/watermark';
import {MobileTooltip} from '../mobileTooltip/mobileTooltip';
import {useMobileBreakpointInnsyn} from '../../hooks/breakpoint';



const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        map: {
            backgroundColor: theme.palette.background.default,
        },
    })
);

export function MainMap(): JSX.Element {
    const tooltipStedfesting = useSelector((state: ApplicationState) => state.tooltip.stedfesting);
    const mapRef = useMap(olMap);
    const classes = useStyles({});
    const lang = useLanguage();
    const mobileScreen = useMobileBreakpointInnsyn();
   
    const [layerSelectorOpen, setLayerSelectorOpen] = useState(false);
    const openLayerSelector = (): void => {
        setLayerSelectorOpen(true);
    };
    const closeLayerSelector = (): void => {
        setLayerSelectorOpen(false);
    };

    return (
        <div id="map" ref={mapRef} className={classes.map }>
            <MapToolbar>
                <MapTool label={lang('map.zoominn')} control={ZoomIn} src="zoomin" ariaLabel={lang('map.zoominn')} />
                <MapTool label={lang('map.zoomut')} control={ZoomOut} src="zoomout" ariaLabel={lang('map.zoomut')} />
                <MapTool
                    label={lang('map.layers')}
                    control={Layers}
                    onActivate={openLayerSelector}
                    src="layers"
                    ariaLabel={lang('map.layers')}
                />
            </MapToolbar>
            {mobileScreen && isStedfesting(tooltipStedfesting) && <MobileTooltip />}
            {!mobileScreen && isStedfesting(tooltipStedfesting) &&  <Tooltip />}
            <LayerSelector closeDrawer={closeLayerSelector} open={layerSelectorOpen} />
            <WatermarkMapLayer />
            <Attribution />
        </div>
    );
}
