/* eslint-disable no-unused-vars */
// Skal matche CauseOfDeathType.cs i backend
export enum causeOfDeathType {
    LicenseHunting = 1,
    DamageControl = 2,
    DefenceOfAnimal  = 3,
    SnoMission = 4,
    AnimalWelfare = 5,
    PoliceDecision  = 6,
    KilledByAnimal = 7,
    Research = 8,
    IllegalKilling = 9,
    CollisionRail = 10,
    CollisionRoad = 11,
    SelfDefence = 12,
    Accident = 13,
    Disease = 14,
    Unknown = 15,
    QuotaHunting = 16
}
