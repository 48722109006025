import { createStyles, Drawer, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/styles/makeStyles';
import filter from 'ramda/es/filter';
import values from 'ramda/es/values';
import React, {PropsWithChildren, ReactElement} from 'react';
import { useSelector } from 'react-redux';
import { useLanguage } from '../../i18n';
import { isVisible, LayerCollection, LayerGroup } from '../../model/layer/layer';
import {ApplicationState, useActionCreator} from '../../store';
import { toggleLayerGroup } from '../../store/map/actions';
import { IconButton } from '../themeButtton/themeButton';
import classnames from 'classnames';
import './layerSelector.scss';
import {ToggleSwitch} from '../toggleSwitch/toggleSwitch';
import {useMobileBreakpointInnsyn} from '../../hooks/breakpoint';
import {Tooltip} from '../layerSelectorToolTip/tooltip';


const useLSStyles = makeStyles((theme: Theme) =>
    createStyles({
        title: {
            backgroundColor: theme.palette.secondary.main,
        },
        imageButton: {
            '& img': {
                border: `3px solid ${theme.palette.grey[300]}`,
            },
            '&:focus': {
              fontWeight: 'bold',  
            },
            '&:focus img': {
                outline: `1px solid ${theme.palette.primary.dark}`,
                outlineOffset: '2px',
            }
        },
        layerChecked: {
           '& img':{
               border: `3px solid ${theme.palette.primary.light}`,
           }
        },
        imageSwitch: {
            '&:focus-within': {
                outline: `1px solid ${theme.palette.primary.light}`,
            }
        },
        switchButtonWrapper: {
            display: 'flex',
            maxWidth: 'fit-content',
            '&:focus-within': {
                outline: `1px solid ${theme.palette.primary.light}`,
            }
        }
    })
);

const layerCollectionNotEmpty = (layers: LayerCollection): boolean => values(layers).length > 0;

type LayerSelectorProps = {
    open: boolean;
    closeDrawer: () => void;
};

export function LayerSelector(props: LayerSelectorProps): JSX.Element {
    const classes = useLSStyles({});
    const titleClass = classnames(classes.title, 'layer-selector-header');
    const mobile = useMobileBreakpointInnsyn();
    const layers = useSelector((state: ApplicationState) => state.map.layers);
    const l = useLanguage();
    const getLayerGroup = (ids: string[]): LayerCollection => filter(layer => ids.includes(layer.id), layers);
    const drawerPosition = mobile ? 'bottom' : 'right';
    const toggleLayers = useActionCreator(toggleLayerGroup);
    
    // Layer groups
    const reinbeitedistriktLayer = getLayerGroup(['reinbeite_omr', 'reinbeite_distr']);
    const beitelagsLayer = getLayerGroup(['beitelagnamn', 'beitelaggrenser']);
    const samebyLayer = getLayerGroup(['samebygrenser']);

    return (
        <Drawer anchor={drawerPosition} onClose={props.closeDrawer} open={props.open}>
            <div className={'layer-selector-container'}>
                <div className={titleClass}>
                    <h2>{l('map.layers')}</h2>
                    <IconButton onClick={props.closeDrawer} tabIndex={0} aria-label={l('uu.close')}>
                        <CloseIcon color="primary" />
                    </IconButton>
                </div>
                <div className={'layer-selector-body'}>
                    <LayerGroupWrapper title={l('layerSelector.basemap')}>
                        <div className={'image-switch-container'}>
                            <MapLayerImageButton 
                                label="Standard" 
                                layers={getLayerGroup(['norge_graatone', 'sverige_nedtonad'])} 
                                group="bg"
                                imgsrc="/assets/png/graatone.png" 
                            />
                            <MapLayerImageButton 
                                label={l('layerSelector.satellite')} 
                                layers={getLayerGroup(['norgeibilder', 'sverige_orto'])}
                                group="bg"
                                imgsrc={'/assets/png/nib.png'} 
                            />
                            <MapLayerImageButton 
                                label={l('layerSelector.color')} 
                                layers={getLayerGroup(['norge', 'sverige'])} 
                                group="bg" 
                                imgsrc={'/assets/png/topo.png'} 
                            />
                        </div>
                    </LayerGroupWrapper>
                    <LayerGroupWrapper title={l('layerSelector.reindrift')}>
                        {layerCollectionNotEmpty(reinbeitedistriktLayer) &&
                            <div className={classes.switchButtonWrapper}>
                                <ToggleSwitch id={'layer-selector-reinbeite'} label={l('layerSelector.reinbeitedistrikt')} checked={isVisible(reinbeitedistriktLayer)} onChange={() => toggleLayers(reinbeitedistriktLayer, !isVisible(reinbeitedistriktLayer))}/>
                            </div>
                            }
                        {layerCollectionNotEmpty(beitelagsLayer) &&
                            <div className={classes.switchButtonWrapper}>
                                <ToggleSwitch id={'layer-selector-beitelag'} label={l('layerSelector.beitelag')} checked={isVisible(beitelagsLayer)} onChange={() => toggleLayers(beitelagsLayer, !isVisible(beitelagsLayer))}/>
                            </div>
                            }
                        {layerCollectionNotEmpty(samebyLayer) &&
                            <div className={classes.switchButtonWrapper}>
                                <ToggleSwitch id={'layer-selector-sameby'} label={l('layerSelector.samebygrense')} checked={isVisible(samebyLayer)} onChange={() => toggleLayers(samebyLayer, !isVisible(samebyLayer))}/>
                                <Tooltip text={l('layerSelector.samebygrense.info')} tooltipForLabel={l('layerSelector.samebygrense')}/>
                            </div>
                            }
                    </LayerGroupWrapper>
                    <LayerGroupWrapper title={l('layerSelector.managementAreas')}>
                        <MapLayerImageSwitch label={l('carnivore.wolf')} imgsrc={'/assets/svg/layerIcons/ulv.svg'}  layers={getLayerGroup(['ulv'])}/>
                        <MapLayerImageSwitch label={l('carnivore.bear')} imgsrc={'/assets/svg/layerIcons/bjorn.svg'}  layers={getLayerGroup(['bjorn'])}/>
                        <MapLayerImageSwitch label={l('carnivore.lynx')} imgsrc={'/assets/svg/layerIcons/gaupe.svg'}  layers={getLayerGroup(['gaupe'])}/>
                        <MapLayerImageSwitch label={l('carnivore.wolverine')} imgsrc={'/assets/svg/layerIcons/jerv.svg'}  layers={getLayerGroup(['jerv'])}/>
               
                    </LayerGroupWrapper>    
                </div>
            </div>
        </Drawer>
    );
}

type layerGroupProps = {
    title: string;
}

function LayerGroupWrapper(props: PropsWithChildren<layerGroupProps>): ReactElement {
    return (
        <div className={'layer-group-wrapper'}>
            <h3>{props.title}</h3>
            {props.children}
        </div>
    );
}


type ImageButtonProps = {
    label: string;
    group?: string;
    layers: LayerCollection;
    imgsrc: string;
};


function MapLayerImageButton(props: ImageButtonProps): ReactElement {
    const classes = useLSStyles({});
    const checked = isVisible(props.layers);
    const toggleLayers = useActionCreator(toggleLayerGroup);
    const checkedClass = classnames('image-button', classes.imageButton, classes.layerChecked);
    const imageButtonClass = classnames('image-button', classes.imageButton);
    const handleClickLayer = (layers: LayerCollection) => e => {
        e.preventDefault();
        if (values(layers)[0].group && checked) {
            return;
        }
        toggleLayers(layers, !isVisible(layers));
    };
    // console.log(checked);
    return (
        <button className={checked ? checkedClass : imageButtonClass } onClick={handleClickLayer(props.layers)} aria-pressed={isVisible(props.layers)}>
            <img className={'layer-image'} src={props.imgsrc} alt="" />
            <div>{props.label}</div>
        </button>
    );
}

type ImageSwitchProps = {
    label: string;
    layers: LayerCollection;
    imgsrc: string;
};


function MapLayerImageSwitch(props: ImageSwitchProps): ReactElement {
    const classes = useLSStyles({});
    const imageSwitchClass = classnames('layer-selector-image-switch', classes.imageSwitch);
    const toggleLayers = useActionCreator(toggleLayerGroup);

    return (
        <div className={imageSwitchClass}>
            <ToggleSwitch id={`map-layer-${props.label}`} label={props.label} checked={isVisible(props.layers)} onChange={() => toggleLayers(props.layers, !isVisible(props.layers))}/>
            <img src={props.imgsrc} alt={''} />
        </div>
    );
}

