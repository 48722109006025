import React from 'react';
import Checkmark from '../../../assets/svg/md-icons/checkmark.svg';
import './checkbox.scss';

type OwnProps = {
    label: string;
    onChange: () => void;
    selected: boolean;
};

export function CheckboxWrapper(props: OwnProps): JSX.Element {
    const checkboxID = props.label.replace(' ', '').toLowerCase();
    return (
        <label className={'checkbox-text'}>
            <input
                type="checkbox"
                className="checkbox-input"
                checked={props.selected}
                onChange={props.onChange}
                value={props.label}
                id={checkboxID}
            />
            <label className="checkbox-label" htmlFor={checkboxID}>
                <Checkmark className="checkmark" aria-hidden={true} />
            </label>
            {props.label}
        </label>
    );
}
