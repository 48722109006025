import React, {ReactElement, useEffect, useState} from 'react';
import {useLanguage, useLanguageTemplate} from '../../i18n';
import {IconButton, SecondaryButton} from '../themeButtton/themeButton';
import CloseIcon from '@material-ui/icons/Close';
import {ApplicationState, useActionCreator} from '../../store';
import {SetSearchinput, UpdateSearchFilterAndSearch} from '../../store/searchfilter/action';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import {CloseSearchresultlist, RemoveIndivid, SetIndivid} from '../../store/sidePanel/action';
import {Individ, Stedfesting} from '../../model/stedfesting/StedfestingTypes';
import {isDNA, isDodeRovdyr, isRovviltobservasjon} from '../../model/stedfesting/Stedfesting';
import {getStringFromEnum} from '../tooltip/tooltip.util';
import {Carnivore} from '../../constants/carnivore';
import {useSelector} from 'react-redux';
import {OriginIndividual} from '../../constants/originIndividual';
import {Gender} from '../../constants/gender';
import {getIndividualData} from '../../services/individService';
import {isNil} from 'ramda';

type TitleProps = {
    title: string;
}

export type IndividualData = {
    individID: string;
    individnavn: string;
    kjonnID: string;
    artsID: string;
    fodtRevir: string;
    opprinnelseID: string;
}

export function ResultTitle(props: TitleProps): ReactElement {
    const l = useLanguage();
    const closeResultList = useActionCreator(CloseSearchresultlist);
    return (
        <div className="title">
            <h2>{props.title}</h2>
            <IconButton onClick={closeResultList} aria-label={l('uu.close')}>
                <CloseIcon/>
            </IconButton>
        </div>
    );
}

export function LoadingTitle(props: TitleProps): ReactElement {
    const l = useLanguage();
    const setSearchParameter = useActionCreator(UpdateSearchFilterAndSearch);
    const setSearchinput = useActionCreator(SetSearchinput);
    const closeResultList = useActionCreator(CloseSearchresultlist);
    const handleCloseSpecialSearch = (): void => {
        setSearchParameter({
            IndividualNameOrID: '',
            ID: [],
            Barcode: [],
        });
        setSearchinput('');
        closeResultList();
    };

    return (
        <div className={'loading-panel'}>
            <div className={'special-search-header'}>
                <SecondaryButton onClick={handleCloseSpecialSearch} className={'flex-button'}>
                    <NavigateBeforeIcon className="arrow-left-icon"/>
                    <div>{`${l('searchresultList.backToSearch')}`}</div>
                </SecondaryButton>
            </div>
            <h2>{props.title}</h2>
        </div>
    );
}

export const getIndividObjectFromStedfesting = (individSearchText: string, stedfesting: Stedfesting): Individ => {
    if (isDNA(stedfesting) || isDodeRovdyr(stedfesting)) {
        return stedfesting.individ && stedfesting.individ;
    } else if (isRovviltobservasjon(stedfesting)) {
        if (stedfesting.individ) {
            return stedfesting.individ.filter(obj => obj.individID === individSearchText)[0];
        }
    }
    return {individID: individSearchText, individnavn: ''};
};



export function SpecialSearchTitle(): ReactElement {
    const l = useLanguage();
    const lt = useLanguageTemplate();

    // Actions from store
    const setSearchParameter = useActionCreator(UpdateSearchFilterAndSearch);
    const setSearchinput = useActionCreator(SetSearchinput);
    const closeResultList = useActionCreator(CloseSearchresultlist);
    const setIndivid = useActionCreator(SetIndivid);
    const clearIndivid = useActionCreator(RemoveIndivid);

    // Values from store
    const stedfestings = useSelector((state: ApplicationState) => state.map.stedfesting);
    const individSearchText = useSelector((state: ApplicationState) => state.searchFilter.searchFilter.IndividualNameOrID);
    const barcode = useSelector((state: ApplicationState) => state.searchFilter.searchFilter.Barcode);
    const id = useSelector((state: ApplicationState) => state.searchFilter.searchFilter.ID);
    const individ = useSelector((state: ApplicationState) => state.sidePanelFilter.individ);

    // Other values
    const listLength = stedfestings.length;
    const isIndividSearch = individSearchText.length > 0;
    const isRovbaseIDSearch = listLength === 1 && id[0] === stedfestings[0].id;
    const haveIndivid = individ && listLength > 0;
    const theCarnivoreString = getStringFromEnum(Carnivore, individ?.artsID);
    const genderString = getStringFromEnum(Gender, individ?.kjonnID);
    const originString = getStringFromEnum(OriginIndividual, individ?.opprinnelseID).toLowerCase();
    const individualCarnivoreType = haveIndivid
        ? `${l(`theCarnivore.${theCarnivoreString}.${genderString}`)}`
        : l('tooltip.individual').toLowerCase();
    const searchType = listLength === 1 ? l('searchType.barcode') : l('searchType.search');


    const getBarcodeSearchTitle = (barcodes: string[]): string => {
        if (barcodes.length > 3) {
            return lt('searchresult.multipleElementsSearch', {element1: barcodes[0], element2: barcodes[1], count: barcodes.length - 2});
        }
        return barcodes.join(', ');
    };
    
    // Titles
    const individDescription = individ && lt('searchresult.individDescription', {
        count: listLength,
        individualType: individualCarnivoreType,
        individual: individ
            ? `${individ.individID} ${
                individ.individnavn
            }`
            : individSearchText,
    });
    const individOpprinnelse = individ?.opprinnelseID ? `. ${l(`individual.origin.${originString}`)}` : '';
    const individRevir = individ?.fodtRevir ? lt('searchresult.individDescription.revir', {
        revir: individ.fodtRevir
    }) : '';
    const individTitle = haveIndivid ? `${individDescription}${individRevir}${individOpprinnelse}` : individDescription;
    const idBarcodeTitle = lt('searchresult.specialSearchDescription', {
        searchType: isRovbaseIDSearch ? l('searchType.id') : searchType,
        count: listLength,
        searchText: getBarcodeSearchTitle(barcode),
    });
    const specialSearchTitle = isIndividSearch ? individTitle : idBarcodeTitle;

    useEffect(() => {
        if (!isNil(individSearchText) && individSearchText !== '') {
            getIndividualData(individSearchText)
                .then(res => setIndivid(res));
        }
    }, [individSearchText]);

    const handleCloseSpecialSearch = (): void => {
        setSearchParameter({
            IndividualNameOrID: '',
            ID: [],
            Barcode: [],
        });
        setSearchinput('');
        clearIndivid();
        closeResultList();
    };

    return (
        <>
            <div className={'special-search-header'}>
                <SecondaryButton onClick={handleCloseSpecialSearch} className={'flex-button'}>
                    <NavigateBeforeIcon className="arrow-left-icon"/>
                    <div>{l('searchresultList.backToSearch')}</div>
                </SecondaryButton>
            </div>
            <h2>{specialSearchTitle}</h2>
        </>
    );
}

