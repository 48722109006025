import { Reducer } from 'redux';
import {
    CLOSE,
    MenuState,
    OPEN,
    REMOVE_INDIVID,
    SET_INDIVID,
    SidePanelFilterState,
} from './types';

const initialMenuState: MenuState = {
    open: false
};

const initialState: SidePanelFilterState = {
    individ: null,
    menuState: initialMenuState
};

const reducer: Reducer<SidePanelFilterState> = (state = initialState, action): SidePanelFilterState => {
    switch (action.type) {
        case OPEN:
            return {
                ...state,
                menuState: {
                    open: true
                }
            };
        case CLOSE:
            return {
                ...state,
                menuState: {
                    open: false
                }
            };
        case SET_INDIVID: 
            return {
                ...state,
                individ: action.value
            };
        case REMOVE_INDIVID:
            return {
                ...state,
                individ: initialState.individ
            }; 
        default: {
            return state;
        }
    }
};

export { reducer as sidePanelReducer };
