import React, {ReactNode} from 'react';
import { MenuBar } from '../menubar/menubar';
import { MenuButton } from '../menubutton/menubutton';
import { Footer } from '../footer/footer';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import './staticPageFrame.scss';
import {useMobileBreakpointInnsyn} from '../../hooks/breakpoint';
import classnames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& a': {
                '&:active, &:visited, &:link': {
                    color: theme.palette.primary.main,
                },
            },
        },
    })
);

type StaticPageFrameProps =  { className?: string, children: ReactNode | ReactNode[] }

export default function StaticPageFrame(props: StaticPageFrameProps): JSX.Element {
    const classes = useStyles({});
    const mobileScreen = useMobileBreakpointInnsyn();
    const classNameContainer = props.className || '';
    return (
        <div className={classnames('content-wrapper', classNameContainer)}>
            <MenuBar />
            {!mobileScreen && <MenuButton />}
            <main className={classes.root}>{props.children}</main>
            <Footer/>
        </div>
    );
}
