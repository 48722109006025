import { observeProp, store } from '..';
import { LayerCollection, Layer } from '../../model/layer/layer';
import filter from 'ramda/es/filter';
import keys from 'ramda/es/keys';
import { Language } from '../../constants/language';

export function initCookieStore(): void {
    observeProp<LayerCollection>(store, ['map', 'layers']).subscribe(layerCollection => {
        localStorage.setItem(
            'mapLayers',
            JSON.stringify(keys(filter((layer: Layer) => layer.options.visible, layerCollection)))
        );
    });

    observeProp<Language>(store, ['settings', 'language']).subscribe(language => {
        localStorage.setItem('language', language);
    });
}
