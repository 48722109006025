import { Reducer } from 'redux';
import {
    SettingsState,
    SET_LANGUAGE,
    SET_ISLOADING,
    LanguageAction,
    Site,
    SET_SITE,
    SiteAction,
    SET_ISINITIALIZED,
} from './types';
import { store } from '../index';
import { setSite, setLanguage } from './actions';
import { Language, isLang } from '../../constants/language';

const isSwedish = /.se$/.test(location.origin);
// as Language.NO nødvendig, men usikker på hvorfor. Virker som bug knyttet til enum vs const enum
const defaultLanguage = isSwedish ? ('sv' as Language.SV) : ('no' as Language.NO);
let language: Language = defaultLanguage;
try {
    if (isLang(localStorage.language)) {
        language = localStorage.language;
    } else {
        localStorage.clear();
    }
} catch (e) {
    localStorage.clear();
}

const initialState: SettingsState = {
    language: language,
    isLoading: false,
    site: isSwedish ? Site.SE : Site.NO,
    isInitialized: false,
};

export const settingsReducer: Reducer<SettingsState> = (state = initialState, action): SettingsState => {
    switch (action.type) {
        case SET_LANGUAGE: {
            const { language } = action as LanguageAction;
            return {
                ...state,
                language,
            };
        }

        case SET_SITE: {
            const { site } = action as SiteAction;
            return {
                ...state,
                site,
            };
        }

        case SET_ISLOADING: {
            return {
                ...state,
                isLoading: action.isLoading,
            };
        }

        case SET_ISINITIALIZED: {
            return {
                ...state,
                isInitialized: action.isInitialized,
            };
        }
        default: {
            return state;
        }
    }
};

// @ts-ignore
window.setSite = site => {
    if (site === 'no' || site === 'se') {
        store.dispatch(setSite(site === 'no' ? 0 : 1));
        store.dispatch(setLanguage(site === 'no' ? Language.NO : Language.SV));
    }
};
