import React, { Component, ErrorInfo, ReactNode } from 'react';
import { PrimaryButton } from '../themeButtton/themeButton';
import './errorBoundary.scss';
import {appInsight} from "../../applicationInsights";
import L from "../../i18n";
import {store} from "../../store";

type ErrorState = {
    hasError: boolean;
    error?: Error;
    errorInfo?: ErrorInfo;
};

export class ErrorBoundary extends Component<{}, ErrorState> {
    public constructor(props: any) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }

    private static getDerivedStateFromError(error: Error): ErrorState {
        return { hasError: true, error: error };
    }

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        try {
            // const { appInsights } = window;
            const _error = error instanceof Error ? error : new Error(error);
            appInsight && appInsight.trackException({exception: _error });
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Could not log exception', e);
        }
    }

    public render(): ReactNode {
        if (this.state.hasError) {
            const language = store.getState().settings.language;
            const l = L(language);
            // Fallback UI
            return (
                <div className="error-wrapper">
                    <h2>{l('error.oida')}</h2>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error.message}
                    </details>
                    <PrimaryButton onClick={() => (window.location.href = window.location.origin)}>
                        {l('error.goBack')}
                    </PrimaryButton>
                </div>
            );
        }

        return this.props.children;
    }
}
