import {useLanguage, useLanguageTemplate} from "../../i18n";
import {ControlType} from "../../constants/controlType";
import {Evaluation} from "../../constants/evaluation";
import {Carnivore} from "../../constants/carnivore";
import {Observations} from "../../constants/observations";
import React from "react";
import {getStringFromEnum, getStringFromEnumNumber, notNullOrEmpty} from "./tooltip.util";
import {Rovviltobservasjon} from "../../model/stedfesting/StedfestingTypes";
import {IndividLink} from "./tooltip";
import './tooltip.scss';
import {useSelector} from "react-redux";
import {ApplicationState} from "../../store";

function useIntro(stedfesting: Rovviltobservasjon): string {
    const lt = useLanguageTemplate();
    const l = useLanguage();
    const vurderingID = parseInt(stedfesting.vurderingID);
    const art = l(`carnivore.${getStringFromEnum(Carnivore, stedfesting.artsID)}`);
    
    const control = notNullOrEmpty(stedfesting.kontrollstatusID)
        ? `${l(`observations.controlType.${getStringFromEnum(ControlType, stedfesting.kontrollstatusID)}`)}. `
        : `${l('observations.controlType.notVerified')}`;

    if (vurderingID === Evaluation.ErroneousReport) {
        return lt('tooltip.observation.error', {
            art: art,
            controlType: control,
        });
    }

    return lt('tooltip.observation', {
        art: art,
        controlType: control,
        evaluation: l(`evaluation.${getStringFromEnum(Evaluation, stedfesting.vurderingID)}`).toLowerCase(),
    });
}

function useObservations(stedfesting: Rovviltobservasjon): string {
    const l = useLanguage();

    if (stedfesting.observasjoner.length > 0) {
        const observations = stedfesting.observasjoner.map((i, key) => {
            if (key === 0) {
                return l(`observations.${getStringFromEnumNumber(Observations, i)}`);
            } else {
                return l(`observations.${getStringFromEnumNumber(Observations, i)}`).toLowerCase();
            }
        });
        
        return observations.join(", ");
    }
}

export function RovviltobservasjonTooltipDescription(): JSX.Element {
    const l = useLanguage();
    const stedfesting = useSelector((state: ApplicationState) => state.tooltip.stedfesting) as Rovviltobservasjon;

    const intro = useIntro(stedfesting);
    const observations = useObservations(stedfesting);

    return (
        <div className="tooltip-description">
            {intro}
            <IndividLink individ={stedfesting.individ} text={l('tooltip.individual')} postText={l('tooltip.individualPhrase')}/>
            {observations && <div className="observation-types">{observations}</div>}
        </div>
    );
}

