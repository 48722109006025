// Used for analysed DNA-samples
export enum CarnivoreObserved {
    Lynx = 1,
    Wolverine = 2,
    Bear = 3,
    Wolf = 4,
    Eagle = 5,
    RedFox = 6,
    ArcticFox = 7,
    Dog = 8,
    Unknown = 14,
    Mink = 15,
    Marten = 17,
}
