import { TooltipState, SET_TOOLTIP, SetTooltipAction, CLEAR_TOOLTIP } from './types';
import { AnyAction } from 'redux';

const initialState: TooltipState = {
    stedfesting: null
};

export const tooltipReducer = (state: TooltipState = initialState, action: AnyAction): TooltipState => {
    switch (action.type) {
        case SET_TOOLTIP: {
            const { stedfesting } = action as SetTooltipAction;
            return {
                ...state,
                stedfesting
            };
        }

        case CLEAR_TOOLTIP: {
            return {
                ...initialState
            };
        }

        default: {
            return state;
        }
    }
};
