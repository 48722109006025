export enum Datatype {
    DNA = 'dna', // NB: Denne må være med små bokstaver
    Rovviltobservasjon = 'Rovviltobservasjon',
    Rovviltskade = 'Rovviltskade',
    YnglelokalitetJerv = 'YnglelokalitetJerv',
    DodeRovdyr = 'DodeRovdyr',
    MarhundObservasjon = 'MarhundObservasjon',
    KongeornReirplass = 'KongeornReirplass',
}

export type Individ = {
    individID: string;
    individnavn: string;
};

type StedfestingBase = {
    id: string;
    wkt: string;
    datatype: Datatype;
    dato: string;
    kommune: string;
    funnsted: string;
    rovdyrArtsID: string;
};

export type DNA = StedfestingBase & {
    strekkode: string;
    artsIDPrøve: string;
    artsIDAnalyse: string;
    prøvetypeID: string;
    prøvestatusID: string;
    individ?: Individ;
    kjønnID: string;
};

export type Rovviltobservasjon = StedfestingBase & {
    observasjonstype: string;
    artsID: string;
    kjønnID: string;
    vurderingID: string;
    observasjoner: number[];
    totaltAntall: string;
    individ: Individ[];
    kontrollstatusID: string;
};

export type Rovviltskade = StedfestingBase & {
    tilstandID: string;
    skadetypeID: string;
    skadeårsakID: string;
    skadestedID: string;
    hendelseID: string;
    vurderingID: string;
    undersoktAvID: string;
};

export type DodeRovdyr = StedfestingBase & {
    artsID: string;
    kjønnID: string;
    alder: string;
    alderFastsattNINA: boolean;
    alderFastsattSVA: boolean;
    yngling: boolean;
    vekt: string;
    helvekt: string;
    slaktevekt: string;
    bakgrunnArsakID: string;
    utfallID: string;
    vurderingID: string;
    individ?: Individ;
};

export type Stedfesting = DNA | Rovviltobservasjon | Rovviltskade | DodeRovdyr;
