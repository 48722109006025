import {Language} from './language';

export enum Culture {
    NO = 'nb-NO',
    SV = 'sv-SE',
    EN = 'en-US',
}

export function getCulture(language: Language): Culture {
    switch (language) {
        case Language.NO:
            return Culture.NO;
        case Language.SV:
            return Culture.SV;
        case Language.EN:
            return Culture.EN;
        default:
            return Culture.NO;
    }
}