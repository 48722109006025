import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
} from '@material-ui/core/';
import {ExpansionPanel, ExpansionPanelContent, ExpansionPanelTitle} from '../expansionPanel/expansionPanel';
import { createStyles, makeStyles } from '@material-ui/styles';
import classnames from 'classnames';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import './statisticalTable.scss';
import {alpha, fade} from '@material-ui/core/styles';
import { useLanguage } from '../../i18n';
import {useTabletBreakpointInnsyn} from '../../hooks/breakpoint';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '840px',
            backgroundColor: theme.palette.background.paper,

            '& th': {
                fontWeight: 'bold',
                padding: '12px',
                fontSize: '16px',
                textAlign: 'right',
            },
            '& th:first-of-type': {
                paddingLeft: '16px',
                textAlign: 'left',
            },
            '& th:last-of-type': {
                paddingRight: '16px',
            },

            '& td': {
                border: 0,
                fontSize: '16px',
                textAlign: 'right',
                padding: '6px 16px 6px 12px',
            },
            '& td:first-of-type': {
                paddingLeft: '16px',
                textAlign: 'left',
            },
            '& td:last-of-type': {
                paddingRight: '16px',
            },

            '& > tbody > tr:nth-of-type(2n + 1)': {
                backgroundColor: alpha(theme.palette.grey[200], 0.5),
            },
        },
        wrapper: {
            margin: '100px auto',
            width: '100%',
        },
        head: {
            border: `1px solid ${theme.palette.grey[300]}`,
        },
        underTableFirstRow: {
            borderBottom: `1px solid ${theme.palette.grey[300]}`,
            backgroundColor: 'none',
        },
        underTableText: {
            fontWeight: 'bold',
        },
        underTable: {
            borderBottom: 0,
        },
    })
);

export default function StatisticalTableSheep(): JSX.Element {
    const l = useLanguage();
    const classes = useStyles({});
    const apiData = useSelector((state: ApplicationState) =>
        state.refund.sheepStatistics.sheepCompensationArea.filter(row => row.year === 'ALLE')
    );
    const total = apiData.filter(obj => obj.area === 'Totalt');
    const sorted = apiData
        .filter(obj => obj.area !== 'Totalt')
        .sort((a, b) => a.area.localeCompare(b.area))
        .concat(total);
    const underTableHeadClass = classnames(classes.underTableText, classes.underTable);
    const tabletScreen = useTabletBreakpointInnsyn();
    return (
        <div className={'statistics-table-wrapper'} id="statistics-table">
            {tabletScreen &&
                apiData
                    .filter(row => row.year === 'ALLE')
                    .map((row, key) => (
                        <ExpansionPanel theme="dark" key={key}>
                            <ExpansionPanelTitle theme="dark">{row.area}</ExpansionPanelTitle>
                            <ExpansionPanelContent>
                                <div>
                                    <div style={{ padding: '5px 0px  5px 0px' }}>
                                        <b>{l('refund.numberOfApplications')}: </b> {row.applications.toLocaleString()}
                                    </div>
                                    <div style={{ padding: '5px 0px  5px 0px' }}>
                                        <b>{l('refund.compensationAmount')}: </b>{' '}
                                        {row.compensationAmount.toLocaleString()} kr
                                    </div>

                                    <div>
                                        <Table size="small" className={classes.root}>
                                            <TableHead className={classes.head}>
                                                <TableRow>
                                                    <TableCell className="small-table-sheep-left-column"
                                                    />
                                                    <TableCell
                                                        style={{
                                                            minWidth: 70,
                                                            maxWidth: 120,
                                                        }}
                                                    >
                                                        {l('refund.totalLoss')}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            minWidth: 90,
                                                            maxWidth: 120,
                                                        }}
                                                    >
                                                        {l('refund.normalLoss')}
                                                    </TableCell>
                                                    <TableCell
                                                        style={{
                                                            minWidth: 80,
                                                            maxWidth: 100,
                                                        }}
                                                    >
                                                        {l('refund.compensated')}
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow hover={true}>
                                                    <TableCell
                                                        className="small-table-sheep-left-column"
                                                    >
                                                        {l('carnivoreDamage.sheep')}
                                                    </TableCell>
                                                    <TableCell>{row.totalLossSheep.toLocaleString()}</TableCell>
                                                    <TableCell>{row.normalLossSheep.toLocaleString()}</TableCell>
                                                    <TableCell>{row.compensatedSheep.toLocaleString()}</TableCell>
                                                </TableRow>
                                                <TableRow hover={true}>
                                                    <TableCell
                                                        className="small-table-sheep-left-column"
                                                    >
                                                        {l('refund.lamb')}
                                                    </TableCell>
                                                    <TableCell>{row.totalLossLamb.toLocaleString()}</TableCell>
                                                    <TableCell>{row.normalLossLamb.toLocaleString()}</TableCell>
                                                    <TableCell>{row.compensatedLamb.toLocaleString()}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </div>
                                </div>
                            </ExpansionPanelContent>
                        </ExpansionPanel>
                    )
                )
            }
            
            {!tabletScreen && (
                <Fragment>
                    <Table className={classes.root}>
                        <TableHead className={classes.head}>
                            <TableRow>
                                <TableCell>{l('refund.area')}</TableCell>
                                <TableCell>{l('refund.numberOfApplications')}</TableCell>
                                <TableCell>{l('refund.compensationAmount')}</TableCell>
                                <TableCell style={{
                                    minWidth: 52,
                                    maxWidth: 52,
                                }} />
                                <TableCell>{l('refund.totalLoss')}</TableCell>
                                <TableCell>{l('refund.normalLoss')}</TableCell>
                                <TableCell>{l('refund.compensated')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sorted.map(row => (
                                <TableRow key={row.area} hover={true}>
                                    <TableCell scope="row">{row.area}</TableCell>
                                    <TableCell>{row.applications.toLocaleString()}</TableCell>
                                    <TableCell>{row.compensationAmount.toLocaleString()} kr</TableCell>
                                    <TableCell colSpan={4} padding="none">
                                        <Table>
                                            <TableBody>
                                                <TableRow className={classes.underTableFirstRow}>
                                                    <TableCell padding="none" className={classes.underTableText}>
                                                        {l('refund.sheep')}
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 100, maxWidth: 100 }}>
                                                        {row.totalLossSheep.toLocaleString()}
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 100, maxWidth: 100 }}>
                                                        {row.normalLossSheep.toLocaleString()}
                                                    </TableCell>
                                                    <TableCell style={{ minWidth: 80, maxWidth: 100 }}>
                                                        {row.compensatedSheep.toLocaleString()}
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell padding="none" className={underTableHeadClass}>
                                                        {l('refund.lamb')}
                                                    </TableCell>
                                                    <TableCell className={classes.underTable} align="right">
                                                        {row.totalLossLamb.toLocaleString()}
                                                    </TableCell>
                                                    <TableCell className={classes.underTable} align="right">
                                                        {row.normalLossLamb.toLocaleString()}
                                                    </TableCell>
                                                    <TableCell className={classes.underTable} align="right">
                                                        {row.compensatedLamb.toLocaleString()}
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Fragment>
            )}
        </div>
    );
}
